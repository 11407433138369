import React, { useState, useEffect } from 'react';

import Banner from '../Banner/Banner';
import AdvantageSection from '../Sections/Advantage';
import SearchForm from '../Sections/SearchForm';
import FeaturedProduct from '../Sections/FeaturedProduct';
import AreasSection from '../Sections/AreasSection';
import { Helmet } from 'react-helmet';

import {
    LoaderIcon
} from "../../icons";
import {
    Container
} from 'react-bootstrap';
import { useLocation } from 'react-router';
import queryString, { parse } from 'query-string';
import { useHistory } from "react-router-dom"
import axios from 'axios'


export const HomePage = (props) => {




    const [BannerTitle, setBannerTitle] = useState();
    const [BannerSubTitle, setBannerSubTitle] = useState();
    const [BannerImage, setBannerImage] = useState();
    const [BannerButtonText, setBannerButtonText] = useState();
    const [BannerButtonUrl, setBannerButtonUrl] = useState();

    const [AdvantageTitle, setAdvantageTitle] = useState();
    const [AdvantageList, setAdvantageList] = useState([]);

    const [featuredProgramList, setfeaturedProgramList] = useState([]);

    const [selectedPracticeArea, setselectedPracticeArea] = useState([])
    const [UserEmail, setUserEmail] = useState(undefined);


    const location = useLocation();
    let history = useHistory();
    const queryParam = queryString.parse(location.search);
    const GAurl = queryParam['gclid'] !== undefined ? '?gclid=' + queryParam['gclid'] : '';

    const PathName = location.pathname;
    let tagfilter = "";



    const [userData_, setUserData_] = useState(undefined);

    useEffect(() => {
        if (props.useLoggedIn) {
            if (props.UserData !== undefined) {
                setUserEmail(props.UserData.customer.email);
                tagfilter = 'cpd-programs-menu';
                fetchData(tagfilter, props.UserData.customer.email);
            }
        }
        else {
            tagfilter = 'cpd-programs-menu';
            fetchData(tagfilter, undefined);

        }
    }, [props.UserData])


    /**
      * Fetch product data
      */
    const [isLoading, setLoading] = useState(true)
    const [isError, setError] = useState(false)
    const [totalProduct, settotalProduct] = useState(0);
    const [seo_title, set_seo_title] = useState("");
    const [seo_description, set_seo_description] = useState('');


    const fetchData = async (tags, useemaill) => {
        setError(false);
        setLoading(true);

        try {
            const admin_panel_api_URL = props.useLoggedIn ? props.AdminPanelURL + 'api/home_page_details?email=' + useemaill : props.AdminPanelURL + 'api/home_page_details';
            const admin_panel_response = await axios(admin_panel_api_URL);
            // console.log(api_URL);
            if (admin_panel_response.status === 200) {
                var data = admin_panel_response.data;
                if (data.length !== 0) {
                    setBannerTitle(data.banner.banner_title);
                    setBannerSubTitle(data.banner.banner_subtitle);
                    setBannerImage(data.banner.banner_image);
                    setBannerButtonText(data.banner.banner_btn_text);
                    setBannerButtonUrl(data.banner.banner_btn_url);
                    setAdvantageTitle(data.advantages.title)
                    setAdvantageList(data.advantages.tabs);
                    setfeaturedProgramList(data.featured_programs);
                    if (props.useLoggedIn) {
                        setselectedPracticeArea(data.practice_areas);
                    }
                    else {
                        if (localStorage.getItem("new_practice_area") !== null) {
                            setselectedPracticeArea(localStorage.getItem("new_practice_area").split(","));
                        }
                    }
                }
            }


            const api_URL = props.EngineURL + 'shopify-pings/getdata.php';
            const response = await axios(api_URL);
            // console.log(api_URL);
            /***
             * Assign data to State
            */
            if (response.status === 200) {
                var data = response.data;
                if (data.length !== 0) {
                    var result = Object.keys(data).map((key) => [Number(key), data[key]]);
                    //console.log('result', result);
                    setUserData_({ products: result });
                    settotalProduct(result);
                }
                else {
                    setUserData_({ products: [] });
                    settotalProduct(0);
                }
                setLoading(false);
            }
        } catch (error) {
            setError(true);
        }
        setLoading(false);
    };

    useEffect(() => {

    }, []);

    /***
     * Compare array value
     */
    function intersect_arrays(a, b) {
        var sorted_a = a.concat().sort();
        var sorted_b = b.concat().sort();
        var common = [];
        var a_i = 0;
        var b_i = 0;

        while (a_i < a.length
            && b_i < b.length) {
            if (sorted_a[a_i] === sorted_b[b_i]) {
                common.push(sorted_a[a_i]);
                a_i++;
                b_i++;
            }
            else if (sorted_a[a_i] < sorted_b[b_i]) {
                a_i++;
            }
            else {
                b_i++;
            }
        }
        return common;
    }

    /***
     * Filter value from main array
    */
    function mysearch(data, featuredProgramList_, selectedProgram_) {
        //  console.log('data',data);
        // console.log("length ->" + data.length)
        const filterRespones = data.filter(function (pppp) {
            let ppp = Object.values(pppp);

            //console.log(ppp);
            var program_type = false;
            var search_text = false;
            var hidefromlist = false;
            var datapassed = true;
            var state_allowed = false;
            var defaaultt = false;

            var CurrentDate = new Date();


            if (featuredProgramList_ !== undefined || selectedProgram_ !== undefined) {
                ppp[1].metafields.filter(function (metadata) {
                    if (metadata.key === "all_data") {

                        var prodcutJson = JSON.parse(metadata.value);




                        /****
                         * Old date
                         */

                        var is_program_gone = program_date_is_gone_or_not(prodcutJson.start_date_time);
                        if (prodcutJson.start_date_time !== null && is_program_gone) {
                            datapassed = false;
                        }






                        /***
                         * Hide from list
                         */

                        if (prodcutJson.Hide_from_list !== true) {
                            hidefromlist = true;
                        }



                        /**
                         * Program type
                         */

                        /*       if (selectedProgram !== undefined) {
                                   if (prodcutJson.Program_Types__c !== null && prodcutJson.Program_Types__c !== undefined) {
                                       var lower_program = prodcutJson.Program_Types__c.map(v => v.split(' ').join('-').toLowerCase());
       
                                       if (lower_program.includes(selectedProgram.split(" ").join("-"))) {
                                           program_type = true;
                                       }
                                   }
                               }
                               else {
                                   program_type = true;
                               }*/






                        /**
                         * Product title
                        */
                        if (featuredProgramList_ !== null && featuredProgramList_ !== undefined) {
                            if (featuredProgramList_.includes(ppp[1].title)) {
                                search_text = true
                            }
                        } else {
                            search_text = true
                        }






                        if (datapassed && hidefromlist && search_text) {
                            defaaultt = true;
                        }
                    }

                })
            }
            else {


                /****
                 * Hide from list 
                 */
                ppp[1].metafields.filter(function (metadata) {
                    if (metadata.key == "all_data") {
                        var prodcutJson = JSON.parse(metadata.value);
                        if (prodcutJson.Hide_from_list !== true) {
                            hidefromlist = true;
                        }

                        /****
                       * Old date
                       */

                        /* if (prodcutJson.end_date != undefined && prodcutJson.end_date < CurrentDate) {
                             datapassed = true
                             alert();
                         }*/
                        var past = new Date(prodcutJson.end_date);

                        if (prodcutJson.end_date !== undefined && dateInPast(past, CurrentDate)) {
                            datapassed = true
                        }

                        /*     var is_program_gone = program_date_is_gone_or_not(prodcutJson.start_date_time);
                             if(is_program_gone)
                             {
                                 datapassed = false;
                             }*/


                    }



                });


                if (datapassed && hidefromlist){
                    defaaultt = true;
                }
            }



            return defaaultt;
        })


        /***
         * Sort by date
         */


        filterRespones.sort(function (aa, bb) {
            let a = Object.values(aa);
            let b = Object.values(bb);
            var pdate1 = a[1];
            var pdate2 = b[1];

            var date1 = null;
            var date2 = null;
             a[1].metafields.map(function (metadata) {
                if (metadata.key == "all_data") {
                    var prodcutJson1 = JSON.parse(metadata.value);
                    date1 = prodcutJson1.start_date_time;

                }
            })

             b[1].metafields.map(function (metadata) {
                if (metadata.key == "all_data") {
                    var prodcutJson2 = JSON.parse(metadata.value);
                    date2 = prodcutJson2.start_date_time;

                }
            })

            if (date1 == null || date1 == "" || pdate1.tags.includes("online-intensive-(to-download)")) {
                return 1;
            }
            else if (date2 == null || date2 == "" || pdate2.tags.includes("online-intensive-(to-download)")) {
                return -1;
            }
            else {
                return new Date(date1) - new Date(date2);
            }

        })






        return filterRespones;
    }

    /***
     * Check date between
     */
    function compare_date(dateFrom, dateTo, dateCheck) {
        //   console.log(dateFrom + " - " + dateTo + " - " + dateCheck);
        var d1 = dateFrom.split("-");
        var d2 = dateTo.split("-");
        var c = dateCheck.split("-");

        var from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]);  // -1 because months are from 0 to 11
        var to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
        var check = new Date(c[0], parseInt(c[1]) - 1, c[2]);

        return from <= check && check <= to;
    }

    function dateInPast(firstDate, secondDate) {
        if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
            return true;
        }

        return false;
    };

    /***
    * State Variable 
    * Phase 2
    */
    const [userState, setUserState] = useState(() => {
        const user_state = localStorage.getItem("user_state");
        return user_state !== null ? user_state : 'VIC'
    });



    const is_ten_minite_less = (program_start_date_time) => {
        if (program_start_date_time === undefined || program_start_date_time === null || program_start_date_time === "") {
            return false;
        }

        program_start_date_time = new Date(program_start_date_time);
        let program_time_modify = program_start_date_time.setHours(program_start_date_time.getHours());
        let current_time_10 = new Date(new Date().getTime() + 1000 * 600);
        let current_time = new Date().getTime();

        var from = current_time;  // -1 because months are from 0 to 11
        var to = current_time_10;
        var check = program_time_modify;

        return from <= check && check <= to;
    }

    const program_date_is_gone_or_not = (program_start_date_time) => {
        if (program_start_date_time === null) {
            return false;
        }
        program_start_date_time = new Date(program_start_date_time);
        let program_time_modify = program_start_date_time.setHours(program_start_date_time.getHours());
        let current_time = new Date().getTime();
        if ((parseFloat(program_time_modify) - parseFloat(current_time)) > 0) {
            return false;
        }
        else {
            return true;
        }
    }



    if (userData_ === undefined) {
        return <><div className="pre_loader l1"><LoaderIcon /></div></>
    }


    return (
        <>
          <Helmet>
                    <title>Leo Cussen Centre for Law</title>
                    <link rel="canonical" href={"https://store.leocussen.edu.au/"} />
                    <meta name="description" content="" />
                </Helmet>
            <Banner
                BannerTitle={BannerTitle}
                BannerSubTitle={BannerSubTitle}
                BannerImage={BannerImage}
                BannerButtonText={BannerButtonText}
                BannerButtonUrl={BannerButtonUrl}
                GAurl={GAurl} />

            <AdvantageSection
                AdvantageTitle={AdvantageTitle}
                AdvantageList={AdvantageList}
                GAurl={GAurl} />
                
            <SearchForm
                PopularTag={props.PopularTag}
                GAurl={GAurl} />

            {userData_ !== undefined ?
                <FeaturedProduct
                    GAurl={GAurl}
                    program_date_is_gone_or_not={program_date_is_gone_or_not}
                    is_ten_minite_less={is_ten_minite_less}
                    Program_structure_images={props.Program_structure_images}
                    isLoading={isLoading}
                    productData={mysearch(userData_.products, featuredProgramList, undefined)}
                    AddtoCart={props.AddtoCart} />
                :
                <Container style={{ height: "400px" }}>
                    <div className='title_wrap text-center'>
                        <h2 className='text-primary'>Featured Programs loading...</h2>
                    </div>
                    <div className="pre_loader l1"><LoaderIcon /></div>
                </Container>
            }
            {
                userData_ !== undefined ?
                    <AreasSection
                        selectedProgram={selectedPracticeArea}
                        program_date_is_gone_or_not={program_date_is_gone_or_not}
                        is_ten_minite_less={is_ten_minite_less}
                        Program_structure_images={props.Program_structure_images}
                        products={mysearch(userData_.products)}
                        AddtoCart={props.AddtoCart} GAurl={GAurl} />
                    : ''
            }


        </>
    )


}


import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import pm from '../../../images/tag-pm.png';
import ps from '../../../images/tag-ps.png';
import sl from '../../../images/tag-sl.png';
import ep from '../../../images/tag-ep.png';
import user from '../../../images/user-ic.png';
import laptop from '../../../images/laptop-ic.png';
import calendar from '../../../images/calendar-ic.png';
import watch from '../../../images/watch-ic.png';
import gql from 'graphql-tag';

import { useQuery } from '@apollo/react-hooks';
const FetchProductData = gql`
query ($handle: String!) {
  product(handle: $handle) {
        id
        handle
        title
        tags
        productType
        vendor
        description
        descriptionHtml
        priceRange
        {
          minVariantPrice
          {
            amount
            currencyCode
          }
        }
        compareAtPriceRange { minVariantPrice { amount } }
        options
        {
            id
            name
            values
        }
      
        variants(first: 5) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            edges {
              node {
                id
                title
                sku
                selectedOptions {
                  name
                  value
                }
                price{
                  amount
                  currencyCode
                }
              }
            }
          }
          metafields(identifiers: [{namespace: "arena", key: "all_data"}])
          {
                 key
                 value
                 namespace
          } 
    }
  }`;
export const RelatedProgram = ({ productHandle, AddtoCart, Program_structure_images, is_ten_minite_less, program_date_is_gone_or_not }) => {
    const { loading, error, data, fetchMore } = useQuery(FetchProductData, {
        variables: {
            handle: productHandle
        }
    });
    const randm = () => {
        return (Math.random() + 1).toString(36).substring(7);
    }

    const isNotEmpty = (value) => {
        return value !== null && value !== undefined;
    }
    const UpdateRadio = (value, index) => {

        setDefaultRadioValue(value);
        setDefaultRadio(index);
    }

    const [TEN_MIntLeft, setTEN_MIntLeft] = useState(false);
    const [isProgramOver, setisProgramOver] = useState(false);
    const [Program_structure_image, setProgram_structure_image] = useState(Program_structure_images);
    const [DefaultRadioValue, setDefaultRadioValue] = useState("");
    const [DefaultRadio, setDefaultRadio] = useState(0);
    const [metaData, setmetaData] = useState({});
    const [substantive_law, setSubstantive_law] = useState(0);
    const [professional_skills, setProfessional_skills] = useState(0);
    const [practise_managment_skill, setPractise_managment_skill] = useState(0);
    const [ethics_professional_responsibi, setEthics_professional_responsibi] = useState(0);
    const [program_date_time_line_2, setProgram_date_time_line_2] = useState('');
    const [program_date_time_line_1, setProgram_date_time_line_1] = useState('');
    const [reached_capacity, set_reached_capacity] = useState(false);
    const [Program_Structure__c, setProgram_Structure__c] = useState(undefined);
    const [sDate, setsDate] = useState(null);
    const [DeliveryMode, setDeliveryMode] = useState([]);
    const [RecordType, setRecordType] = useState("")

    useEffect(() => {
        if (sDate !== undefined && sDate !== null && sDate !== "") {
            setTEN_MIntLeft(is_ten_minite_less(sDate));
            setisProgramOver(program_date_is_gone_or_not(sDate));
        }
    }, [sDate])



    useEffect(() => {
        if (DeliveryMode.length) {
            const dfvalue = DeliveryMode[0];
            UpdateRadio(dfvalue == "" ? '' : dfvalue, 0);
        }

    }, [DeliveryMode])


    useEffect(() => {
        setSubstantive_law(metaData.Substantive_Law__c !== undefined ? metaData.Substantive_Law__c : 0);
        setProfessional_skills(metaData.Professional_Skills__c !== undefined ? metaData.Professional_Skills__c : 0);
        setPractise_managment_skill(metaData.Practice_Management_Business_Skills__c !== undefined ? metaData.Practice_Management_Business_Skills__c : 0);
        setEthics_professional_responsibi(metaData.Ethics_Professional_Responsibility__c !== undefined ? metaData.Ethics_Professional_Responsibility__c : 0);
        setProgram_date_time_line_2(metaData.Program_Date_Time_Line_2__c !== undefined && metaData.Program_Date_Time_Line_2__c !== null ? metaData.Program_Date_Time_Line_2__c : "");
        setProgram_date_time_line_1(metaData.Program_Date_Time_Line_1__c !== undefined && metaData.Program_Date_Time_Line_1__c !== null ? metaData.Program_Date_Time_Line_1__c : "");
        setDeliveryMode(metaData.delivery_mode !== undefined ? metaData.delivery_mode : []);
        setProgram_Structure__c(isNotEmpty(metaData.Program_Structure__c) ? metaData.Program_Structure__c : undefined);
        set_reached_capacity(metaData.Reached_Capacity__c !== undefined ? metaData.Reached_Capacity__c : false);
        setsDate(metaData.start_date_time !== undefined && metaData.start_date_time !== null ? metaData.start_date_time : null);
        setRecordType(metaData.RecordType !== undefined ? metaData.RecordType : "");





    }, [metaData])

    const [mokdi, setMokdi] = useState("");

    useEffect(() => {
        if (data != undefined) {
            const product = data.product;

            setmetaData(JSON.parse(data.product.metafields[0].value));
        }
    }, [data]);


    if (loading) return <h5>Loading</h5>;
    if (data.product === null) return null;







    const price = data.product.priceRange.minVariantPrice.amount;
    const price_float = parseFloat(price).toFixed(2);

    const dateFormat_sDate = new Date(sDate);
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    return (
        <div className='product_grid_block'>
            <div className='product_grid_block_top'>
                <div className='product_grid_block_title'>
                    <h2 className='h3'><Link to={`/product/${data.product.handle}`}>{data.product.title}</Link></h2>
                    {sDate !== null && sDate !== undefined ? <p>({months[dateFormat_sDate.getMonth()]} {dateFormat_sDate.getFullYear()})</p> : null}
                </div>
                <div className='product_grid_block_icons' key={mokdi}>

                    {DeliveryMode.length ?
                        <>
                            {DeliveryMode.map((value, index) => {
                                return <> {value === "Live Stream"
                                    ? <img key={'check' + index} src={laptop} alt={value} className={index == DefaultRadio ? 'selected ' + mokdi : mokdi} onClick={() => UpdateRadio(value, index)} />
                                    : <img key={'check' + index} src={user} alt={value} className={index == DefaultRadio ? 'selected ' + mokdi : mokdi} onClick={() => UpdateRadio(value, index)} />}</>
                            })}

                        </> : null}
                </div>
            </div>{/*product_grid_block_top */}
            <div className='product_grid_block_tags'>
                {practise_managment_skill > 0 ? <img src={pm} alt="Practice Management & Business Skills" title="Practice Management & Business Skills" /> : null}
                {professional_skills > 0 ? <img src={ps} alt="Professional Skills" title="Professional Skills" /> : null}
                {substantive_law > 0 ? <img src={sl} alt="Substantive Law" title="Substantive Law" /> : null}
                {ethics_professional_responsibi > 0 ? <img src={ep} alt="Ethics & Professional Responsibility" title="Ethics & Professional Responsibility" /> : null}
            </div>
            <div className='product_grid_block_titmings'>
                {
                    program_date_time_line_1 !== '' && program_date_time_line_1 !== undefined ?
                        (
                            <div className='inside_block' key={program_date_time_line_1}>
                                <div className='icon'><img src={calendar} alt='calendar' /></div>
                                <div className='content'>{program_date_time_line_1}</div>
                            </div>
                        )
                        : ''
                }
                {
                    program_date_time_line_2 !== '' && program_date_time_line_2 !== undefined ?
                        (
                            <div className='inside_block' key={program_date_time_line_2}>
                                <div className='icon'><img src={watch} alt='watch' /></div>
                                <div className='content'>{program_date_time_line_2}</div>
                            </div>
                        )
                        : ''
                }

            </div>
            <div className='product_grid_block_price'>
                <h3>${price_float}</h3>
                {/*<h4 className='text-decoration-line-through'>$299.00</h4>*/}
            </div>
            <div className='product_grid_block_btns'>
                {!TEN_MIntLeft ?
                    isProgramOver ? <div className="label_tag">Bookings Closed</div> :
                        reached_capacity
                            ? <div className="label_tag">Fully Booked</div>
                            : <>

                                <a href='#' className='btn btn-secondary' onClick={(e) => AddtoCart(data.product.variants.edges[0].node.id, 1, data.product.variants.edges[0].node.sku, DefaultRadioValue, RecordType, data.product.tags, Program_structure_image, data.product.handle, sDate, Program_Structure__c)}>Add to cart</a>
                            </>
                    : <div className="label_tag">Bookings Closed</div>
                }
                <Link className="btn-link" to={`/product/${data.product.handle}`}>Learn more</Link>
            </div>
        </div>
    )
}


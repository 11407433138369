import React, { useState, useEffect } from 'react';
import {
    Container,
    Form,
    Button,
} from 'react-bootstrap';
import {
    SearchIconNew,
    ArrowUpIcon,
    ArrowDownIcon
} from "../../icons";
import { Hint } from 'react-autocomplete-hint';

import BlueBg from "../../images/blue-banner-img.png"

export const SearchForm = (props) => {

    const ProgramType =  [
        "Live Streamed One Hour Seminars",
        "Live Streamed Conferences and Intensives",
        "Live Streamed Practical Workshops",
        "Onsite Programs",
        "Pre-Recorded Webcasts",
      ];

    const PracticeArea = [
        "Advocacy",
        "Building & Construction",
        "Business & Contracts",
        "Consumer",
        "Corporate & In-House Counsel",
        "Costs",
        "Criminal",
        "Employment & Workplace Relations",
        "Ethics",
        "Family",
        "Finance, Tax, Superannuation",
        "Government, Privacy & FOI",
        "Health & Aged Care",
        "Insolvency & Restructuring",
        "Intellectual Property",
        "Litigation & Dispute Resolution",
        "Migration",
        "Personal Injury & Medico-Legal",
        "Planning & Environment",
        "Professional Skills & Practice Management",
        "Property & Leases",
        "Sports Law",
        "Trusts & Succession Planning",
        "Wills & Estates"
    ];

    const formatType = [
        "Pre-Recorded Webcasts",
        "Books"
    ];

    const Topics = [
        "Administrative Law",
        "Civil Litigation",
        "Commercial and Corporate Practice",
        "Employment and Industrial Relations",
        "Ethics and Professional Responsibility",
        "Lawyer’s Skills including Advocacy",
        "Problem Solving",
        "Property Law",
        "Risk Management",
        "Trust and Office Accounting",
        "Work Management Business Skills (including Risk Management)"
    ];


    const CPDCategories = [
        "Ethics & Professional Responsibility",
        "Professional Skills",
        "Practice Management & Business Skills",
        "Substantive Law"
    ];

    const DeliverMode = [
        "Face to Face",
        "Live Stream"
    ];

    const flatten = (arr) => {
        return arr.reduce(function (flat, toFlatten) {
            return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
        }, []);
    }
    const [SearchText, setSearchText] = useState("");
    const [selectedFormat, setselectedFormat] = useState([]);
    const [selectedProgram, setProgram] = useState([]);
    const [selectedPractise, setPractise] = useState([]);
    const [selectedCPD, setCPD] = useState([]);
    const [selectedTopic, setTopic] = useState([]);
    const [selectedDelivery, setDelivery] = useState([]);
    const [VisibleFilterLayout, setVisibleFilterLayout] = useState(true);


    /***
     * Remove array value
     */
    const removeItemOnce = (arr, value) => {
        var index = arr.indexOf(value);
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    }

    const GenerateSearchURL = () => {
        if (selectedProgram.length || selectedPractise.length || selectedCPD !== undefined || SearchText !== "" || selectedDelivery.length || selectedTopic.length || selectedFormat.length) {
            var MakeURL = "?";
            if (selectedProgram !== undefined && selectedProgram !== null && selectedProgram.length) {
                MakeURL += "&program-type=" + selectedProgram.map(v => RemoveSpecialChar(v)).join(",");
            }
            if (selectedPractise !== undefined && selectedPractise.length) {
                MakeURL += "&practice-area=" + selectedPractise.map(v => RemoveSpecialChar(v)).join(",");
            }
            if (selectedCPD !== undefined && selectedCPD.length) {
                MakeURL += "&cpd-categories=" + selectedCPD.map(v => RemoveSpecialChar(v)).join(",");
            }
            if (selectedDelivery !== undefined && selectedDelivery.length) {
                MakeURL += "&stream-mode=" + selectedDelivery.map(v => RemoveSpecialChar(v)).join(",");
            }
            if (selectedTopic !== undefined && selectedTopic.length) {
                MakeURL += "&topic=" + selectedTopic.map(v => RemoveSpecialChar(v)).join(",");
            }
            if (selectedFormat !== undefined && selectedFormat.length) {
                MakeURL += "&format=" + selectedFormat.map(v => RemoveSpecialChar(v)).join(",");
            }

            if (SearchText !== "") {
                MakeURL += "&keyword=" + SearchText;
            }
            return MakeURL

        }
    }

    /***
     * Auto complete list
     */

    const AutoComplteList = () => {
        var ArraysTemp = [
            [ProgramType],
            [formatType],
            [PracticeArea],
            [Topics],
            [CPDCategories],
            [DeliverMode]
        ];
        var ReturnArray = [];
        flatten(ArraysTemp).map((val) => {
            ReturnArray.push(val);
        });
        return ReturnArray;
    }
    /***
     * Remove special charachter
     */
    const RemoveSpecialChar = (StringText) => {
        if (StringText !== null && StringText !== undefined) {
            return StringText.toLowerCase().split("&").join("and").split(",").join(" ").split(" ").join("-");
        }
    }


    /****
  *
  */
    const AutoCompltehandleOnSelect = (item) => {

        let TagValue = item;
     /*   console.log(TagValue);
        if (Does_Tag_isProgramType(TagValue).length > 0) {
            setProgram([RemoveSpecialChar(TagValue)]);
            setSearchText("");
            return true;
        } else if (Does_Tag_isFormatType(TagValue).length > 0) {
            setselectedFormat([RemoveSpecialChar(TagValue)]);
            setSearchText("");
            return true;
        } else if (Does_Tag_isPracticeArea(TagValue).length > 0) {
            setPractise([RemoveSpecialChar(TagValue)]);
            setSearchText("");
            return true;
        } else if (Does_Tag_isTopics(TagValue).length > 0) {
            setTopic([RemoveSpecialChar(TagValue)]);
            setSearchText("");
            return true;
        } else if (Does_Tag_isCPDCategories(TagValue).length > 0) {
            setCPD([RemoveSpecialChar(TagValue)]);
            setSearchText("");
            return true;
        } else if (Does_Tag_isDeliverMode(TagValue).length > 0) {
            setDelivery([RemoveSpecialChar(TagValue)]);
            setSearchText("");
            return true;
        } else {
            setProgram([]);
            setselectedFormat([]);
            setTopic([]);
            setPractise([]);
            setDelivery([]);
            setCPD([]);
            setSearchText(TagValue);
        }*/
        setSearchText(TagValue);

    }


    /***
     * Is filter Tag checked
     */

    const isTagCHecked = (TagValue) => {
        if (Does_Tag_isProgramType(TagValue).length > 0 && selectedProgram !== undefined) {
            return selectedProgram.includes(RemoveSpecialChar(TagValue));
        } else if (Does_Tag_isFormatType(TagValue).length > 0) {
            return selectedFormat.includes(RemoveSpecialChar(TagValue));
        } else if (Does_Tag_isPracticeArea(TagValue).length > 0 && selectedPractise !== undefined) {
            return selectedPractise.includes(RemoveSpecialChar(TagValue));
        } else if (Does_Tag_isTopics(TagValue).length > 0) {
            return selectedTopic.includes(RemoveSpecialChar(TagValue));
        } else if (Does_Tag_isCPDCategories(TagValue).length > 0 && selectedCPD !== undefined) {
            return selectedCPD.includes(RemoveSpecialChar(TagValue));
        } else if (Does_Tag_isDeliverMode(TagValue).length > 0) {
            return selectedDelivery.includes(RemoveSpecialChar(TagValue));
        }

    }

    /****
     * All filter list
     */
    const AllFilterList = (RequestTag) => {
        var ArraysTemp = [
            [ProgramType],
            [formatType],
            [PracticeArea],
            [Topics],
            [CPDCategories],
            [DeliverMode]
        ];
        return flatten(ArraysTemp).includes(RequestTag) && isTagCHecked(RequestTag);

    }



    /***
     *
     */
    const Does_Tag_isProgramType = (ProgramValue) => {
        return ProgramType.filter((val) => {
            return RemoveSpecialChar(val) === RemoveSpecialChar(ProgramValue);

        });
    }

    const Does_Tag_isFormatType = (FormatValue) => {
        return formatType.filter((val) => {
            return RemoveSpecialChar(val) === RemoveSpecialChar(FormatValue);
        });
    }

    const Does_Tag_isPracticeArea = (PracticeValue) => {
        return PracticeArea.filter((val) => {
            return RemoveSpecialChar(val) === RemoveSpecialChar(PracticeValue);
        });
    }


    const Does_Tag_isTopics = (TopicValue) => {
        return Topics.filter((val) => {
            return RemoveSpecialChar(val) === RemoveSpecialChar(TopicValue);
        });
    }

    const Does_Tag_isCPDCategories = (CategoriesValue) => {
        return CPDCategories.filter((val) => {
            return RemoveSpecialChar(val) === RemoveSpecialChar(CategoriesValue);
        });
    }

    const Does_Tag_isDeliverMode = (DeliverModeValue) => {
        return DeliverMode.filter((val) => {
            return RemoveSpecialChar(val) === RemoveSpecialChar(DeliverModeValue);
        });
    }

    /****
 * Manage Popular tag click event
 */
    const ClickPopularTag = (TagValue) => {
        if (Does_Tag_isProgramType(TagValue).length > 0) {
            (selectedProgram.includes(RemoveSpecialChar(TagValue))) ? setProgram([...removeItemOnce(selectedProgram, RemoveSpecialChar(TagValue))]) : setProgram(oldArray => [...oldArray, RemoveSpecialChar(TagValue)]);
            return true;
        } else if (Does_Tag_isFormatType(TagValue).length > 0) {
            (selectedFormat.includes(RemoveSpecialChar(TagValue))) ? setselectedFormat([...removeItemOnce(selectedFormat, RemoveSpecialChar(TagValue))]) : setselectedFormat(oldArray => [...oldArray, RemoveSpecialChar(TagValue)]);
            return true;
        } else if (Does_Tag_isPracticeArea(TagValue).length > 0) {
            (selectedPractise.includes(RemoveSpecialChar(TagValue))) ? setPractise([...removeItemOnce(selectedPractise, RemoveSpecialChar(TagValue))]) : setPractise(oldArray => [...oldArray, RemoveSpecialChar(TagValue)]);
            return true;
        } else if (Does_Tag_isTopics(TagValue).length > 0) {
            (selectedTopic.includes(RemoveSpecialChar(TagValue))) ? setTopic([...removeItemOnce(selectedTopic, RemoveSpecialChar(TagValue))]) : setTopic(oldArray => [...oldArray, RemoveSpecialChar(TagValue)]);
            return true;
        } else if (Does_Tag_isCPDCategories(TagValue).length > 0) {
            (selectedCPD.includes(RemoveSpecialChar(TagValue))) ? setCPD([...removeItemOnce(selectedCPD, RemoveSpecialChar(TagValue))]) : setCPD(oldArray => [...oldArray, RemoveSpecialChar(TagValue)]);
            return true;
        } else if (Does_Tag_isDeliverMode(TagValue).length > 0) {
            (selectedDelivery.includes(RemoveSpecialChar(TagValue))) ? setDelivery([...removeItemOnce(selectedDelivery, RemoveSpecialChar(TagValue))]) : setDelivery(oldArray => [...oldArray, RemoveSpecialChar(TagValue)]);
            return true;
        }

    }


    /***
        * Detect enter key
        */
    const HitEnter = (event) => {
        event.preventDefault();
        window.location.href = window.location.origin + '/search' + GenerateSearchURL();
    }



    /****
     * Fillter 3 dot toogle
     */
    const [dotToogle, setdotToogle] = useState(false);

    /***
     * handleOnSearch
     */
    const handleOnSearch = (seachtext) => {
       // console.log(seachtext);
    }

    return (
        <div className='search_wrapper' id="search_wrapper">
            <Container>
                {/* <div className="blue_banner_bar" style={{ backgroundImage: "url(" + BlueBg + ")" }}></div> */}
                <div className='search_inner'>
                    <Form onSubmit={HitEnter}>
                        <div className='filter_search_block'>
                            <div className='sc-eCYdqJ'>
                                <div className="wrapper">
                                    <div className="sc-hKMtZM jVgFww d-none d-md-block">
                                        <Hint options={AutoComplteList()} allowTabFill={true} className="">
                                            <input
                                                className="search_filed"
                                                placeholder="Search for a program title, program description or presenter name"
                                                onChange={e => AutoCompltehandleOnSelect(e.target.value)} />
                                        </Hint>
                                        </div>
                                        <div className="sc-hKMtZM jVgFww d-md-none">
                                        <Hint options={AutoComplteList()} allowTabFill={true} className="d-md-none">
                                            <input
                                                className="search_filed"
                                                placeholder="Search..."
                                                onChange={e => AutoCompltehandleOnSelect(e.target.value)} />
                                        </Hint>
                                    </div>
                                </div>
                            </div>
                            <Button type="submit" variant="search"><SearchIconNew /></Button>
                        </div>
                      

                    </Form>
                </div>

            </Container >
        </div >
    )
}

export default SearchForm;

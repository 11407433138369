import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag';

import { Link } from 'react-router-dom';

import {
  RightAngleIcon,
  CalendarIcon,
  LocationIcon,
  ClockIcon,
  LikeIcon
} from "../../../icons";
import {
  Row,
  Col,
  Form,
  Card,
  ListGroup
} from 'react-bootstrap';
import user_default_image from '../../../images/user.png';


import pm from '../../../images/pm-ic.png';
import ps from '../../../images/ps-ic.png';
import sl from '../../../images/sl-ic.png';
import ep from '../../../images/ep-ic.png';
import { useQuery } from '@apollo/react-hooks';
const FetchProductData = gql`
query ($handle: String!) {
  product(handle: $handle) {
        id
        handle
        title
        tags
        productType
        vendor
        description
        descriptionHtml
        priceRange
        {
          minVariantPrice
          {
            amount
            currencyCode
          }
        }
        compareAtPriceRange { minVariantPrice { amount } }
        options
        {
            id
            name
            values
        }
      
        variants(first: 5) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            edges {
              node {
                id
                title
                sku
                selectedOptions {
                  name
                  value
                }
                price{
                  amount
                  currencyCode
                }
              }
            }
          }
          metafields(identifiers: [{namespace: "arena", key: "all_data"}])
          {
                 key
                 value
                 namespace
          } 
    }
  }`;

export const LoveItem = (props) => {
  const { loading, error, data, fetchMore } = useQuery(FetchProductData, {
    variables: {
      handle: props.productHandle
    }
  });




  const [DefaultRadio, setDefaultRadio] = useState(0);
  const [DefaultRadioValue, setDefaultRadioValue] = useState("");



  const UpdateRadio = (value, index) => {

    setDefaultRadioValue(value);
    setDefaultRadio(index);
  }


  const [TEN_MIntLeft, setTEN_MIntLeft] = useState(false);
  const [isProgramOver, setisProgramOver] = useState(false);


  let Program_structure_image = 'https://engine.leocussen.edu.au/sync/img/logo.png';


  const isNotEmpty = (value) => {
    return value !== null && value !== undefined;
  }

  let author_index = 0;
  let top_author_index = 0;
  const randm = () => {
    return (Math.random() + 1).toString(36).substring(7);
  }





  if (loading) return <h5>Loading</h5>;
  if (data.product === null) return null;

  const metaData = JSON.parse(data.product.metafields[0].value);
  const substantive_law = isNotEmpty(metaData.Substantive_Law__c) ? metaData.Substantive_Law__c : 0;
  const professional_skills = isNotEmpty(metaData.Professional_Skills__c) ? metaData.Professional_Skills__c : 0;
  const practise_managment_skill = isNotEmpty(metaData.Practice_Management_Business_Skills__c) ? metaData.Practice_Management_Business_Skills__c : 0;
  const ethics_professional_responsibi = isNotEmpty(metaData.Ethics_Professional_Responsibility__c) ? metaData.Ethics_Professional_Responsibility__c : 0;
  const totalCpdPoints = substantive_law + professional_skills + practise_managment_skill + ethics_professional_responsibi;
  const program_date_time_line_2 = isNotEmpty(metaData.Program_Date_Time_Line_2__c) ? metaData.Program_Date_Time_Line_2__c : "";
  const program_date_time_line_1 = isNotEmpty(metaData.Program_Date_Time_Line_1__c) ? metaData.Program_Date_Time_Line_1__c : "";
  const reached_capacity = isNotEmpty(metaData.Reached_Capacity__c) ? metaData.Reached_Capacity__c : false;

  const DeliveryMode = isNotEmpty(metaData.delivery_mode) ? metaData.delivery_mode : [];

  const longPromotionMessage = isNotEmpty(metaData.Long_Promotion_Message__c) ? metaData.Long_Promotion_Message__c : '';
  const shortPromotionMessage = isNotEmpty(metaData.Short_Promotion_Message__c) ? metaData.Short_Promotion_Message__c : '';


  const Program_Types__c = isNotEmpty(metaData.Program_Types__c) ? metaData.Program_Types__c : [];
  const sDate = isNotEmpty(metaData.start_date_time) ? metaData.start_date_time : "";
  const RecordType = isNotEmpty(metaData.RecordType) ? metaData.RecordType : "";
  const presentor = isNotEmpty(metaData.presentor) ? metaData.presentor : [];
  const Early_Bird_Price__c = isNotEmpty(metaData.Early_Bird_Price__c) ? metaData.Early_Bird_Price__c : undefined;

  const Program_Structure__c = isNotEmpty(metaData.Program_Structure__c) ? metaData.Program_Structure__c : undefined;
  const Program_Structure__c_Array = isNotEmpty(metaData.Program_Structure__c) ? metaData.Program_Structure__c.split(" ") : [];
  let Program_Types__c_color = '#bc318c';
  props.Program_structure_images.filter(function (item, value) {
    var keyname = Object.keys(item);
    var tag_array = data.product.tags;
    if (tag_array.includes(keyname.toString())) {
      Program_structure_image = Object.values(item);
    }
  })
  props.Program_structure_color.filter(function (item, value) {
    var keyname = Object.keys(item);
    var tag_array = data.product.tags;
    if (tag_array.includes(keyname.toString())) {
      Program_Types__c_color = Object.values(item);
    }
  })

  const price = data.product.priceRange.minVariantPrice.amount;
  const price_float = parseFloat(price);
  const numberWithCommas = (x) => {
    return parseFloat(x).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  
  return (

    <>
      <Col
      >
        <Card className="program_card flex-row">
          <Card.Body>
            <Card.Text as="div">
              {Program_Structure__c !== undefined
                ? <div className="program_cate d-flex justify-content-center align-items-start flex-column" style={{ backgroundColor: Program_Types__c_color }}>
                  {Program_Structure__c_Array.length > 1
                    ? <>
                      <h3>{Program_Structure__c_Array[0]}</h3>
                      <small>{Program_Structure__c_Array.join(" ").replace(Program_Structure__c_Array[0], "")}</small>
                    </>
                    : <h3>{Program_Structure__c}</h3>
                  }

                </div>
                :
                null
              }
              <Link to={`/product/${data.product.handle}`} className="program_head">
                <Card.Title as="h4" className="card-title">{data.product.title} <span className='cpd_points'>CPD Points {totalCpdPoints}</span></Card.Title>
              </Link>
              {totalCpdPoints == 0 ? '' :
                <ListGroup horizontal as="ul" className="align-items-sm-center flex-wrap flex-column flex-sm-row">
                  {/* <ListGroup.Item as="li" className="cpd_points">CPD Points {totalCpdPoints}</ListGroup.Item> */}
                  <ListGroup.Item as="li" className="cpd_topics">
                    {professional_skills > 0 ? <img src={ps} alt="Professional Skills" title="Professional Skills" /> : null}
                    {practise_managment_skill > 0 ? <img src={pm} alt="Practice Management & Business Skills" title="Practice Management & Business Skills" /> : null}
                    {substantive_law > 0 ? <img src={sl} alt="Substantive Law" title="Substantive Law" /> : null}
                    {ethics_professional_responsibi > 0 ? <img src={ep} alt="Ethics & Professional Responsibility" title="Ethics & Professional Responsibility" /> : null}
                  </ListGroup.Item>
                  <ListGroup.Item as="li">
                    {program_date_time_line_1 != '' || program_date_time_line_2 != '' ? <>
                      <p className="address small"><CalendarIcon />{program_date_time_line_1} {program_date_time_line_2 != '' ? '' : null} </p></> : null}
                  </ListGroup.Item>
                  <ListGroup.Item as="li">
                    <p className='mb-0 program_time'><ClockIcon />{program_date_time_line_2}</p>
                  </ListGroup.Item>

                </ListGroup>
              }
              {longPromotionMessage != '' ? <p className="price_sale"><ClockIcon stroke='#70c0c7' /> {longPromotionMessage}</p> : null}
              {/* <p className="address small">(Australia/Melbourne Time)</p> */}
              {/* <p className="address small">{venue ? <><LocationIcon /> {venue} </> : ''}</p> */}

              {
                presentor.length <= 2 ?
                  <div className="author_groups d-lg-none">
                    <Row className="author_listing">
                      {
                        presentor.map((top_author) => {
                          //  console.log('top_author',top_author);
                          top_author_index = top_author_index + 1;
                          let author_name = top_author.Contact_info !== undefined && top_author.Contact_info[0] !== undefined ? top_author.Contact_info[0].Name : null;
                          let author_image = top_author.Contact_info !== undefined && top_author.Contact_info[0] !== undefined ? top_author.Contact_info[0].Presenter_Profile_Photo_URL__c : null;
                          let author_role = top_author.Role__c !== undefined && top_author.Role__c !== null ? top_author.Role__c : null;
                          let author_job_title = top_author.Contact_info !== undefined && top_author.Contact_info[0] !== undefined && top_author.Contact_info[0]['Job_Title_Position__c'] !== null ? top_author.Contact_info[0]['Job_Title_Position__c'] : '';

                          let author_account_name = top_author.Contact_info !== undefined && top_author.Contact_info[0] !== undefined && top_author.Contact_info[0]['Account_Name'] !== null ? top_author.Contact_info[0]['Account_Name'] : '';


                          if (top_author_index <= 2) {
                            return <Col key={author_index} className="col-auto">
                              <div className="author_card">
                                <img src={author_image == undefined || author_image == null ? user_default_image : author_image} alt={author_image} />
                                <p>{author_name}</p>
                                {author_account_name != '' && author_account_name !== null ? <small>{author_account_name}</small> : null}

                              </div>
                            </Col>
                          }
                        })
                      }
                    </Row>
                  </div>
                  : null
              }

              <div className="program_right d-flex flex-column align-items-start d-lg-none">
                <h4 className="text-lg-center" ><span className="discount_price">{Early_Bird_Price__c !== undefined ? '$' + numberWithCommas(Early_Bird_Price__c) : null}</span>${numberWithCommas(price_float)}</h4>
                <button className={props.getAllWishlist.includes(data.product.handle) ? 'wishlist_link wishlist_link_add' : 'wishlist_link'} onClick={() => props.AddtoWishlist(data.product.handle, sDate)}> {props.getAllWishlist.includes(data.product.handle) ? <> <LikeIcon /> Added</> : <> <LikeIcon /> Add to wishlist</>}</button>
                {!TEN_MIntLeft ?
                  isProgramOver ? null :
                    reached_capacity
                      ? ''
                      : shortPromotionMessage !== ''
                        ? <div className="label_tag">{shortPromotionMessage}</div>
                        : null
                  : null
                }

                {!TEN_MIntLeft ?
                  isProgramOver ? <div className="label_tag">Bookings Closed</div> :
                    reached_capacity
                      ? <div className="label_tag">Fully Booked</div>
                      : <> <div key={`inline-${randm()}`} className="form_check_group d-flex align-items-center">
                        {DeliveryMode.length > 1 ?
                          <>
                            <span>select:</span>
                            {DeliveryMode.map((cpd, index) => {

                              return <Form.Check
                                inline
                                key={'pp' + index}
                                name={data.product.handle}
                                label={cpd}
                                value={cpd}
                                checked={index == DefaultRadio}
                                onChange={(e) => UpdateRadio(e.target.value, index)}
                                type="radio"
                                id={`inline-${randm()}-1`}
                              />
                            })
                            }
                          </>
                          : <span><strong>{DefaultRadioValue}</strong></span>
                        }
                      </div>
                        <div className='program_cta d-flex align-items-center'>
                          <Link className="btn-link" to={`/product/${data.product.handle}`}>Learn more</Link>
                          <a data-record={RecordType} data-test={DefaultRadioValue} className="btn btn-primary" onClick={(e) => props.AddtoCart(data.product.variants.edges[0].node.id, 1, data.product.variants.edges[0].node.sku, DefaultRadioValue, RecordType, data.product.tags, Program_structure_image, data.product.handle, sDate, Program_Structure__c)}>Add to Cart</a>
                        </div>
                      </>
                  : <div className="label_tag">Bookings Closed</div>
                }
              </div>
            </Card.Text>
            {
              presentor.length <= 2 ?
                <div className="author_groups d-none d-lg-block">
                  <Row className="author_listing">
                    {
                      presentor.map((top_author) => {
                        //  console.log('top_author',top_author);
                        top_author_index = top_author_index + 1;
                        let author_name = top_author.Contact_info !== undefined && top_author.Contact_info[0] !== undefined ? top_author.Contact_info[0].Name : null;
                        let author_image = top_author.Contact_info !== undefined && top_author.Contact_info[0] !== undefined ? top_author.Contact_info[0].Presenter_Profile_Photo_URL__c : null;
                        let author_role = top_author.Role__c !== undefined && top_author.Role__c !== null ? top_author.Role__c : null;
                        let author_job_title = top_author.Contact_info !== undefined && top_author.Contact_info[0] !== undefined && top_author.Contact_info[0]['Job_Title_Position__c'] !== null ? top_author.Contact_info[0]['Job_Title_Position__c'] : '';

                        let author_account_name = top_author.Contact_info !== undefined && top_author.Contact_info[0] !== undefined && top_author.Contact_info[0]['Account_Name'] !== null ? top_author.Contact_info[0]['Account_Name'] : '';


                        if (top_author_index <= 2) {
                          return <Col key={author_index} className="col-auto">
                            <div className="author_card">
                              <img src={author_image == undefined || author_image == null ? user_default_image : author_image} alt={author_image} />
                              <p>{author_name}</p>
                              {author_account_name != '' && author_account_name !== null ? <small>{author_account_name}</small> : null}

                            </div>
                          </Col>
                        }
                      })
                    }
                  </Row>
                </div>
                : null
            }
          </Card.Body>
          <div className="program_right d-none d-lg-flex flex-column align-items-end">
            <h4 className="text-lg-center" ><span className="discount_price">{Early_Bird_Price__c !== undefined ? '$' + Early_Bird_Price__c.toFixed(2) : null}</span>${price_float}</h4>
            <button className={props.getAllWishlist.includes(data.product.handle) ? 'wishlist_link wishlist_link_add' : 'wishlist_link'} onClick={() => props.AddtoWishlist(data.product.handle, sDate)}> {props.getAllWishlist.includes(data.product.handle) ? <> <LikeIcon /> Added</> : <> <LikeIcon /> Add to wishlist</>}</button>
            {!TEN_MIntLeft ?
              isProgramOver ? null :
                reached_capacity
                  ? ''
                  : shortPromotionMessage !== ''
                    ? <div className="label_tag">{shortPromotionMessage}</div>
                    : null
              : null
            }

            {!TEN_MIntLeft ?
              isProgramOver ? <div className="label_tag">Bookings Closed</div> :
                reached_capacity
                  ? <div className="label_tag">Fully Booked</div>
                  : <> <div key={`inline-${randm()}`} className="form_check_group d-flex">
                    {DeliveryMode.length > 1
                      ?
                      <>
                        <span>select:</span>
                        {DeliveryMode.map((cpd, index) => {

                          return <Form.Check
                            inline
                            key={index}
                            name={data.product.handle}
                            label={cpd}
                            value={cpd}
                            checked={index == DefaultRadio}
                            onChange={(e) => UpdateRadio(e.target.value, index)}
                            type="radio"
                            id={`inline-${randm()}-1`}
                          />
                        })
                        }
                      </>
                      : <span><strong>{DefaultRadioValue}</strong></span>
                    }
                  </div>
                    <div className='program_cta d-flex align-items-center'>
                      <Link className="btn-link" to={`/product/${data.product.handle}`}>Learn more</Link>
                      <a data-record={RecordType} data-test={DefaultRadioValue} className="btn btn-primary" onClick={(e) => props.AddtoCart(data.product.variants.edges[0].node.id, 1, data.product.variants.edges[0].node.sku, DefaultRadioValue, RecordType, data.product.tags, Program_structure_image, data.product.handle, sDate, Program_Structure__c)}>Add to Cart</a>
                    </div>
                  </>
              : <div className="label_tag">Bookings Closed</div>
            }
          </div>
        </Card>
      </Col>

    </>
  )
}

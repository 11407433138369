import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  LocationIcon,
  DollarIcon,
  ClockIcon,
  CalendarIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  FaceBookIcon,
  TwitterIcon,
  LinkedFIcon,
  MessageIcon,
  PrintIcon,
  ShareIcon,
  LikeIcon,
} from "../../../icons";
import {
  Container,
  Row,
  Col,
  ListGroup,
  Form,
  Modal,
  Accordion,
  Button,
} from "react-bootstrap";
import programimg from "../../../images/program-img.jpg";
import avtar1 from "../../../images/avtar-1.png";
import avtar2 from "../../../images/avtar-2.png";
import user_default_image from "../../../images/user.png";
import pm from "../../../images/pm-ic.png";
import ps from "../../../images/ps-ic.png";
import sl from "../../../images/sl-ic.png";
import ep from "../../../images/ep-ic.png";
import { PresentorModal } from "../../Modals/Presentor";
import { RelatedProgram } from "./../relatedProgram/RelatedProgram";

import Bg from "../../../images/tile-new.png";

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from "swiper";
// install Swiper modules
SwiperCore.use([Navigation, Pagination]);
const TypeProgram = (props) => {
  // new api datas
  let title =
    props.single_program_basic_data.title !== undefined
      ? props.single_program_basic_data.title
      : "";
  let handlee = props.handle !== undefined ? props.handle : "";
  let description =
    props.single_program_basic_data.description !== undefined
      ? props.single_program_basic_data.description
      : "";
  let thumbnail = programimg;
  let date_line1 =
    props.single_program_data.Program_Date_Time_Line_1__c != ""
      ? props.single_program_data.Program_Date_Time_Line_1__c
      : "";
  let date_line2 =
    props.single_program_data.Program_Date_Time_Line_2__c != ""
      ? props.single_program_data.Program_Date_Time_Line_2__c
      : "";
  let Designed_For__c =
    props.single_program_data.Designed_For__c != ""
      ? props.single_program_data.Designed_For__c
      : "";
  let Catering =
    props.single_program_data.Catering__c != ""
      ? props.single_program_data.Catering__c
      : "";
  let Venue__c =
    props.single_program_data.Venue__c != "" &&
    props.single_program_data.Venue__c !== null
      ? props.single_program_data.Venue__c
      : "";
  let Venue_link =
    Venue__c !== "" && Venue__c !== "Live Stream Only"
      ? "https://www.google.com/maps/search/?api=1&query=" + Venue__c
      : "#";
    
  let Ethics =
    props.single_program_data.Ethics_Professional_Responsibility__c > 0
      ? props.single_program_data.Ethics_Professional_Responsibility__c
      : 0;
  let practice_managment =
    props.single_program_data.Practice_Management_Business_Skills__c > 0
      ? props.single_program_data.Practice_Management_Business_Skills__c
      : 0;
  let professional_skills =
    props.single_program_data.Professional_Skills__c > 0
      ? props.single_program_data.Professional_Skills__c
      : 0;
  let Substantive_Law =
    props.single_program_data.Substantive_Law__c > 0
      ? props.single_program_data.Substantive_Law__c
      : 0;
  let total_points =
    parseFloat(Ethics) +
    parseFloat(practice_managment) +
    parseFloat(professional_skills) +
    parseFloat(Substantive_Law);
  let Short_Promotion_Message__c =
    props.single_program_data.Short_Promotion_Message__c != ""
      ? props.single_program_data.Short_Promotion_Message__c
      : "";
  let Long_Promotion_Message__c =
    props.single_program_data.Long_Promotion_Message__c != ""
      ? props.single_program_data.Long_Promotion_Message__c
      : "";
  let delivery_mode =
    props.single_program_data.delivery_mode != ""
      ? props.single_program_data.delivery_mode
      : [];
  let product_code =
    props.single_program_basic_data.product_code !== undefined &&
    props.single_program_basic_data.product_code != ""
      ? props.single_program_basic_data.product_code
      : "";
  let product_price =
    props.single_program_basic_data.price !== undefined &&
    props.single_program_basic_data.price != ""
      ? props.single_program_basic_data.price
      : 0;
  let related_program =
    props.single_program_data.related_program.length > 0
      ? props.single_program_data.related_program
      : [];
  let presentor =
    props.single_program_data.presentor.length > 0
      ? props.single_program_data.presentor
      : [];
  let product_image_url =
    props.single_program_data.product_image_url !== null
      ? props.single_program_data.product_image_url
      : programimg;
  let session_topics =
    props.single_program_data.topic !== undefined &&
    props.single_program_data.topic !== null
      ? props.single_program_data.topic
      : [];
  let TEN_MINITES = props.is_ten_minite_less(
    props.single_program_data.start_date_time
  )
    ? true
    : false;
  let isPorgramOver = props.program_date_is_gone_or_not(
    props.single_program_data.start_date_time
  )
    ? true
    : false;
  let start_date_time =
    props.single_program_data.start_date_time != ""
      ? props.single_program_data.start_date_time
      : "";
  const Program_Structure__c =
    props.single_program_data.Program_Structure__c !== "" &&
    props.single_program_data.Program_Structure__c !== null &&
    props.single_program_data.Program_Structure__c !== undefined
      ? props.single_program_data.Program_Structure__c
      : null;
  const Program_Structure__c_Array =
    Program_Structure__c !== null ? Program_Structure__c.split(" ") : [];
  const Early_Bird_Price__c =
    props.single_program_data.Compare_Price !== "" &&
    props.single_program_data.Compare_Price !== null
      ? props.single_program_data.Compare_Price
      : undefined;
  const [DefaultRadioValue, setDefaultRadioValue] = useState(
    delivery_mode === undefined || delivery_mode.length == 0
      ? ""
      : delivery_mode[0]
  );

  const [DefaultRadio, setDefaultRadio] = useState(0);

  const UpdateRadio = (value, index) => {
    setDefaultRadioValue(value);
    setDefaultRadio(index);
  };
  session_topics.sort(function (a, b) {
    return parseInt(a.Session_No__c) - parseInt(b.Session_No__c);
  });
  //console.log(session_topics);
  if (props.single_program_data.file.length > 0) {
    props.single_program_data.file.forEach((file_val, file_key) => {
      if (file_val.NEILON__Category__c == "Product Image") {
        thumbnail =
          file_val.NEILON__File_Presigned_URL__c != ""
            ? file_val.NEILON__File_Presigned_URL__c
            : programimg;
      }
    });
  }

  // new api datas end

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  useEffect(() => {
    if (!show) {
      props.RevetCopyText();
    }
  }, [show]);
  const handleShow = () => setShow(true);
  let top_author_index = 0;

  const randm = () => {
    return (Math.random() + 1).toString(36).substring(7);
  };

  const [showPresentorModal, setShowPresentorModal] = useState(false);
  const [presentor_modal_data, set_presentor_modal_data] = useState({
    name: "",
    image: "",
    job: "",
    description: "",
    linkedin: "",
    author_company_title: "",
  });
  const show_presentor_modal = () => {
    setShowPresentorModal(true);
  };
  const hide_presentor_modal = () => {
    setShowPresentorModal(false);
  };

  const price_float = parseFloat(product_price);

  let Session_need_to_show = false;
  session_topics.map((topic, index) => {
    // console.log('topic', topic);
    const topic_desc =
      topic.Blurb__c !== null && topic.Blurb__c !== "" ? topic.Blurb__c : "";
    const topic_allocated_time =
      topic.Allocated_Time_Minutes__c !== undefined &&
      topic.Allocated_Time_Minutes__c !== null &&
      topic.Allocated_Time_Minutes__c > 0
        ? topic.Allocated_Time_Minutes__c
        : 0;
    if (
      topic_desc !== null &&
      topic_desc !== "" &&
      topic_allocated_time > 0 &&
      index !== 0
    ) {
      Session_need_to_show = true;
    }
  });

  const numberWithCommas = (x) => {
    return parseFloat(x)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  //console.log("isPorgramOver ->"+isPorgramOver);
  return (
    <div className={"single_program_page mprogram " + props.product_page_type}>
      <Container>
        <div className="single_program_wrapper position-relative">
          <Row>
            <Col xl={8} lg={7} xs={12}>
              <div className="cpd_single_programs_content">
                <div className="single_programs_head">
                  {/* <img src={product_image_url} alt="BigCo Inc. logo" className="d-none d-md-block" /> */}
                  <h1 className="mb-0">
                    {title}{" "}
                    <small className="product_code d-md-none">
                      {product_code}
                    </small>
                  </h1>
                </div>
                <div className="cpd_points_mode_type_block d-flex flex-column flex-md-row">
                  <div className="cpd_points_mode_type_img">
                    {Program_Structure__c !== undefined ? (
                      <div
                        className="program_cate d-flex justify-content-start align-items-start flex-column"
                        style={{
                          backgroundColor: props.Program_Types__c_color,
                        }}
                      >
                        {Program_Structure__c_Array.length > 1 ? (
                          <>
                            <h3>{Program_Structure__c_Array[0]}</h3>
                            <small>
                              {Program_Structure__c_Array.join(" ").replace(
                                Program_Structure__c_Array[0],
                                ""
                              )}
                            </small>
                          </>
                        ) : (
                          <h3>{Program_Structure__c}</h3>
                        )}
                      </div>
                    ) : null}
                  </div>
                  <div className="cpd_points_mode_type_txt">
                    {Program_Structure__c ===
                    "Medicare Billing Course" ? null : (
                      <div className='cpd_point_item d-flex flex-column flex-sm-row flex-lg-column flex-xl-row align-item-center"'>
                        <span className="item_label">CPD Points</span>
                        <ListGroup
                          horizontal
                          as="ul"
                          className="d-flex flex-row align-items-center"
                        >
                          <ListGroup.Item as="li">
                            {professional_skills > 0 ? (
                              <img
                                src={ps}
                                alt="Professional Skills"
                                title="Professional Skills"
                              />
                            ) : null}
                          </ListGroup.Item>
                          <ListGroup.Item as="li">
                            {practice_managment > 0 ? (
                              <img
                                src={pm}
                                alt="Practice Management & Business Skills"
                                title="Practice Management & Business Skills"
                              />
                            ) : null}
                          </ListGroup.Item>
                          <ListGroup.Item as="li">
                            {Substantive_Law > 0 ? (
                              <img
                                src={sl}
                                alt="Substantive Law"
                                title="Substantive Law"
                              />
                            ) : null}
                          </ListGroup.Item>
                          <ListGroup.Item as="li">
                            {Ethics > 0 ? (
                              <img
                                src={ep}
                                alt="Ethics & Professional Responsibility"
                                title="Ethics & Professional Responsibility"
                              />
                            ) : null}
                          </ListGroup.Item>
                          <ListGroup.Item as="li">
                            {total_points} Point{total_points > 1 ? "s" : null}
                          </ListGroup.Item>
                        </ListGroup>
                      </div>
                    )}
                    {delivery_mode.length ? (
                      <div className='cpd_delivery_mode d-flex flex-column flex-sm-row flex-lg-column flex-xl-row align-item-center"'>
                        <span className="item_label">Delivery Mode</span>
                        <ListGroup
                          horizontal
                          as="ul"
                          className="d-flex flex-row align-items-center"
                        >
                          {delivery_mode.map((cpd, index) => {
                            return (
                              <ListGroup.Item as="li" key={"cc" + index}>
                                <span>{cpd}</span>
                                {delivery_mode.length !== index + 1
                                  ? ", "
                                  : null}
                              </ListGroup.Item>
                            );
                          })}
                        </ListGroup>
                      </div>
                    ) : null}
                    {props.programType.length ? (
                      <div className='cpd_program_type d-flex flex-column flex-sm-row flex-lg-column flex-xl-row align-item-center"'>
                        <span className="item_label">Program type</span>
                        {props.programType.map((cpd, index) => {
                          return (
                            <p className="mb-0" key={"type" + index}>
                              {cpd}
                              {props.programType.length !== index + 1
                                ? ","
                                : null}{" "}
                            </p>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="single_rightside">
                  <div className="single_rightside_inner">
                    <div className="program_right_cart">
                      <div className="program_right_cart_head d-flex flex-column flex-sm-row justify-content-between">
                        <h2 className="text-primary d-none d-md-block">
                          {title}{" "}
                          <small className="product_code">{product_code}</small>
                        </h2>
                        <div className="program_price d-none d-md-inline-block fsadfsfd">
                          ${numberWithCommas(price_float)}
                          <br />
                          <span className="discount_price">
                            {Early_Bird_Price__c !== undefined
                              ? "$" + numberWithCommas(Early_Bird_Price__c)
                              : null}
                          </span>
                        </div>
                      </div>

                      <div className="wishlist_and_short_sms d-flex flex-wrap align-items-center">
                        <button
                          className={
                            props.getAllWishlist.includes(handlee)
                              ? "wishlist_link wishlist_link_add"
                              : "wishlist_link"
                          }
                          onClick={() =>
                            props.AddtoWishlist(handlee, start_date_time)
                          }
                        >
                          {" "}
                          {props.getAllWishlist.includes(handlee) ? (
                            <>
                              {" "}
                              <LikeIcon /> Added
                            </>
                          ) : (
                            <>
                              {" "}
                              <LikeIcon /> Add to wishlist
                            </>
                          )}
                        </button>

                        {!props.reached_capacity ? (
                          Short_Promotion_Message__c != "" &&
                          Short_Promotion_Message__c !== null ? (
                            <ListGroup
                              horizontal
                              as="ul"
                              className="short_promotion_message ms-3"
                            >
                              <ListGroup.Item as="li">
                                <div className="label_tag p-0">
                                  {Short_Promotion_Message__c}
                                </div>
                              </ListGroup.Item>
                            </ListGroup>
                          ) : null
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="length_block list_block">
                        {date_line1 !== "" && date_line1 !== null ? (
                          <>
                            {date_line1 != "" ? (
                              <small className="d-flex">
                                <CalendarIcon /> {date_line1}
                              </small>
                            ) : null}
                          </>
                        ) : null}
                        {date_line2 !== "" && date_line2 !== null ? (
                          <>
                            {date_line2 != "" ? (
                              <small className="d-flex">
                                <ClockIcon /> {date_line2}
                              </small>
                            ) : null}
                          </>
                        ) : null}
                        <a
                          className="print_info"
                          onClick={() => window.print()}
                        >
                          <PrintIcon />
                          Print Course Information
                        </a>
                        {Long_Promotion_Message__c !== "" &&
                        Long_Promotion_Message__c !== null ? (
                          <p className="price_sale d-flex">
                            <ClockIcon stroke="#fe1010" />{" "}
                            {Long_Promotion_Message__c}
                          </p>
                        ) : null}
                      </div>
                      {Venue__c !== "" && Venue__c !== null ? (
                        <div className="designed_block list_block">
                          <p>Venue</p>
                          <small><a href={Venue_link} target="_blank">{Venue__c}</a></small>
                        </div>
                      ) : null}
                      {Designed_For__c !== "" && Designed_For__c !== null ? (
                        <div className="designed_block list_block">
                          <p>Designed for</p>
                          <small>{Designed_For__c}</small>
                        </div>
                      ) : null}
                      {Catering != "" &&
                      Catering != null &&
                      delivery_mode !== null &&
                      delivery_mode !== undefined &&
                      delivery_mode.includes("Face to Face") ? (
                        <div className="catering_block list_block">
                          <p>Catering</p>
                          <small>{Catering}</small>
                        </div>
                      ) : null}

                      <div className="quantity_block list_block">
                        <div className="program_right_cart_head mb-3">
                          <div className="program_price d-md-none">
                            <span className="discount_price">
                              {Early_Bird_Price__c !== undefined
                                ? "$" + numberWithCommas(Early_Bird_Price__c)
                                : null}
                            </span>
                            ${numberWithCommas(price_float)}
                          </div>
                        </div>

                        {!TEN_MINITES ? (
                          isPorgramOver ? null : !props.reached_capacity &&
                            delivery_mode.length ? (
                            <div className="form_check_group d-flex align-items-center">
                              <span>Select: </span>
                              {delivery_mode !== undefined &&
                              delivery_mode.length > 0
                                ? delivery_mode.map((cpd, index) => {
                                    return (
                                      <Form.Check
                                        inline
                                        key={`inline5-${randm()}`}
                                        name={props.handle}
                                        label={cpd}
                                        value={cpd}
                                        checked={
                                          parseInt(index) ===
                                          parseInt(DefaultRadio)
                                        }
                                        onChange={(e) =>
                                          UpdateRadio(e.target.value, index)
                                        }
                                        type="radio"
                                        id={`inline-${randm()}-1`}
                                      />
                                    );
                                  })
                                : ""}
                            </div>
                          ) : null
                        ) : null}
                        <div className="d-flex align-items-center justify-content-between">
                          {!TEN_MINITES ? (
                            isPorgramOver ? null : !props.reached_capacity ? (
                              <Form.Group
                                className="mb-0"
                                controlId="formBasicEmail"
                              >
                                <div className="quantity_group position-relative d-inline-flex align-items-center">
                                  <Form.Label className="text-primary">
                                    Qty
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="quantity"
                                    onChange={(e) => props.changeQuantity(e)}
                                    value={props.quantity}
                                    placeholder={props.quantity}
                                  />
                                  <div className="quantity_changer">
                                    <span
                                      className="up_no"
                                      onClick={() =>
                                        props.increaseQuantity(
                                          parseFloat(props.quantity) +
                                            parseFloat(1)
                                        )
                                      }
                                    >
                                      <ArrowUpIcon />
                                    </span>
                                    <span
                                      className="down_no"
                                      onClick={() =>
                                        props.decreaseQuantity(
                                          parseFloat(props.quantity) -
                                            parseFloat(1)
                                        )
                                      }
                                    >
                                      <ArrowDownIcon />
                                    </span>
                                  </div>
                                </div>
                              </Form.Group>
                            ) : null
                          ) : null}
                          {!TEN_MINITES ? (
                            isPorgramOver ? (
                              <div className="label_tag">Bookings Closed</div>
                            ) : !props.reached_capacity ? (
                              <a
                                data-tet={props.SingleVariants.id}
                                onClick={(e) =>
                                  props.AddtoCart(
                                    props.SingleVariants.id,
                                    props.quantity > 0 ? props.quantity : 1,
                                    props.SingleVariants.sku,
                                    DefaultRadioValue,
                                    props.recordType,
                                    props.tags,
                                    props.Program_structure_image,
                                    handlee,
                                    props.single_program_data.start_date_time,
                                    Program_Structure__c
                                  )
                                }
                                className="btn btn-secondary mt-0 btn_cart"
                              >
                                Add to Cart
                              </a>
                            ) : (
                              <strong>Fully Booked</strong>
                            )
                          ) : (
                            <div className="label_tag">Bookings Closed</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <a
                    className="share_colleagues_link d-flex align-items-center justify-content-center"
                    onClick={handleShow}
                  >
                    <ShareIcon />
                    Share with Colleagues
                  </a>
                </div>

                <Accordion defaultActiveKey="0">
                  {presentor.length ? (
                    <Accordion.Item
                      eventKey="0"
                      className="presenters-accordion"
                    >
                      <Accordion.Header>Presenters</Accordion.Header>
                      <Accordion.Body>
                        <div className="author_group">
                          <Row xs={1} xl={2}>
                            {presentor.map((top_author) => {
                              top_author_index = top_author_index + 1;
                              let author_name =
                                top_author.Contact_info !== undefined &&
                                top_author.Contact_info[0] !== undefined
                                  ? top_author.Contact_info[0].Name
                                  : null;
                              let author_image =
                                top_author.Contact_info !== undefined &&
                                top_author.Contact_info[0] !== undefined
                                  ? top_author.Contact_info[0]
                                      .Presenter_Profile_Photo_URL__c
                                  : null;
                              let author_role =
                                top_author.Role__c !== undefined &&
                                top_author.Role__c !== null
                                  ? top_author.Role__c
                                  : null;
                              let author_job_title =
                                top_author.Contact_info !== undefined &&
                                top_author.Contact_info[0] !== undefined &&
                                top_author.Contact_info[0][
                                  "Job_Title_Position__c"
                                ] !== null
                                  ? top_author.Contact_info[0][
                                      "Job_Title_Position__c"
                                    ]
                                  : "";
                              let author_account_name =
                                top_author.Contact_info !== undefined &&
                                top_author.Contact_info[0] !== undefined &&
                                top_author.Contact_info[0]["Account_Name"] !==
                                  null
                                  ? top_author.Contact_info[0]["Account_Name"]
                                  : "";
                              let author_description =
                                top_author.Contact_info !== undefined &&
                                top_author.Contact_info[0] !== undefined &&
                                top_author.Contact_info[0][
                                  "Presenter_Instructor_Profile__c"
                                ] !== null
                                  ? top_author.Contact_info[0][
                                      "Presenter_Instructor_Profile__c"
                                    ]
                                  : "";
                              let author_linkedin =
                                top_author.Contact_info !== undefined &&
                                top_author.Contact_info[0] !== undefined
                                  ? top_author.Contact_info[0].LinkedIn_URL__c
                                  : null;
                              let author_top_job_account = [];
                              if (author_job_title !== "") {
                                author_top_job_account = [
                                  ...author_top_job_account,
                                  author_job_title,
                                ];
                              }
                              if (author_account_name !== "") {
                                author_top_job_account = [
                                  ...author_top_job_account,
                                  author_account_name,
                                ];
                              }
                              return (
                                <Col key={top_author_index}>
                                  <div className="author_card">
                                    <div className="author_card_head">
                                      <img
                                        src={
                                          author_image == undefined ||
                                          author_image == null
                                            ? user_default_image
                                            : author_image
                                        }
                                        alt={author_image}
                                      />
                                      <p>{author_name}</p>
                                      <small>
                                        {author_top_job_account.join(", ")}
                                      </small>
                                    </div>
                                    <div className="author_card_content quote_description">
                                      {author_description.length >= 200 ? (
                                        <>
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                author_description.substring(
                                                  0,
                                                  200
                                                ),
                                            }}
                                          />
                                          <a
                                            onClick={() => {
                                              let popup_author_image =
                                                author_image == undefined ||
                                                author_image == null ||
                                                author_image == ""
                                                  ? user_default_image
                                                  : author_image;
                                              setShowPresentorModal(true);
                                              set_presentor_modal_data({
                                                name: author_name,
                                                image: popup_author_image,
                                                job: author_top_job_account.join(
                                                  ", "
                                                ),
                                                description: author_description,
                                                linkedin: author_linkedin,
                                                author_company_title: "",
                                              });
                                            }}
                                          >
                                            Read More
                                          </a>
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                </Col>
                              );
                            })}
                          </Row>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : null}
                  {description !== "" && description !== null ? (
                    <Accordion.Item
                      eventKey={presentor.length ? "1" : "0"}
                      className="description-accordion"
                    >
                      <Accordion.Header>Description</Accordion.Header>
                      <Accordion.Body>
                        <div className="programs_description">
                          <div
                            dangerouslySetInnerHTML={{ __html: description }}
                          />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : null}
                  {
                    // PROGRAM SESSIONS
                    Session_need_to_show ? (
                      <Accordion.Item
                        eventKey="10"
                        className="sessions-accordion"
                      >
                        <Accordion.Header>Sessions</Accordion.Header>
                        <Accordion.Body>
                          {session_topics.map((topic) => {
                            // console.log('topic', topic);
                            const topic_name =
                              topic.Session__c !== null &&
                              topic.Session__c !== ""
                                ? topic.Session__c
                                : "";
                            const topic_desc =
                              topic.Blurb__c !== null && topic.Blurb__c !== ""
                                ? topic.Blurb__c
                                : "";
                            const topic_CPD_category =
                              topic.CPD_Category__c !== null
                                ? topic.CPD_Category__c.split(";")
                                : [];
                            const topic_allocated_time =
                              topic.Allocated_Time_Minutes__c !== undefined &&
                              topic.Allocated_Time_Minutes__c !== null &&
                              topic.Allocated_Time_Minutes__c > 0
                                ? topic.Allocated_Time_Minutes__c
                                : 0;
                            //   console.log('topic_allocated_time', topic_allocated_time);
                            {
                              if (
                                topic_name !== null &&
                                topic_name !== "" &&
                                topic_desc !== null &&
                                topic_desc !== "" &&
                                topic_allocated_time > 0
                              ) {
                                return (
                                  <div className="">
                                    <h5 className="display-5">
                                      {topic_name}{" "}
                                      {topic_CPD_category.map((cpd) => {
                                        var cpd_image = undefined;
                                        if (
                                          cpd ===
                                          "Practice Management & Business Skills"
                                        ) {
                                          cpd_image = pm;
                                        }
                                        if (cpd === "Professional Skills") {
                                          cpd_image = ps;
                                        }
                                        if (cpd === "Substantive Law") {
                                          cpd_image = sl;
                                        }
                                        if (
                                          cpd ===
                                          "Ethics & Professional Responsibility"
                                        ) {
                                          cpd_image = ep;
                                        }
                                        return (
                                          <img
                                            src={cpd_image}
                                            alt={cpd}
                                            className="mx-2"
                                          />
                                        );
                                      })}
                                    </h5>
                                    {topic.Contact_info != null &&
                                    topic.Contact_info.length > 0 ? (
                                      <div className="author_group">
                                        <Row xs={1} xl={2}>
                                          {topic.Contact_info.map(
                                            (topic_author) => {
                                              let topic_author_job_title =
                                                topic_author.Job_Title_Position__c !==
                                                  undefined &&
                                                topic_author.Job_Title_Position__c !==
                                                  null &&
                                                topic_author.Job_Title_Position__c !==
                                                  ""
                                                  ? topic_author.Job_Title_Position__c
                                                  : "";
                                              let topic_author_account_name =
                                                topic_author.Account_Name !==
                                                  undefined &&
                                                topic_author.Account_Name !==
                                                  null &&
                                                topic_author.Account_Name !== ""
                                                  ? topic_author.Account_Name
                                                  : "";
                                              let topic_author_top_job_account =
                                                [];
                                              if (
                                                topic_author_job_title !== ""
                                              ) {
                                                topic_author_top_job_account = [
                                                  ...topic_author_top_job_account,
                                                  topic_author_job_title,
                                                ];
                                              }
                                              if (
                                                topic_author_account_name !== ""
                                              ) {
                                                topic_author_top_job_account = [
                                                  ...topic_author_top_job_account,
                                                  topic_author_account_name,
                                                ];
                                              }

                                              return (
                                                <Col>
                                                  <div className="author_card">
                                                    <div className="author_card_head">
                                                      <img
                                                        src={
                                                          topic_author.Presenter_Profile_Photo_URL__c !==
                                                          null
                                                            ? topic_author.Presenter_Profile_Photo_URL__c
                                                            : user_default_image
                                                        }
                                                        alt={topic_author.Name}
                                                      />
                                                      <p>{topic_author.Name}</p>
                                                      <small>
                                                        {topic_author_top_job_account.join(
                                                          ", "
                                                        )}
                                                      </small>
                                                    </div>
                                                  </div>
                                                </Col>
                                              );
                                            }
                                          )}
                                        </Row>
                                      </div>
                                    ) : null}

                                    <div className="programs_description">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: topic_desc,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                );
                              }
                            }
                          })}
                        </Accordion.Body>
                      </Accordion.Item>
                    ) : null
                  }
                </Accordion>
              </div>
            </Col>
            <Col xl={4} lg={5} xs={12}></Col>
          </Row>
        </div>
      </Container>
      {related_program !== undefined && related_program.length > 0 ? (
        <div
          className="related_courses_wrapper"
          style={{ backgroundImage: "url(" + Bg + ")" }}
        >
          <Container>
            <h2 className="text-center">Related Programs</h2>
            <Swiper
              cssMode={true}
              spaceBetween={24}
              slidesPerView={4}
              navigation={false}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1200: {
                  slidesPerView: 4,
                },
              }}
              modules={[Pagination]}
            >
              {related_program.map((related_program) => {
                const desc =
                  related_program.Description__c !== undefined &&
                  related_program.Description__c !== null
                    ? related_program.Description__c.substring(0, 150)
                    : null;
                const rdata_permalink =
                  "product/" + related_program.Slug__c !== null
                    ? related_program.Slug__c
                    : "";
                if (related_program.Related_Program__c != "") {
                  return (
                    <SwiperSlide key={Math.random()}>
                      <RelatedProgram
                        productHandle={related_program.Slug__c}
                        AddtoCart={props.AddtoCart}
                        Program_structure_images={props.Program_structure_image}
                        is_ten_minite_less={props.is_ten_minite_less}
                        program_date_is_gone_or_not={
                          props.program_date_is_gone_or_not
                        }
                      />
                    </SwiperSlide>
                  );
                }
              })}
            </Swiper>
          </Container>
        </div>
      ) : null}

      <>
        <Modal show={show} onHide={handleClose} className="share_modal">
          <Modal.Header closeButton className="border-0"></Modal.Header>
          <Modal.Body>
            <Modal.Title className="text-center">
              Share This Program
            </Modal.Title>
            <ListGroup
              horizontal
              as="ul"
              className="social_media_list justify-content-center"
            >
              <ListGroup.Item
                as="li"
                className="social_item"
                onClick={() => props.FBshare()}
              >
                <FaceBookIcon fill="#004e6d" />
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className="social_item"
                onClick={() => props.TwitterShare()}
              >
                <TwitterIcon fill="#004e6d" />
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className="social_item"
                onClick={() => props.LinkedinShare()}
              >
                <LinkedFIcon fill="#004e6d" />
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className="social_item"
                onClick={() => props.emailShare()}
              >
                <MessageIcon fill="#004e6d" />
              </ListGroup.Item>
            </ListGroup>
            <a
              onClick={() => props.CopyLink()}
              className="btn btn-primary w-100"
            >
              {props.Clipboard}
            </a>
          </Modal.Body>
        </Modal>
      </>
      <PresentorModal
        presentor_modal_data={presentor_modal_data}
        show={showPresentorModal}
        hide={hide_presentor_modal}
      />
    </div>
  );
};

export default TypeProgram;

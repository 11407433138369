import React, { useState } from 'react';
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    Alert
} from 'react-bootstrap';
import axios from 'axios';

import Ctabg from '../../images/cta-img.jpg';
import BannerLogoIcon from '../../images/banner-logo-icon.png';

export const CTASection = (props) => {

    const [formOption, setFormOption] = useState(true);

    const ToggleOption = () => {
        setFormOption(!formOption);
    }

    const [buttonText, setbuttonText] = useState("Submit");
    const [SuccessMessage, setSuccessMessage] = useState(undefined);
    const [ErrorMessage, setErrorMessage] = useState(undefined);
    const [SomethingUpadating, setSomethingUpadating] = useState(false);

    const handleSubmit = (event) => {

        setSuccessMessage(undefined);
        setErrorMessage(undefined);
        setSomethingUpadating(true);
        event.preventDefault();
        const finalDate2 = serialize(document.getElementById('49516833'));
       // console.log(finalDate2);
        axios.get(props.EngineURL + 'signup/index.php?' + finalDate2)
            .then(function (response) {

                setTimeout(function () {
                    if (response != null) {
                        if (response.data.code == 200) {
                            setSuccessMessage(response.data.message);
                            localStorage.setItem("new_practice_area", response.data.new_practice_area);
                        }
                        else {
                            setErrorMessage(response.data.message);
                        }
                    }
                    else {
                        setErrorMessage("Something went wrong!");

                    }
                    setSomethingUpadating(false);
                }, 1000);

            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const serialize = (form) => {
        var field, s = [];
        if (typeof form == 'object' && form.nodeName == "FORM") {
            var len = form.elements.length;
            for (var i = 0; i < len; i++) {
                field = form.elements[i];
                if (field.name && !field.disabled && field.type != 'file' && field.type != 'reset' && field.type != 'submit' && field.type != 'button') {
                    if (field.type == 'select-multiple') {
                        for (var j = form.elements[i].options.length - 1; j >= 0; j--) {
                            if (field.options[j].selected)
                                s[s.length] = encodeURIComponent(field.name) + "=" + encodeURIComponent(field.options[j].value);
                        }
                    } else if ((field.type != 'checkbox' && field.type != 'radio') || field.checked) {
                        s[s.length] = encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value);
                    }
                }
            }
        }
        return s.join('&').replace(/%20/g, '+');
    }

    return (
        <div className='cta_wrapper' style={{ backgroundImage: "url(" + Ctabg + ")" }}>
            <div className="cta_logo_icon"><img src={BannerLogoIcon} alt="Leo Cussen" /></div>
            <Container>
                <Row>
                    <Col xl={6} lg={8} xs={12}>
                        <div className='cta_content'>
                            <div className='title'>
                                <h2 className='h1'>Stay informed.</h2>
                                <h2 className='fw-light'>Get insights from industry practitioners straight into your inbox.</h2>
                            </div>
                            <Form className='cta_form' method="post" onSubmit={handleSubmit} id="49516833">
                                <Row>
                                    <Col md={6} xs={12}>
                                        <Form.Group className='form-group'>
                                            <Form.Control name="tfa_2" type="text" placeholder="First Name*" required />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <Form.Group className='form-group'>
                                            <Form.Control name="tfa_3" type="text" placeholder="Last Name*" required />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <Form.Group className='form-group'>
                                            <Form.Control name="tfa_1" type="email" placeholder="Email Address*" required />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <Form.Group className='form-group'>
                                            <Form.Control name="tfa_17" type="text" placeholder="Organisation" />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className='cstype_wrap'>
                                        <Form.Check  inline type='checkbox' value="tfa_19" name="tfa_19" id="tfa_19" label="Building & Construction" />
                                                <Form.Check  inline type='checkbox' value="tfa_20" name="tfa_20" id="tfa_20" label="Business and Contracts" />
                                                <Form.Check  inline type='checkbox' value="tfa_21" name="tfa_21" id="tfa_21" label="Consumer" />
                                                <Form.Check  inline type='checkbox' value="tfa_22" name="tfa_22" id="tfa_22" label="Corporate & In-House Counsel" />
                                                <Form.Check  inline type='checkbox' value="tfa_23" name="tfa_23" id="tfa_23" label="Costs" />
                                            <div className={formOption ? 'd-none' : ''}>
                                            <Form.Check  inline type='checkbox' value="tfa_24" name="tfa_24" id="tfa_24" label="Criminal" />
                                                <Form.Check  inline type='checkbox' value="tfa_25" name="tfa_25" id="tfa_25" label="Employment & Workplace Relations" />
                                                <Form.Check  inline type='checkbox' value="tfa_26" name="tfa_26" id="tfa_26" label="Ethics" />
                                                <Form.Check  inline type='checkbox' value="tfa_27" name="tfa_27" id="tfa_27" label="Family" />
                                                <Form.Check  inline type='checkbox' value="tfa_28" name="tfa_28" id="tfa_28" label="Finance, Tax, Superannuation" />
                                                <Form.Check  inline type='checkbox' value="tfa_29" name="tfa_29" id="tfa_29" label="Government, Privacy & FOI" />
                                                <Form.Check  inline type='checkbox' value="tfa_30" name="tfa_30" id="tfa_30" label="Insolvency & Restructuring" />
                                                <Form.Check  inline type='checkbox' value="tfa_31" name="tfa_31" id="tfa_31" label="Intellectual Property" />
                                                <Form.Check  inline type='checkbox' value="tfa_32" name="tfa_32" id="tfa_32" label="Litigation & Advocacy" />
                                                <Form.Check  inline type='checkbox' value="tfa_33" name="tfa_33" id="tfa_33" label="Migration" />
                                                <Form.Check  inline type='checkbox' value="tfa_34" name="tfa_34" id="tfa_34" label="Personal Injury & Medico-Legal" />
                                                <Form.Check  inline type='checkbox' value="tfa_35" name="tfa_35" id="tfa_35" label="Professional Skills & Practice Management" />
                                                <Form.Check  inline type='checkbox' value="tfa_36" name="tfa_36" id="tfa_36" label="Property, Leases & Planning" />
                                                <Form.Check  inline type='checkbox' value="tfa_37" name="tfa_37" id="tfa_37" label="Sports Law" />
                                                <Form.Check  inline type='checkbox' value="tfa_38" name="tfa_38" id="tfa_38" label="Wills, Trusts & Succession Planning" />
                                            </div>
                                            <div className='more_options small d-inline-block' onClick={() => ToggleOption()}>{formOption ? 'Show More Options' : 'Show Less Options'}</div>

                                        </div>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <input type="hidden" value="4951683" name="tfa_dbFormId" id="tfa_dbFormId" />
                                        <input type="hidden" value="" name="tfa_dbResponseId" id="tfa_dbResponseId" />
                                        <input type="hidden" value="9ddbb774993d2dd282ddaa3326b474d9" name="tfa_dbControl" id="tfa_dbControl" />
                                        <input type="hidden" value="" name="tfa_dbWorkflowSessionUuid" id="tfa_dbWorkflowSessionUuid" />
                                        <input type="hidden" value="2" name="tfa_dbVersionId" id="tfa_dbVersionId" />
                                        <input type="hidden" value="" name="tfa_switchedoff" id="tfa_switchedoff" />
                                        <Button variant="secondary" type="submit">
                                            {SomethingUpadating ? 'Please wait..' : 'Submit'}
                                        </Button>

                                    </Col>
                                    <Col md={12} xs={12}>
                                        <br></br>
                                        {ErrorMessage !== undefined ?
                                            <Alert variant="danger">
                                                {ErrorMessage}
                                            </Alert>
                                            : ''}
                                        {SuccessMessage !== undefined ?
                                            <Alert variant="primary">Thank you for signing up to our mailing list</Alert> : null}
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default CTASection;

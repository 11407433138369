import React, { useState, useEffect } from "react";
import {
  UserIcon,
  CallPhoneIcon,
  LoaderIcon,
  InformationIcon,
} from "../../icons";
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Button,
  Accordion,
  AccordionButton,
  Alert,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import queryString, { parse } from "query-string";
import { PrivacyPolicy } from "../Modals/PrivacyPolicy";
import { TermsCondition } from "../Modals/TermsCondition";
import axios from "axios";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Helmet } from "react-helmet";

const CheckoutFragment = gql`
  fragment CheckoutFragment on Checkout {
    id
    webUrl
    subtotalPrice {
      amount
      currencyCode
    }
    totalPrice {
      amount
      currencyCode
    }
    lineItems(first: 250) {
      edges {
        node {
          id
          title
          variant {
            id
            title
            price {
              amount
              currencyCode
            }
          }
          quantity
        }
      }
    }
  }
`;

export const checkoutCustomerAssociate = gql`
  mutation checkoutCustomerAssociateV2(
    $checkoutId: ID!
    $customerAccessToken: String!
  ) {
    checkoutCustomerAssociateV2(
      checkoutId: $checkoutId
      customerAccessToken: $customerAccessToken
    ) {
      userErrors {
        field
        message
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

const GetuserAddress = gql`
  query ($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      firstName
      lastName
      email
      phone
      addresses(first: 10) {
        edges {
          node {
            address1
            address2
            city
            company
            zip
            country
            countryCodeV2
            firstName
            lastName
            phone
            id
            province
            provinceCode
          }
        }
      }
    }
  }
`;

export const Attendees = (props) => {
  const location = useLocation();
  const queryParam = queryString.parse(location.search);
  const GAurl =
    queryParam["gclid"] !== undefined ? "?gclid=" + queryParam["gclid"] : "";

  const [isITloading, setisITloading] = useState(false);
  const [finalDate, setfinalDate] = useState();
  const [CurrentuserDetail, setCurrentuserDetail] = useState(undefined);
  const [IsThereAnyPastPrograme, setIsThereAnyPastPrograme] = useState(false);

  useEffect(() => {
    props.setAddresstoCheckout();
    localStorage.setItem("was_book", false);
  }, []);

  const [CartData, setCheckout] = useState(() => {
    const cart_item = localStorage.getItem("checkout_id");
    return cart_item !== null ? JSON.parse(cart_item) : undefined;
  });

  const [CardItem_sku, AdditemTOcart_sku] = useState(() => {
    const cart_item = localStorage.getItem("cart_session_sku");
    return cart_item !== null ? JSON.parse(cart_item) : { lineItems: [] };
  });

  /***
   * Associative  Customer to checkout
   */
  const [
    Associate_Customer_to_Checkout,
    {
      loading: Associate_Customer_to_Checkout_loading,
      data: Associate_Customer_to_Checkout_loading_data,
      error: Associate_Customer_to_Checkout_loading_error,
    },
  ] = useMutation(checkoutCustomerAssociate);



  /***
   * After assign user to Order
   * Need to assign address
   */
  const [UpdateloginUserDetail, setUpdateloginUserDetail] = useState(() => {
    const localdetail = localStorage.getItem("userDetail");
    return localdetail !== null ? JSON.parse(localdetail) : undefined;
  });

  const customerAccessToken =
    UpdateloginUserDetail != undefined
      ? UpdateloginUserDetail.accessToken
      : undefined;
  const {
    loading: getting_address,
    error: address_error,
    data: UserData,
  } = useQuery(GetuserAddress, {
    variables: { customerAccessToken },
  });

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  useEffect(() => {
    // console.log(props.useLoggedIn)
    if (UserData != undefined && props.useLoggedIn) {
      if (
        CartData != undefined &&
        props.customerAccessToken != undefined &&
        CartData != undefined
      ) {
        Associate_Customer_to_Checkout({
          variables: {
            checkoutId: CartData.checkout.id,
            customerAccessToken: props.customerAccessToken,
          },
        });

      }

      // console.log(UserData);

      if (
        CartData != undefined &&
        props.customerAccessToken != undefined &&
        UserData != undefined &&
        UserData.customer != null &&
        UserData.customer.addresses.edges.length
      ) {
        //  console.log('associate address');
        const used_address_detail = {
          address1: props.UserData.customer.addresses.edges[0].node.address1,
          city: props.UserData.customer.addresses.edges[0].node.city,
          country: props.UserData.customer.addresses.edges[0].node.country,
          firstName:
            props.UserData.customer.addresses.edges[0].node.firstName !== ""
              ? props.UserData.customer.addresses.edges[0].node.firstName
              : UserData.customer.firstName,
          lastName:
            props.UserData.customer.addresses.edges[0].node.lastName !== ""
              ? props.UserData.customer.addresses.edges[0].node.lastName
              : UserData.customer.lastName,
          phone:
            props.UserData.customer.addresses.edges[0].node.phone !== ""
              ? props.UserData.customer.addresses.edges[0].node.phone
              : UserData.customer.phone,
          province: props.UserData.customer.addresses.edges[0].node.province,
          zip: props.UserData.customer.addresses.edges[0].node.zip,
        };
      }
    }
  }, [UserData]);

  useEffect(() => {
    if (props.UserData != undefined) {
      //   console.log('UserData');
      // console.log(props.UserData);

      const save_data = {
        email: props.UserData.customer.email,
        first_name: props.UserData.customer.firstName,
        last_name: props.UserData.customer.lastName,
        mobile: props.UserData.customer.phone,
        company: props.UserData.customer.addresses.edges.length
          ? props.UserData.customer.addresses.edges[0].node.company
          : "",
      };
      setCurrentuserDetail(save_data);
    }
  }, [props.UserData]);

  let temp = [];
  if (CartData != undefined) {
    CartData.checkout.lineItems.edges.map((cartItem) => {
      let innertemp = [];
      [...Array(cartItem.node.quantity)].map((x, i) => {
        innertemp.push({
          booking_for_self: false,
          company: "",
          medicare_provider_no: "",
          email: "",
          email_error: false,
          salutation: "",
          first_name: "",
          first_name_error: false,
          last_name: "",
          last_name_error: false,
          mobile_number: "",
          mobile_number_error: false,
          landline: "",
          mobile_number_country_code: "+61",
          landline_country_code: "+61",
          mobile_number_without_country_code: "",
          landline_without_country_code: "",
          dietary: [],
          is_book: isBook(cartItem.node.variant.id),
        });
      });
      var test = cartItem.node.id;
      temp.push({ [test]: innertemp });
    });
  }
  const [AttendeesField, setAttendeesDetail] = useState(temp);

  const [ValidationError, setValidationError] = useState(false);
  const [PiravacySelected, setPiravacySelected] = useState(false);
  const [TermSelected, setTermSelected] = useState(false);
  const [PiravacySelected_error, setPiravacySelected_error] = useState(false);
  const [TermSelected_error, setTermSelected_error] = useState(false);
  const [ErrorMessageText, setErrorMessageText] = useState(undefined);

  const [Is_intensive_download, set_Is_intensive_download] = useState(false);

  const [tc_show, set_tc_show] = useState(false);
  const [pp_show, set_pp_show] = useState(false);

  const HideTCPopup = () => set_tc_show(false);
  const HidePPPopup = () => set_pp_show(false);

  const [formUpdated, setformUpdated] = useState(false);

  const [CheckoutDataHaveIssue, setCheckoutDataHaveIssue] = useState(false);

  const program_date_is_gone_or_not = (program_start_date_time) => {
    if (program_start_date_time === null || program_start_date_time === "") {
      return false;
    }
    program_start_date_time = new Date(program_start_date_time);
    let program_time_modify = program_start_date_time.setHours(
      program_start_date_time.getHours()
    );
    let current_time = new Date().getTime();
    if (parseFloat(program_time_modify) - parseFloat(current_time) > 0) {
      return false;
    } else {
      return true;
    }
  };

  /***
   * Something is not correct with data
   */

  useEffect(() => {
    if (CheckoutDataHaveIssue) {
      alert("Something went wrong. Please try again");
      localStorage.removeItem("checkout_id");
      localStorage.removeItem("cart_session");
      localStorage.removeItem("cart_session_sku");
      window.location.reload();
    }
  }, [CheckoutDataHaveIssue]);

  /***
   * Get sku code
   */
  function getSKU(variantID) {
    let psku = undefined;
    CardItem_sku.lineItems.map(function (linedata) {
      if (linedata.variantId == variantID) {
        psku = linedata.sku;
      }
    });
    if (psku === undefined) {
      if (!CheckoutDataHaveIssue) {
        setCheckoutDataHaveIssue(true);
      }
    }
    return psku;
  }

  /***
   * Get sku slug
   */
  function getHandle(variantID) {
    let psku = undefined;
    CardItem_sku.lineItems.map(function (linedata) {
      if (linedata.variantId == variantID) {
        psku = linedata.slug;
      }
    });
    return psku;
  }

  /**
   * Get stream type
   * @param {*} variantID
   * @returns stream type
   */
  function getStream(variantID) {
    let psku = "";
    CardItem_sku.lineItems.map(function (linedata) {
      if (linedata.variantId == variantID) {
        psku = linedata.stream_type;
      }
    });
    return psku;
  }

  const isNotEmpty = (value) => {
    return value !== null && value !== undefined && value !== "";
  };

  /***
   * Get product record type
   */
  function getRecordType(variantID) {
    let psku = undefined;
    CardItem_sku.lineItems.map(function (linedata) {
      if (linedata.variantId == variantID) {
        psku = linedata.record_type;
      }
    });
    return psku;
  }

  function IndividualDateisonOver(variantID) {
    let psku = false;
    CardItem_sku.lineItems.map(function (linedata) {
      if (linedata.variantId == variantID) {
        psku = program_date_is_gone_or_not(linedata.start_date);
      }
    });
    return psku;
  }

  /***
   * Get Tags
   */
  function getTags(variantID) {
    let psku = undefined;
    CardItem_sku.lineItems.map(function (linedata) {
      if (linedata.variantId == variantID) {
        psku = linedata.tags;
      }
    });
    return psku;
  }

  /**
   * Get program_structure
   */
  function getProgram_structure(variantID) {
    let program_structure = undefined;
    CardItem_sku.lineItems.map(function (linedata) {
      if (linedata.variantId === variantID) {
        program_structure = linedata.program_structure;
      }
    });
    return program_structure;
  }
  /***
   * Get Tags
   */
  function getSlug(variantID) {
    let psku = undefined;
    CardItem_sku.lineItems.map(function (linedata) {
      if (linedata.variantId == variantID) {
        psku = linedata.slug;
      }
    });
    return psku;
  }

  /***
   * Get product record type
   */
  function isBook(variantID) {
    let check_book = false;
    CardItem_sku.lineItems.map(function (linedata) {
      if (linedata.variantId == variantID) {
        let taglist = linedata.tags;
        if (taglist.includes("book")) {
          check_book = true;
        }
      }
    });
    return check_book;
  }

  /***
   * Get product record type
   */
  function getProgramStructureImage(variantID) {
    let psku = undefined;
    CardItem_sku.lineItems.map(function (linedata) {
      if (linedata.variantId == variantID) {
        psku = linedata.pimage[0];
      }
    });
    if (psku === undefined) {
      if (!CheckoutDataHaveIssue) {
        // console.log("pskupsku ->" + psku)
        setCheckoutDataHaveIssue(true);
      }
    }
    return psku;
  }

  /****
   * Get Checkout token
   */
  function getToken() {
    var weburl = CartData.checkout.webUrl;
    var n = weburl.lastIndexOf("/");
    var removekey = weburl.substring(n + 1);
    return removekey.split("?key=")[0];
  }

  /***
   * Check product list have any product that are releated to "internsive download"
   */
  useEffect(() => {
    if (CartData != undefined) {
      set_Is_intensive_download(false);
      let psku = undefined;
      var variant_ID_list = [];
      CartData.checkout.lineItems.edges.map((cartItem, i) => {
        variant_ID_list.push(cartItem.node.variant.id);
      });

      CardItem_sku.lineItems.map(function (linedata) {
        if (variant_ID_list.includes(linedata.variantId)) {
          let taglist = linedata.tags;
          if (taglist.includes("online-intensive-(to-download)")) {
            set_Is_intensive_download(true);
          }

          if (program_date_is_gone_or_not(linedata.start_date)) {
            setIsThereAnyPastPrograme(true);
          }
        }
      });
    }
  }, [CartData]);

  const SubmitAttendees = (e) => {
    // console.log(AttendeesField);

    e.preventDefault();
    setformUpdated(true);
    localStorage.setItem("checkout_token", getToken());
    var finalDate = {
      checkout_token: getToken(),
      checkout: {
        id: CartData.checkout.id,
      },
      lineItems: {
        edges: [],
      },
    };

    var go_ahead = true;
    setTermSelected_error(false);
    setPiravacySelected_error(false);
    setValidationError(false);
    setErrorMessageText(undefined);

    CartData.checkout.lineItems.edges.map((cartItem, i) => {
      AttendeesField[i][cartItem.node.id].map((dt) => {
        dt.email_error = false;
        dt.first_name_error = false;
        dt.last_name_error = false;
        dt.mobile_number_error = false;
        if (dt.is_book === false) {
          if (dt.email == "") {
            go_ahead = false;
            dt.email_error = true;
          }
          if (dt.email !== "" && !validateEmail(dt.email)) {
            go_ahead = false;
            dt.email_error = true;
          }
          if (dt.first_name == "") {
            go_ahead = false;
            dt.first_name_error = true;
          }
          if (dt.last_name == "") {
            go_ahead = false;
            dt.last_name_error = true;
          }
          if (dt.mobile_number == "") {
            go_ahead = false;
            dt.mobile_number_error = true;
          }
        } else {
          localStorage.setItem("was_book", true);
        }
      });
    });
    //console.log(AttendeesField);
    setformUpdated(false);

    if (!TermSelected) {
      go_ahead = false;
      setTermSelected_error(true);
    }

    if (!PiravacySelected) {
      go_ahead = false;
      setPiravacySelected_error(true);
    }

    if (go_ahead) {
      CartData.checkout.lineItems.edges.map((cartItem, i) => {
        var temp = {
          id: cartItem.node.id,
          quantity: cartItem.node.quantity,
          title: cartItem.node.title,
          sku: getSKU(cartItem.node.variant.id),
          handle: getHandle(cartItem.node.variant.id),
          stream_type: getStream(cartItem.node.variant.id),
          is_live_stream:
            getStream(cartItem.node.variant.id).toLowerCase() == "live stream"
              ? true
              : false,
          is_face_to_face:
            getStream(cartItem.node.variant.id).toLowerCase() == "face to face"
              ? true
              : false,
          is_book: isBook(cartItem.node.variant.id),
          attendees: AttendeesField[i][cartItem.node.id],
        };
        finalDate.lineItems.edges.push(temp);
      });

      var thank_you_paeg_program_structure = [];
      CartData.checkout.lineItems.edges.map((cartItem, i) => {
        var temp = {
          title: cartItem.node.title,
          tag: getTags(cartItem.node.variant.id),
          slug: getSlug(cartItem.node.variant.id),
        };
        thank_you_paeg_program_structure.push(temp);
      });

      localStorage.setItem(
        "thank_you_image",
        JSON.stringify(thank_you_paeg_program_structure)
      );

      setfinalDate(finalDate);
      console.log(finalDate);
      SenddataTOsalesforce(finalDate);
    } else {
      setValidationError(true);
      setErrorMessageText("Fields are required!");
      //alert("Email field is required");
    }
  };

  function SenddataTOsalesforce(finalDate) {
    window.scrollTo(0, 0);

    setisITloading(true);
    axios
      .post(props.EngineURL + "chk/post-order-data-new.php", finalDate)
      .then(function (response) {
        // console.log(response);
        if (response.status === 200) {
          if (response.data.length !== 0) {
            if (response.data.status !== "failed") {
              setTimeout(function () {
                window.location.href = CartData.checkout.webUrl.replace(
                  "https://demo-leo.myshopify.com/",
                  "https://checkout.leocussen.edu.au/"
                );
              }, 1500);
            } else {
              alert(response.data.message);
              window.location.reload();
              setisITloading(false);
            }
          }
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  //  console.log(CurrentuserDetail);

  const handleInputChange = (node_id, index, event, mainindex, variantID) => {
    const newarray = AttendeesField;
    var field_id = mainindex + node_id + index;
    if (
      event.target.name === "my_self" &&
      event.target.value == "true" &&
      event.target.checked
    ) {
      newarray[mainindex][node_id][index].booking_for_self = event.target.value;

      if (CurrentuserDetail != undefined) {
        newarray[mainindex][node_id][index].email = CurrentuserDetail.email;
        document.getElementById(field_id + "email").value =
          CurrentuserDetail.email;

        newarray[mainindex][node_id][index].first_name =
          CurrentuserDetail.first_name;
        document.getElementById(field_id + "first_name").value =
          CurrentuserDetail.first_name;

        newarray[mainindex][node_id][index].last_name =
          CurrentuserDetail.last_name;
        document.getElementById(field_id + "last_name").value =
          CurrentuserDetail.last_name;

        newarray[mainindex][node_id][index].mobile_number =
          CurrentuserDetail.mobile;

        newarray[mainindex][node_id][index].mobile_number_without_country_code =
          CurrentuserDetail.mobile.substring(3);
        document.getElementById(
          field_id + "mobile_number_without_country_code"
        ).value = CurrentuserDetail.mobile.substring(3);

        newarray[mainindex][node_id][index].mobile_number_country_code =
          CurrentuserDetail.mobile.substring(0, 3);
        document.getElementById(field_id + "mobile_number_country_code").value =
          CurrentuserDetail.mobile.substring(0, 3);

        newarray[mainindex][node_id][index].company = CurrentuserDetail.company;
        document.getElementById(field_id + "company").value =
          CurrentuserDetail.company;
      }

      //   console.log(CurrentuserDetail);
    } else {
      if (event.target.name === "my_self") {
        newarray[mainindex][node_id][index].booking_for_self =
          event.target.value;

        newarray[mainindex][node_id][index].email = null;
        document.getElementById(field_id + "email").value = null;

        newarray[mainindex][node_id][index].first_name = null;
        document.getElementById(field_id + "first_name").value = null;

        newarray[mainindex][node_id][index].last_name = null;
        document.getElementById(field_id + "last_name").value = null;

        newarray[mainindex][node_id][index].company = null;
        document.getElementById(field_id + "company").value = null;

        newarray[mainindex][node_id][index].mobile_number_without_country_code =
          null;
        newarray[mainindex][node_id][index].mobile_number = null;
        newarray[mainindex][node_id][index].mobile_number_country_code = null;
        document.getElementById(
          field_id + "mobile_number_without_country_code"
        ).value = null;
        document.getElementById(field_id + "mobile_number_country_code").value =
          null;
      } else if (event.target.name === "email") {
        newarray[mainindex][node_id][index].email = event.target.value;
      } else if (event.target.name === "company") {
        newarray[mainindex][node_id][index].company = event.target.value;
      } else if (event.target.name === "medicare_provider_no") {
        newarray[mainindex][node_id][index].medicare_provider_no =
          event.target.value;
      } else if (event.target.name === "salutation") {
        newarray[mainindex][node_id][index].salutation = event.target.value;
      } else if (event.target.name === "first_name") {
        newarray[mainindex][node_id][index].first_name = event.target.value;
      } else if (event.target.name === "last_name") {
        newarray[mainindex][node_id][index].last_name = event.target.value;
      } else if (event.target.name === "mobile_number_without_country_code") {
        newarray[mainindex][node_id][index].mobile_number_without_country_code =
          event.target.value;
        newarray[mainindex][node_id][index].mobile_number =
          document.getElementById(field_id + "mobile_number_country_code")
            .value +
          "" +
          document.getElementById(
            field_id + "mobile_number_without_country_code"
          ).value;
      } else if (event.target.name === "mobile_number_country_code") {
        newarray[mainindex][node_id][index].mobile_number_country_code =
          event.target.value;
        newarray[mainindex][node_id][index].mobile_number =
          document.getElementById(field_id + "mobile_number_country_code")
            .value +
          "" +
          document.getElementById(
            field_id + "mobile_number_without_country_code"
          ).value;
      } else if (event.target.name === "landline_country_code") {
        newarray[mainindex][node_id][index].landline_country_code =
          event.target.value;
        newarray[mainindex][node_id][index].landline =
          document.getElementById(field_id + "landline_country_code").value +
          "" +
          document.getElementById(field_id + "landline_without_country_code")
            .value;
      } else if (event.target.name === "landline_without_country_code") {
        newarray[mainindex][node_id][index].landline_without_country_code =
          event.target.value;
        newarray[mainindex][node_id][index].landline =
          document.getElementById(field_id + "landline_country_code").value +
          "" +
          document.getElementById(field_id + "landline_without_country_code")
            .value;
      } else if (event.target.name === "dietary") {
        newarray[mainindex][node_id][index].dietary.push(event.target.value);
      }
    }

    setAttendeesDetail(newarray); //console.log(AttendeesField);
  };

  const numberWithCommas = (x) => {
    return parseFloat(x)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  if (isITloading) {
    return (
      <div className="pre_loader">
        <LoaderIcon />
      </div>
    );
  }
  if (CartData != undefined) {
    return (
      <div className="attendees_page" data-test={formUpdated}>
        <Helmet>
          <title>Attendees | Leo Cussen Centre for Law</title>
          <meta name="description" content="" />
        </Helmet>
        <Container>
          <Row>
            <Col md={8} xs={12}>
              <div className="attendees_content">
                <h1 className="mb-0">Delivery Option</h1>
                <h5 className="display-5 text-secondary">Event Attendance</h5>
                {props.useLoggedIn ? (
                  ""
                ) : (
                  <div
                    className="information_block card border-exlight attendy-paragraph"
                    key="tt"
                  >
                    <div className="information_content ">
                      <InformationIcon />
                      {Is_intensive_download ? (
                        <p className="small small-dec mb-0">
                          Login is required, click
                          <a onClick={(e) => props.ShowLoginPopup()}> here</a>{" "}
                          to login to your account
                        </p>
                      ) : (
                        <p className="small small-dec mb-0">
                          If you are already registered with Leo Cussen, click
                          <a onClick={(e) => props.ShowLoginPopup()}> here</a>{" "}
                          to login to your account
                        </p>
                      )}
                    </div>
                  </div>
                )}

                <div className="cpd_attendees">
                  {CartData.checkout.lineItems.edges.map(
                    (cartItem, mainindex) => {
                      const tst = mainindex + 200;
                      // console.log(cartItem);
                      return (
                        <Accordion
                          defaultActiveKey={tst}
                          key={"lol" + mainindex}
                        >
                          <Accordion.Item
                            eventKey={tst}
                            key={tst}
                            className="shadow bg-white rounded-2 border-0 attendees_card"
                          >
                            <Card
                              className={
                                getRecordType(cartItem.node.variant.id) ==
                                "Book"
                                  ? "no-need-atendee border-0"
                                  : "border-0"
                              }
                            >
                              <AccordionButton
                                id={"toogle" + tst}
                              ></AccordionButton>{" "}
                              <Card.Body>
                                <div className="program_cate2 d-none d-md-flex justify-content-center flex-column">
                                  <img
                                    src={getProgramStructureImage(
                                      cartItem.node.variant.id
                                    )}
                                    alt={cartItem.node.title}
                                  />
                                </div>
                                <Card.Title className="d-flex flex-wrap align-items-center">
                                  <h3
                                    onClick={() => {
                                      document
                                        .getElementById(`toogle${tst}`)
                                        .click();
                                    }}
                                  >
                                    {cartItem.node.title}
                                  </h3>
                                  <div className="attendees_details_right d-flex align-items-center justify-content-end">
                                    <h3 className="attendees_total">
                                      {cartItem.node.quantity}
                                    </h3>
                                    <h3 className="attendees_amount">
                                      $
                                      {numberWithCommas(
                                        cartItem.node.variant.price.amount *
                                          cartItem.node.quantity
                                      )}
                                    </h3>
                                  </div>
                                  {IndividualDateisonOver(
                                    cartItem.node.variant.id
                                  ) ? (
                                    <Alert variant="danger">
                                      This Program has been over. Kindly please
                                      remove it from cart
                                    </Alert>
                                  ) : null}
                                </Card.Title>
                                <Accordion.Body className="p-0">
                                  {[...Array(cartItem.node.quantity)].map(
                                    (x, i) => {
                                      const tetst_index = i;
                                      const total_attnds = i + 1;
                                      const node_id = cartItem.node.id;
                                      return (
                                        <Card.Text
                                          key={i}
                                          as="div"
                                          className={
                                            getRecordType(
                                              cartItem.node.variant.id
                                            ) == "Book"
                                              ? "d-none attendees_details_block"
                                              : "attendees_details_block"
                                          }
                                        >
                                          <h6 className="display-6">
                                            Attendee Details {total_attnds}
                                          </h6>
                                          <Form>
                                            <div className="attendees_details_users d-flex flex-wrap align-items-center">
                                              <UserIcon />
                                              <small>{total_attnds}</small>
                                              <Form.Group
                                                className={
                                                  total_attnds == 1
                                                    ? "my_self_holder"
                                                    : "d-none"
                                                }
                                                controlId={
                                                  tst + "CheckboxBooking"
                                                }
                                              >
                                                <Form.Check
                                                  name="my_self"
                                                  type="checkbox"
                                                  value="true"
                                                  label="I am booking for myself."
                                                  htmlFor={
                                                    tst + "CheckboxBooking"
                                                  }
                                                  onChange={(event) =>
                                                    handleInputChange(
                                                      node_id,
                                                      tetst_index,
                                                      event,
                                                      mainindex,
                                                      cartItem.node.variant.id
                                                    )
                                                  }
                                                  required
                                                />
                                              </Form.Group>
                                              {props.useLoggedIn ? (
                                                ""
                                              ) : (
                                                <>
                                                  <p className="address small mygrey-text mb-0">
                                                    Click
                                                    <a
                                                      onClick={(e) =>
                                                        props.ShowLoginPopup()
                                                      }
                                                    >
                                                      {" "}
                                                      here
                                                    </a>{" "}
                                                    to login to your account.
                                                  </p>
                                                </>
                                              )}
                                            </div>
                                            <div className="attendees_form_details">
                                              <Form.Group
                                                className="form-group"
                                                data-mainindex={formUpdated}
                                              >
                                                <Form.Control
                                                  type="email"
                                                  className={
                                                    AttendeesField[mainindex][
                                                      node_id
                                                    ][tetst_index]
                                                      .email_error !=
                                                      undefined &&
                                                    AttendeesField[mainindex][
                                                      node_id
                                                    ][tetst_index]
                                                      .email_error === true
                                                      ? "form-error"
                                                      : ""
                                                  }
                                                  name="email"
                                                  id={
                                                    mainindex +
                                                    node_id +
                                                    tetst_index +
                                                    "email"
                                                  }
                                                  placeholder="Attendee Email*"
                                                  onChange={(event) => {
                                                    handleInputChange(
                                                      node_id,
                                                      tetst_index,
                                                      event,
                                                      mainindex,
                                                      cartItem.node.variant.id
                                                    );
                                                    setformUpdated("dfsd");
                                                  }}
                                                />
                                              </Form.Group>
                                              <Form.Group className="form-group">
                                                <Form.Select
                                                  name="salutation"
                                                  className="form-control"
                                                  onChange={(event) =>
                                                    handleInputChange(
                                                      node_id,
                                                      tetst_index,
                                                      event,
                                                      mainindex,
                                                      cartItem.node.variant.id
                                                    )
                                                  }
                                                >
                                                  <option value="">
                                                    Salutation
                                                  </option>
                                                  <option value="Mr.">
                                                    Mr.
                                                  </option>
                                                  <option value="Mrs.">
                                                    Mrs.
                                                  </option>
                                                  <option value="Ms.">
                                                    Ms.
                                                  </option>
                                                  <option value="Miss">
                                                    Miss
                                                  </option>
                                                  <option value="Dr.">
                                                    Dr.
                                                  </option>
                                                  <option value="Prof.">
                                                    Prof.
                                                  </option>
                                                  <option value="Other">
                                                    Other
                                                  </option>
                                                </Form.Select>
                                              </Form.Group>
                                              <Form.Group className="form-group">
                                                <Form.Control
                                                  className={
                                                    AttendeesField[mainindex][
                                                      node_id
                                                    ][tetst_index]
                                                      .first_name_error
                                                      ? "form-group form-error"
                                                      : "form-group"
                                                  }
                                                  type="text"
                                                  id={
                                                    mainindex +
                                                    node_id +
                                                    tetst_index +
                                                    "first_name"
                                                  }
                                                  name="first_name"
                                                  placeholder="First Name*"
                                                  onChange={(event) => {
                                                    handleInputChange(
                                                      node_id,
                                                      tetst_index,
                                                      event,
                                                      mainindex,
                                                      cartItem.node.variant.id
                                                    );
                                                    setformUpdated("dfsdd");
                                                  }}
                                                />
                                              </Form.Group>
                                              <Form.Group className="form-group">
                                                <Form.Control
                                                  type="text"
                                                  className={
                                                    AttendeesField[mainindex][
                                                      node_id
                                                    ][tetst_index]
                                                      .last_name_error
                                                      ? "form-group form-error"
                                                      : "form-group"
                                                  }
                                                  id={
                                                    mainindex +
                                                    node_id +
                                                    tetst_index +
                                                    "last_name"
                                                  }
                                                  placeholder="Last Name*"
                                                  name="last_name"
                                                  onChange={(event) => {
                                                    handleInputChange(
                                                      node_id,
                                                      tetst_index,
                                                      event,
                                                      mainindex,
                                                      cartItem.node.variant.id
                                                    );
                                                    setformUpdated("dfsd");
                                                  }}
                                                />
                                              </Form.Group>
                                              <Form.Group className="form-group">
                                                <Form.Control
                                                  type="text"
                                                  placeholder="Company"
                                                  name="company"
                                                  id={
                                                    mainindex +
                                                    node_id +
                                                    tetst_index +
                                                    "company"
                                                  }
                                                  onChange={(event) =>
                                                    handleInputChange(
                                                      node_id,
                                                      tetst_index,
                                                      event,
                                                      mainindex,
                                                      cartItem.node.variant.id
                                                    )
                                                  }
                                                />
                                              </Form.Group>
                                              <Form.Group
                                                className={
                                                  getProgram_structure(
                                                    cartItem.node.variant.id
                                                  ) ===
                                                  "Medicare Billing Course"
                                                    ? "form-group"
                                                    : "form-group  d-none"
                                                }
                                              >
                                                <Form.Control
                                                  type="text"
                                                  placeholder="Medicare Provider No"
                                                  name="medicare_provider_no"
                                                  id={
                                                    mainindex +
                                                    node_id +
                                                    tetst_index +
                                                    "medicare_provider_no"
                                                  }
                                                  maxLength="8"
                                                  pattern="[A-Za-z0-9]{1,8}"
                                                  onChange={(event) =>
                                                    handleInputChange(
                                                      node_id,
                                                      tetst_index,
                                                      event,
                                                      mainindex,
                                                      cartItem.node.variant.id
                                                    )
                                                  }
                                                />
                                              </Form.Group>
                                              <Form.Group
                                                as={Row}
                                                className="form-group gx-2"
                                              >
                                                <Col xs={3} sm={2}>
                                                  <Form.Control
                                                    required
                                                    id={
                                                      mainindex +
                                                      node_id +
                                                      tetst_index +
                                                      "mobile_number_country_code"
                                                    }
                                                    name="mobile_number_country_code"
                                                    onChange={(event) =>
                                                      handleInputChange(
                                                        node_id,
                                                        tetst_index,
                                                        event,
                                                        mainindex,
                                                        cartItem.node.variant.id
                                                      )
                                                    }
                                                    type="text"
                                                    placeholder="+61"
                                                    defaultValue="+61"
                                                  />
                                                </Col>
                                                <Col xs={9} sm={10}>
                                                  <Form.Control
                                                    required
                                                    type="text"
                                                    className={
                                                      AttendeesField[mainindex][
                                                        node_id
                                                      ][tetst_index]
                                                        .mobile_number_error
                                                        ? " form-error"
                                                        : ""
                                                    }
                                                    name="mobile_number_without_country_code"
                                                    id={
                                                      mainindex +
                                                      node_id +
                                                      tetst_index +
                                                      "mobile_number_without_country_code"
                                                    }
                                                    placeholder="Mobile*"
                                                    onChange={(event) => {
                                                      handleInputChange(
                                                        node_id,
                                                        tetst_index,
                                                        event,
                                                        mainindex,
                                                        cartItem.node.variant.id
                                                      );
                                                      setformUpdated("dfsdss");
                                                    }}
                                                  />
                                                </Col>
                                              </Form.Group>
                                              <Form.Group
                                                as={Row}
                                                className="form-group gx-2"
                                              >
                                                <Col xs={3} sm={2}>
                                                  <Form.Control
                                                    required
                                                    id={
                                                      mainindex +
                                                      node_id +
                                                      tetst_index +
                                                      "landline_country_code"
                                                    }
                                                    type="text"
                                                    name="landline_country_code"
                                                    placeholder="+61"
                                                    onChange={(event) =>
                                                      handleInputChange(
                                                        node_id,
                                                        tetst_index,
                                                        event,
                                                        mainindex,
                                                        cartItem.node.variant.id
                                                      )
                                                    }
                                                    defaultValue="+61"
                                                  />
                                                </Col>
                                                <Col xs={9} sm={10}>
                                                  <Form.Control
                                                    type="text"
                                                    placeholder="Phone"
                                                    id={
                                                      mainindex +
                                                      node_id +
                                                      tetst_index +
                                                      "landline_without_country_code"
                                                    }
                                                    name="landline_without_country_code"
                                                    onChange={(event) =>
                                                      handleInputChange(
                                                        node_id,
                                                        tetst_index,
                                                        event,
                                                        mainindex,
                                                        cartItem.node.variant.id
                                                      )
                                                    }
                                                  />
                                                </Col>
                                              </Form.Group>

                                              <Form.Group
                                                controlId="CheckboxFaceToFace"
                                                className={
                                                  getStream(
                                                    cartItem.node.variant.id
                                                  ).toLowerCase() ===
                                                  "Face to Face"
                                                    ? "form-group d-flex flex-wrap align-items-center dietary_req"
                                                    : "d-none  form-group d-flex flex-wrap align-items-center dietary_req"
                                                }
                                              >
                                                <Form.Select
                                                  className="form-control"
                                                  name="dietary"
                                                  onChange={(event) =>
                                                    handleInputChange(
                                                      node_id,
                                                      tetst_index,
                                                      event,
                                                      mainindex,
                                                      cartItem.node.variant.id
                                                    )
                                                  }
                                                >
                                                  <option value="">
                                                    Dietary requirement
                                                  </option>
                                                  <option value="VGT">
                                                    Vegetarian
                                                  </option>
                                                  <option value="VGN">
                                                    Vegan
                                                  </option>
                                                  <option value="GF">
                                                    Gluten Free
                                                  </option>
                                                </Form.Select>
                                              </Form.Group>
                                            </div>
                                          </Form>
                                        </Card.Text>
                                      );
                                    }
                                  )}
                                </Accordion.Body>
                              </Card.Body>
                            </Card>
                          </Accordion.Item>
                        </Accordion>
                      );
                    }
                  )}

                  {CartData.checkout.lineItems.edges.length ? (
                    <>
                      <div className="form-check d-flex align-items-center">
                        <input
                          type="checkbox"
                          id="check_term"
                          className={
                            TermSelected_error
                              ? "form-check-input form-error"
                              : "form-check-input"
                          }
                          onChange={(e) => setTermSelected(!TermSelected)}
                        />
                        <label
                          title=""
                          htmlFor="check_term"
                          className="form-check-label"
                        >
                          I agree to the{" "}
                          <a onClick={() => set_tc_show(true)}>
                            Terms & Conditions
                          </a>
                        </label>
                      </div>
                      <div className="form-check d-flex align-items-center">
                        <input
                          type="checkbox"
                          id="check_privacy"
                          className={
                            PiravacySelected_error
                              ? "form-check-input form-error"
                              : "form-check-input"
                          }
                          onChange={(e) =>
                            setPiravacySelected(!PiravacySelected)
                          }
                        />
                        <label
                          title=""
                          htmlFor="check_privacy"
                          className="form-check-label"
                        >
                          I agree to the{" "}
                          <a onClick={() => set_pp_show(true)}>
                            Privacy Policy
                          </a>
                        </label>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {ErrorMessageText !== undefined ? (
                    <Alert variant="danger">{ErrorMessageText}</Alert>
                  ) : (
                    ""
                  )}

                  {CartData.checkout.lineItems.edges.length ? (
                    <div className="attendees_cta text-center d-flex flex-wrap justify-content-center">
                      {Is_intensive_download && !props.useLoggedIn ? (
                        <div className="information_block card border-exlight attendy-paragraph">
                          <div className="information_content ">
                            <InformationIcon />
                            <p className="small small-dec mb-0">
                              Login is required, click
                              <a onClick={(e) => props.ShowLoginPopup()}>
                                {" "}
                                here
                              </a>{" "}
                              to login to your account
                            </p>
                          </div>
                        </div>
                      ) : IsThereAnyPastPrograme ? null : (
                        <Button
                          type="submit"
                          className="btn-lg border-2 rounded-pill"
                          onClick={SubmitAttendees}
                        >
                          Checkout
                        </Button>
                      )}
                      <Link to={"/" + GAurl} className="btn-link btn-lg">
                        Continue Shopping
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Col>
            <Col md={4} xs={12}>
              <div className="attendees_order_right_sidebar">
                {CartData.checkout.lineItems.edges.length ? (
                  <>
                    <h3>Order</h3>
                    <div className="attendees_order_listing">
                      {CartData.checkout.lineItems.edges.map(
                        (cartItem, mainindex) => {
                          const priceBYqyt =
                            cartItem.node.variant.price.amount *
                            cartItem.node.quantity;

                          return (
                            <div
                              key={"de" + mainindex}
                              className="attendees_order_items"
                            >
                              <h6 className="display-6">
                                {cartItem.node.title}
                              </h6>
                              {isNotEmpty(
                                getStream(cartItem.node.variant.id)
                              ) ? (
                                <small>
                                  (Attendance:{" "}
                                  {getStream(cartItem.node.variant.id)})
                                </small>
                              ) : null}
                              <h6 className="display-6 attendees_order_price">
                                ${numberWithCommas(priceBYqyt)}
                              </h6>
                            </div>
                          );
                        }
                      )}
                    </div>

                    <div className="attendees_order_item_total d-flex flex-wrap align-items-center justify-content-between">
                      <h6 className="display-6 text-secondary">Item Total</h6>
                      <h6 className="display-6 text-secondary">
                        $
                        {numberWithCommas(
                          CartData.checkout.subtotalPrice.amount
                        )}
                      </h6>
                    </div>

                    <div className="attendees_order_total_gst d-flex flex-wrap align-items-center justify-content-between">
                      <h3 className="text-white mb-0">
                        Total
                        <small className="display-6 text-white mb-0">
                          (Including GST)
                        </small>
                      </h3>
                      <h3 className="text-white mb-0">
                        ${numberWithCommas(CartData.checkout.totalPrice.amount)}
                      </h3>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>

              <div className="need_help_block">
                <h6 className="display-6">
                  Need Help ?{" "}
                  <a
                    href="mailto:registration@leocussen.edu.au"
                    className="btn-link"
                  >
                    Get in touch
                  </a>
                  .
                </h6>
                <div className=" d-flex flex-wrap align-items-center justify-content-between">
                  <h3>
                    <CallPhoneIcon />{" "}
                    <a href="tel:1300 039 031">1300 039 031</a>
                  </h3>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <TermsCondition show={tc_show} hide={HideTCPopup} />
        <PrivacyPolicy show={pp_show} hide={HidePPPopup} />
      </div>
    );
  }
  return (
    <div className="attendees_page">
      <Container>
        <h3>Please visit Course page to purchase course</h3>
      </Container>
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';


import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css'
import "react-big-calendar/lib/css/react-big-calendar.css";

import './App.css';

import { Header } from './components/Header/Header';
import { Footer } from './components/Footer/Footer';


import { ProductPage } from './components/ProductListing/ProductPage';
import { HomePage } from './components/DefaultPage/HomePage';

import { Attendees } from './components/CheckoutProcess/Attendees';

import { Thankyou } from './components/Thank-you/Thankyou';

import { SIngleProduct } from './components/SingleProduct/SIngleProduct';

import { AdminMyEvents } from './components/Admin/AdminMyEvents';
import { AdminOrders } from './components/Admin/AdminOrders';
import { AdminCPDPoints } from './components/Admin/AdminCPDPoints';
import { AdminMyLibrary } from './components/Admin/AdminMyLibrary';
import { AdminProfile } from './components/Admin/AdminProfile';
import { AdminWishlist } from './components/Admin/AdminWishlist';
import { AdminRefer } from './components/Admin/AdminRefer';

import { Login } from './components/Modals/Login';
import { CreateAccount } from './components/Modals/CreateAccount';

import { CartSidebar } from './components/Modals/CartSidebar';

import { AboutPage } from './components/Modals/AboutUs';

import { Cart } from './components/Cart/Cart'

import { ForgotPassword } from './components/Modals/ForgotPassword';
import { ForgotUsername } from './components/Modals/ForgotUsername';

import ScrollToTop from './components/ScrollToTop';

import { ActivateAccount } from './components/activateAccount/ActivateAccount';
import { ResetPassword } from './components/resetPassword/ResetPassword';

import CookieConsent from "react-cookie-consent";

import { Search } from './components/Search/Search';
import { ActivateAccountGuest } from './components/activateAccount/ActivateAccountGuest';
import { SignUp } from './components/Modals/SignUp';
import { NotFound } from './components/DefaultPage/notFound';
import { StatePopup } from './components/Modals/StateSelection';
import { CanWeHelp } from './components/Modals/CanWeHelp';
import { CookiePref } from './components/Modals/CookiePref';

const AddnewAddressQuery = gql`
  mutation customerAddressCreate($customerAccessToken: String!, $address: MailingAddressInput!) {
    customerAddressCreate(
      customerAccessToken: $customerAccessToken
      address: $address
    ) {
      customerAddress {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
 `;

const customerAccessTokenCreate = gql`
  mutation customerAccessTokenCreate($userEmailPass: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $userEmailPass) {
      customerUserErrors {
        field
        message
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
    }
  }
`;
const CheckoutFragment = gql`
  fragment CheckoutFragment on Checkout {
    id
    webUrl
    subtotalPrice{
      amount
      currencyCode
    }
    totalPrice{
      amount
      currencyCode
    }
    lineItems (first: 250) {
      edges {
        node {
          id
          title
          variant {
            id
            title
            price{
              amount
              currencyCode
            }
          }
          quantity
        }
      }
    }
  }
`;

export const createCheckout = gql`
  mutation checkoutCreate ($CardItem: CheckoutCreateInput!){
    checkoutCreate(input: $CardItem) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export const checkoutLineItemsAdd = gql`
  mutation checkoutLineItemsAdd ($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!) {
    checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export const checkoutLineItemsUpdate = gql`
  mutation checkoutLineItemsUpdate ($checkoutId: ID!, $lineItems: [CheckoutLineItemUpdateInput!]!) {
    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export const checkoutLineItemsRemove = gql`
  mutation checkoutLineItemsRemove ($checkoutId: ID!, $lineItemIds: [ID!]!) {
    checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;





const GetuserAddress = gql`
query($customerAccessToken:String!){
    customer(customerAccessToken:$customerAccessToken){
    firstName
    lastName
    email
    phone
      addresses(first:10)
      {
        edges
        {
          node{
            address1
            address2
            city
            company
            zip
            country
            countryCodeV2
            firstName
            lastName
            phone
            id
            province
            provinceCode
          }
        }
      }
    }
  }
  `;



function App() {

  /***
   * Global Variables
   */
  const store_env = "live";
  const EngineURL = (store_env === "staging") ? 'https://leo-engine.sfstaging.com.au/' : 'https://engine.leocussen.edu.au/';
  const AdminPanelURL = (store_env === "staging") ? 'https://leoadmin.saltandfuessel.com.au/' : 'https://storeadmin.leocussen.edu.au/';
  //const storeURL = (store_env === "staging") ? 'https://leocussen-store.sfstaging./com.au' : 'https://store.leocussen.edu.au/';
  const storeURL = (store_env === "staging") ? 'https://leocussen-store.sfstaging.com.au/' : 'https://store.leocussen.edu.au/';





  /***
* Producgram structure images
*/
  const Program_structure_images = [
    { 'cpd-seminar': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Seminar.jpg' },
    { 'cpd-seminar-(1-hour)': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Seminar.jpg' },
    { 'cpd-seminar-(2-hour)': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Seminar.jpg' },
    { 'cpd-online-module': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Online%20Module.jpg' },
    { 'cpd-half-day-conference': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Conference.jpg' },
    { 'online-intensive-(to-download)': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/Webcast%20Package.jpg' },
    { 'cpd-workshop---essential': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Workshop.jpg' },
    { 'cpd-intensive': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Intensive.jpg' },
    { 'cpd-intensive-(3-point)': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Intensive.jpg' },
    { 'cpd-intensive-(4-point)': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Intensive.jpg' },
    { 'mental-health-first-aid-course': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/Mental%20Health%20First%20Aid.jpg' },
    { 'online-intensive-(to-download)': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/Webcast%20Package.jpg' },
    { 'book': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/Book.jpg' },
    { 'corporate-division': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/Corporate%20Training%20Division.jpg' },
    { 'practice-management-course': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/Practice%20Management%20Course.jpg' },
    { 'traineeship-(swt)-workshop': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/SLT%20Supervised%20Legal%20Training.jpg' },
    { 'traineeship-(slt)-1-day-workshop': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/SLT%20Supervised%20Legal%20Training.jpg' },
    { 'traineeship-(slt)-1.5-day-workshop': '  https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/SLT%20Supervised%20Legal%20Training.jpg' },
    { 'traineeship-(slt)-2-day-workshop': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/SLT%20Supervised%20Legal%20Training.jpg' },
    { 'traineeship-(slt)-3-day-workshop': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/SLT%20Supervised%20Legal%20Training.jpg' },
    { 'traineeship-(slt)-5-day-workshop': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/SLT%20Supervised%20Legal%20Training.jpg' },
    { 'traineeship-(slt)-half-day-workshop': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/SLT%20Supervised%20Legal%20Training.jpg' },
    { 'cpd-conference': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Conference.jpg' },
    { 'cpd-full-day-conference': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Conference.jpg' },
    { 'cpd-mini-conference': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Conference.jpg' },
    { 'cpd-workshop---advocacy': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Workshop.jpg' },
    { 'cpd-workshop---platinum': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Workshop.jpg' },
    { 'legal-support-staff': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/Legal%20Support%20Staff.jpg' },
    { 'medicare-billing-course': 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/Medicare%20Billing%20Course.jpg'}
  ];
  /***
* Producgram structure images
*/
  const Program_structure_color = [
    { 'cpd-seminar': '#70c0c7' },
    { 'cpd-seminar-(1-hour)': '#70c0c7' },
    { 'cpd-seminar-(2-hour)': '#70c0c7' },
    { 'cpd-online-module': '#84be9c' },
    { 'cpd-half-day-conference': '#987C88' },
    { 'online-intensive-(to-download)': '#8cafbd' },
    { 'cpd-workshop---essential': '#fec010' },
    { 'cpd-intensive': '#bc318c' },
    { 'cpd-intensive-(3-point)': '#bc318c' },
    { 'cpd-intensive-(4-point)': '#bc318c' },
    { 'mental-health-first-aid-course': '#bc318c' },
    { 'online-intensive-(to-download)': '#8cafbd' },
    { 'book': '#bc318c' },
    { 'corporate-division': '#84be9c' },
    { 'practice-management-course': '#987C88' },
    { 'traineeship-(swt)-workshop': '#01293a' },
    { 'traineeship-(slt)-1-day-workshop': '#01293a' },
    { 'traineeship-(slt)-1.5-day-workshop': '#01293a' },
    { 'traineeship-(slt)-2-day-workshop': '#01293a' },
    { 'traineeship-(slt)-3-day-workshop': '#01293a' },
    { 'traineeship-(slt)-5-day-workshop': '#01293a' },
    { 'traineeship-(slt)-half-day-workshop': '#01293a' },
    { 'cpd-conference': '#01293a' },
    { 'cpd-full-day-conference': '#01293a' },
    { 'cpd-mini-conference': '#01293a' },
    { 'cpd-workshop---advocacy': '#fec010g' },
    { 'cpd-workshop---platinum': '#fec010g' },
    { 'legal-support-staff': '#bc318c' }
  ];





  if (window.location.pathname === "/thank-you") {
    localStorage.removeItem("checkout_id");
    localStorage.removeItem("cart_session");
    localStorage.removeItem("cart_session_sku");
  }




  const [Cartqty, setCartqty] = useState(0);
  const [CartAmount, setCartAmount] = useState(0.00);

  const UpdateCartQty = (val) => {
    setCartqty(val);
  }

  const UpdateCartAmount = (val) => {
    setCartAmount(val);
  }


  const [useLoggedIn, setUserLoggedIn] = useState(() => {
    const cart_item = localStorage.getItem("isLoggedin");
    return cart_item !== null && (cart_item === true || cart_item === "true")
      ? true
      : false
  });




  const [offerBar, setofferBar] = useState(() => {
    const cart_item = localStorage.getItem("offerBar");
    return cart_item !== null && (cart_item === true || cart_item === "true")
      ? false
      : false
  });

  const [offerBarTwo, setofferBarTwo] = useState(() => {
    const cart_item = localStorage.getItem("offerBarTwo");
    return cart_item !== null && (cart_item === true || cart_item === "true")
      ? false
      : false
  });



  const UpdateDtatususeLoggedIn = () => {
    setUserLoggedIn(true);
    localStorage.setItem("isLoggedin", true)
  }

  const [LoginPopup, setLoginPopup] = useState(window.location.hash === "#login" ? true : false);
  /*  SHow Login Popup   */
  const HideLoginPopup = () => setLoginPopup(false);
  const ShowLoginPopup = () => setLoginPopup(true);

  const ShowLoginPopup_ = () => {
    if (useLoggedIn) {
      window.location.href = "/admin-profile";
    }
    else {
      setLoginPopup(true);
    }
  }


  const [lastURL, setlastURL] = useState(undefined);

  /*  SHow Login Popup   */

  const [CreateAccountPopup, setCreateAccountPopup] = useState(window.location.hash === "#register" ? true : false);
  /*  SHow Create Account Popup   */
  const HideCreateAccountPopup = () => setCreateAccountPopup(false);
  const ShowCreateAccountPopup = () => setCreateAccountPopup(true);

  const [CartSidebarPopup, setCartSidebarPopup] = useState(false);
  /*  SHow Create Account Popup   */
  const HideCartSidebarPopup = () => setCartSidebarPopup(false);
  const ShowCartSidebarPopup = () => setCartSidebarPopup(true);

  const [AboutPagePopup, setAboutPagePopup] = useState(false);
  /*  SHow Create Account Popup   */
  const HideAboutPagePopup = () => setAboutPagePopup(false);
  const ShowAboutPagePopup = () => setAboutPagePopup(true);


  const [SignUpPopup, setSignUpPopup] = useState(false);
  /*  SHow Create Account Popup   */
  const HideSignUpPopup = () => setSignUpPopup(false);
  const ShowSignUpPopup = () => setSignUpPopup(true);


  //cookie pref popup
  const [CookiePrefPopup, setCookiePrefPopup] = useState(() => {
    return localStorage.getItem("user_cookie") !== null ? false : true;
  });
  const HideCookiePrefPopup = () => {
    setCookiePrefPopup(false);
    localStorage.setItem("user_cookie", "enabled");
  }
  const ShowCookiePrefPopup = () => setCookiePrefPopup(true);


  /***
   * Forgot Passowrd
   */



  const [prodcut_list_order, set_prodcut_list_order] = useState(true);

  const [forgot_password_popup, setforgot_password_popup] = useState(false);
  const [forgot_username_popup, setforgot_username_popup] = useState(false);

  const forgot_password_popup_hide = () => setforgot_password_popup(false);
  const forgot_password_popup_show = () => setforgot_password_popup(true);

  const forgot_username_popup_hide = () => setforgot_username_popup(false);
  const forgot_username_popup_show = () => setforgot_username_popup(true);



  const redirectTocart = () => {
    ShowCartSidebarPopup();
  }

  const OpenRegisterPopupFromLogin = () => {
    setLoginPopup(false);
    setCreateAccountPopup(true);
  }
  /* */
  const [userEmailPass, setLoginuserDetail] = useState({ 'email': "", 'password': "" });
  const [UpdateloginUserDetail, setUpdateloginUserDetail] = useState(() => {
    const localdetail = localStorage.getItem("userDetail");
    return localdetail !== null
      ? JSON.parse(localdetail)
      : undefined;
  });


  const [LoginErrorMsgm, setLoginErrorMessage] = useState(undefined);
  const [requested_for, set_requested_for] = useState(undefined);

  const [redirect_url_, set_redirect_url_] = useState("/");

  const DoLoginAuth = (useremail, password, remember_me, requested_for, redirect_url) => {
    set_requested_for(requested_for);
    set_redirect_url_(redirect_url);
    const input = {
      email: useremail,
      password: password
    };
    setLoginuserDetail(input);
    setTimeout(function () {
      VerifyLogin();

    }, 10);
  }
  useEffect(() => {
    if (UpdateloginUserDetail !== undefined && UpdateloginUserDetail !== null && UpdateloginUserDetail.hasOwnProperty("accessToken") && UpdateloginUserDetail.accessToken !== "" && UpdateloginUserDetail.accessToken !== null) {
      if (program_date_is_gone_or_not(UpdateloginUserDetail.expireeAt)) {
        localStorage.removeItem("userDetail");
        localStorage.setItem('isLoggedin', false);
        localStorage.removeItem("checkout_token");
        localStorage.removeItem("thank_you_image");
        localStorage.removeItem("checkout_id");
        localStorage.removeItem("cart_session");
        localStorage.removeItem("create_account_detail");
        localStorage.removeItem("cart_session_sku");
        localStorage.removeItem("isLoggedin");
        localStorage.removeItem("was_book");
        localStorage.removeItem("wishlist_item");
        localStorage.removeItem("new_practice_area");
        window.location.href = "/";
      } else {
        setUserLoggedIn(true);
        localStorage.setItem("isLoggedin", true)
      }
    }
  }, [])



  const [VerifyLogin, { loading, data, error }] = useMutation(customerAccessTokenCreate, {
    variables: { userEmailPass }
  });


  const [doAddAddress, { data: address_added, error: error_on_address_added, loading: address_adding_new }] = useMutation(AddnewAddressQuery);



  useEffect(() => {

    if (data != undefined) {
      if (data.customerAccessTokenCreate.customerAccessToken != null) {
        const SaveDetail = {
          email: userEmailPass.email,
          token: userEmailPass.password,
          accessToken: data.customerAccessTokenCreate.customerAccessToken.accessToken,
          expireeAt: data.customerAccessTokenCreate.customerAccessToken.expiresAt,
          rememberMe: userEmailPass.rememberMe
        }
        localStorage.setItem("userDetail", JSON.stringify(SaveDetail));
        setUpdateloginUserDetail(SaveDetail);
        //create user detail as address
        if (requested_for == "Register") {
          const register_user_detail = localStorage.getItem("create_account_detail");
          if (register_user_detail != null) {
            const register_user_detail_json = JSON.parse(register_user_detail);
            //  console.log(register_user_detail_json);
            doAddAddress({
              variables: {
                customerAccessToken: data.customerAccessTokenCreate.customerAccessToken.accessToken,
                address: {
                  lastName: register_user_detail_json.lastName,
                  firstName: register_user_detail_json.firstName,
                  phone: register_user_detail_json.phone,
                  company: register_user_detail_json.company
                }
              }
            })
            UpdateDtatususeLoggedIn(true);

          }
          else {
            UpdateDtatususeLoggedIn(true);

            window.location.href = redirect_url_;
          }

        } else {
          UpdateDtatususeLoggedIn(true);

          window.location.href = (redirect_url_ === "/activate" || redirect_url_ === "/activate-guest") ? "/" : redirect_url_;
        }
      }
      else {
        setLoginErrorMessage(undefined);
        data.customerAccessTokenCreate.customerUserErrors.forEach(function (error) {
          // console.log(error);
          setLoginErrorMessage("Email/Mobile or Password is not correct!");
        })
      }
    }
  }, [data])


  useEffect(() => {
    if (address_added != undefined) {
      //  console.log(address_added);
      window.location.href = redirect_url_;
    }
  }, [address_added])

  /**
   * Fetch data
   */

  const [CheckoutID, setCheckoutID] = useState(() => {
    const cart_item = localStorage.getItem("checkout_id");
    const cart_item_json = JSON.parse(cart_item);
    if (cart_item !== null && cart_item_json.userErrors.length !== 0) {
      localStorage.removeItem("userDetail");
      localStorage.setItem('isLoggedin', false);
      localStorage.removeItem("checkout_token");
      localStorage.removeItem("thank_you_image");
      localStorage.removeItem("checkout_id");
      localStorage.removeItem("cart_session");
      localStorage.removeItem("create_account_detail");
      localStorage.removeItem("cart_session_sku");
      localStorage.removeItem("isLoggedin");
      localStorage.removeItem("was_book");
      window.location.href = "/";
      return undefined;
    }
    else {
      return cart_item !== null && cart_item_json.userErrors.length === 0
        ? cart_item_json.checkout.id
        : undefined
    }
  });

  const [InitialLoad, setInitialLoad] = useState(true);
  const [CardItem, AdditemTOcart] = useState(() => {
    const cart_item = localStorage.getItem("cart_session");
    return cart_item !== null
      ? JSON.parse(cart_item)
      : { lineItems: [] }
  });




  const [CardItem_sku, AdditemTOcart_sku] = useState(() => {
    const cart_item = localStorage.getItem("cart_session_sku");
    return cart_item !== null
      ? JSON.parse(cart_item)
      : { lineItems: [] }
  });


  const [CheckoutData, setCheckout] = useState(() => {
    const cart_item = localStorage.getItem("checkout_id");
    return cart_item !== null
      ? JSON.parse(cart_item)
      : undefined
  });



  const getCheckoutID = () => {
    const cart_item = localStorage.getItem("checkout_id");
    const cart_item_json = JSON.parse(cart_item);
    return cart_item !== null
      ? cart_item_json.checkout.id
      : undefined
  };

  const getCardItem_sku = () => {
    const cart_item = localStorage.getItem("cart_session_sku");
    return cart_item !== null
      ? JSON.parse(cart_item)
      : { lineItems: [] }
  };

  const getCardItem = () => {
    const cart_item = localStorage.getItem("cart_session");
    return cart_item !== null
      ? JSON.parse(cart_item)
      : { lineItems: [] }

  };

  const getCheckoutData = () => {
    const cart_item = localStorage.getItem("checkout_id");
    return cart_item !== null
      ? JSON.parse(cart_item)
      : undefined
  };




  useEffect(() => {
    if (CheckoutData != undefined) {
      UpdateCartAmount(CheckoutData.checkout.totalPrice);
      let prc = 0;
      CheckoutData.checkout.lineItems.edges.map((item) => {
        prc += item.node.quantity;
      })
      UpdateCartQty(prc);

    }
  }, [CheckoutData]);


  /***
     * Add item to cart query
     */
  const [CreateCheckoutQL, {
    loading: Addtocartloading,
    data: Addtocartdata,
    error: Addtocarterror
  }
  ] = useMutation(createCheckout, {
    variables: { CardItem }
  });

  /***
       * Add more product on existing Checkout
       */
  const [AddmoreItemInCar, {
    loading: AddtocartMoreloading,
    data: AddtocartMoredata,
    error: AddtocartMoreerror
  }
  ] = useMutation(checkoutLineItemsAdd);



  /****
   * Update cart quanitty
   */
  const [UpdateCartItemQuery, {
    loading: UpdateItemLoading,
    data: UpdatedData,
    error: ErrorUpdateCart
  }
  ] = useMutation(checkoutLineItemsUpdate);


  /***
   * Remove Cart quntity
   */
  const [RemoveCartItemQuery, {
    loading: RemoveItemLoading,
    data: RemovedData,
    error: RemoveUpdateCart
  }
  ] = useMutation(checkoutLineItemsRemove);

  /***
      * Add to cart (called by add to card button)
      */
  function AddtoCart(VariantID, Quantity, sku, stream_type, record_type, tags, pimage, slug, start_date, program_structure) {
    setInitialLoad(false);
    var mylineitem = undefined;
    var mylineitem_sku = undefined;
    const itemdata = {
      variantId: VariantID,
      quantity: Quantity,
    };

    const itemdata_sku = {
      variantId: VariantID,
      quantity: Quantity,
      sku: sku,
      stream_type: stream_type,
      record_type: record_type,
      tags: tags,
      pimage: pimage,
      slug: slug,
      start_date: start_date,
      program_structure: program_structure
    };

    let CardItem = getCardItem();

    if (CardItem.lineItems.length < 1) {
      mylineitem_sku = { lineItems: [itemdata_sku] };
    }
    else {
      const currentCartItem = CardItem;
      const newdata_sku = [itemdata_sku];

      let CardItem_sku = getCardItem_sku();

      // console.log("before");
      // console.log(CardItem_sku);

      CardItem_sku.lineItems.map(cartdata => {
        if (VariantID !== cartdata.variantId) {
          const itemdata_ = {
            variantId: cartdata.variantId,
            quantity: cartdata.quantity,
            sku: cartdata.sku,
            stream_type: cartdata.stream_type,
            record_type: cartdata.record_type,
            tags: cartdata.tags,
            pimage: cartdata.pimage,
            slug: cartdata.slug,
            start_date: cartdata.start_date,
            program_structure: cartdata.program_structure
          };
          newdata_sku.push(itemdata_);
        }
      });
      mylineitem_sku = { lineItems: newdata_sku };

    }




    mylineitem = { lineItems: [itemdata] };
    // mylineitem_sku = { lineItems: [itemdata_sku] };
    redirectTocart();

    AdditemTOcart(mylineitem);
    localStorage.setItem('cart_session', JSON.stringify(mylineitem));
    // console.log(mylineitem_sku);
    AdditemTOcart_sku(mylineitem_sku);
    localStorage.setItem('cart_session_sku', JSON.stringify(mylineitem_sku));
  }


  /**
       *  Call API to add product in cart
       */
  useEffect(() => {
    if (!InitialLoad) {

      let CheckoutData = getCheckoutData();

      if (CheckoutData != undefined) {
        if (CheckoutData.checkout.lineItems.length < 1) {
          CreateCheckoutQL();
        }
        else {
          AddmoreItemInCar({
            variables: {
              checkoutId: CheckoutData.checkout.id,
              lineItems: CardItem.lineItems
            }
          });
        }

      }
      else if (CardItem.lineItems.length) {
        CreateCheckoutQL();
      }
      setInitialLoad(false);
    }
  }, [CardItem])

  /***
         * Cart API response
         */
  useEffect(() => {
    if (Addtocartdata != undefined) {
      setCheckout(Addtocartdata.checkoutCreate);
      localStorage.setItem('checkout_id', JSON.stringify(Addtocartdata.checkoutCreate));
      setCheckoutID(Addtocartdata.checkoutCreate.checkout.id);
      //redirectTocart();
      // history.push("/cart");
    }
  }, [Addtocartdata])

  useEffect(() => {
    if (AddtocartMoredata != undefined) {
      setCheckout(AddtocartMoredata.checkoutLineItemsAdd);
      localStorage.setItem('checkout_id', JSON.stringify(AddtocartMoredata.checkoutLineItemsAdd));
      // redirectTocart();
      //  history.push("/cart");
    }
  }, [AddtocartMoredata])







  function removeItemfromCart(qty, lineID) {
    let CheckoutID = getCheckoutID();
    const variables = { checkoutId: CheckoutID, lineItemIds: [lineID] };
    RemoveCartItemQuery({ variables });

    var sku = [];
    var cart_item_sku = CardItem_sku.lineItems.filter(function (itm) {
      // console.log(itm.variantId + "!=" + lineID)
      return itm.variantId != lineID;
    })

    //  console.log(cart_item_sku);

    // AdditemTOcart_sku(cart_item_sku);

  }

  useEffect(() => {
    if (RemovedData != undefined) {
      setCheckout(RemovedData.checkoutLineItemsRemove);
      localStorage.setItem('checkout_id', JSON.stringify(RemovedData.checkoutLineItemsRemove));

      if (window.location.pathname == "/attendees") {
        window.location.reload();
      }
    }
  }, [RemovedData]);


  function UpdateCartItem(qty, lineID) {

    let CheckoutID = getCheckoutID();


    // console.log("Updated qty")
    const TempLineITEM = [];
    TempLineITEM.push({ id: lineID, quantity: parseInt(qty) });
    // console.log("CheckoutID ->" + CheckoutID);
    UpdateCartItemQuery({
      variables: {
        checkoutId: CheckoutID,
        lineItems: TempLineITEM
      }
    });

    if (window.location.pathname == "/attendees") {
      // window.location.reload();
    }
  }



  useEffect(() => {
    if (UpdatedData != undefined) {
      setCheckout(UpdatedData.checkoutLineItemsUpdate);
      // console.log(UpdatedData);
      localStorage.setItem('checkout_id', JSON.stringify(UpdatedData.checkoutLineItemsUpdate));
      if (window.location.pathname == "/attendees") {
        window.location.reload();
      }

    }
  }, [UpdatedData])







  const customerAccessToken = UpdateloginUserDetail != undefined ? UpdateloginUserDetail.accessToken : undefined;

  const { loading: getting_address, error: address_error, data: UserData } = useQuery(GetuserAddress,
    {
      variables: { customerAccessToken },
      skip: UpdateloginUserDetail === undefined ? true : false

    });



  /****
   * function calling from attendees page
   */
  function Logged_In_user_associative_with_checkout() {


  }




  const [pageJson, setpageJson] = useState([]);


  /*****
   * Store page
   *
   */
  //let ProductPageJsonTemp = [];
  const randomString = (Math.random() + 1).toString(36).substring(7);
  const [ProductPageJson, setProductPageJson] = useState([]);
  useEffect(() => {
    if (ProductPageJson.length === 0) {
      console.count("pagejson");
      axios.get(EngineURL + 'sync/update-page-item/store-page-data.json?var=' + randomString)
        .then(function (response) {
          //  console.log(response.data);
          setProductPageJson(response.data);

        });
    }
  }, []);

  useEffect(() => {
    setpageJson(ProductPageJson.sort(function (a, b) { return a.Menu_Order__c - b.Menu_Order__c }));
    // console.log(ProductPageJson);
  }, [ProductPageJson]);

  // groupValue === Shop in first

  /****
   * Store page
   *
   */




  /*****
   * HEader search function
   */

  const [HeaderSearchText, setHeaderSearchText] = useState("");



  const program_date_is_gone_or_not = (program_start_date_time) => {
    if (program_start_date_time === null) {
      return false;
    }
    program_start_date_time = new Date(program_start_date_time);
    let program_time_modify = program_start_date_time.setHours(program_start_date_time.getHours());
    let current_time = new Date().getTime();
    if ((parseFloat(program_time_modify) - parseFloat(current_time)) > 0) {
      return false;
    }
    else {
      return true;
    }
  }

  /***
      * State Variable 
      * Phase 2
      */
  const [userState, setUserState] = useState(() => {
    const user_state = localStorage.getItem("user_state");
    return user_state !== null ? user_state : 'VIC'
  });


  const [showStatePopup, setShowStatePopup] = useState(() => {
    const user_state = localStorage.getItem("user_state");
    return user_state !== null ? false : false
  });

  const [TempState, setTempState] = useState('VIC');
  const hidedStatePopup = () => setShowStatePopup(false);
  //set user selected state
  const setStateforUser = () => {
    localStorage.setItem("user_state", TempState);
    setUserState(TempState);
    setShowStatePopup(false);
  }

  //set user selected state
  const setStateforUserDropdown = (state_name) => {
    localStorage.setItem("user_state", state_name);
    setUserState(state_name);
  }
  const [userStateCodeIp, SETuserStateCodeIp] = useState("VIC2");
  const [userStateIp, SETuserStateIp] = useState("Victoria2");

  useEffect(() => {
    axios.get('https://api.db-ip.com/v2/free/self').then(res => {
      if (res.status === 200) {
        SETuserStateCodeIp(res.data.stateProvCode);
        SETuserStateIp(res.data.stateProv);
        if (res.data.countryCode == "AU") {
          if (localStorage.getItem("user_state") === null) {
            setUserState(res.data.stateProvCode);
          }
          setTempState(res.data.stateProvCode);
        }
      }
    })
  }, []);

  /*****
   * Can we help
   */
  const [CanWeHelpStatus, setCanWeHelpStatus] = useState(false);


  /***
   * Most popular search tag
   */
  const [PopularTag, setPopularTag] = useState([]);
  const fetchTag = async () => {

    try {

      const home_page_filters = AdminPanelURL + 'api/home_page_filters';
      const home_page_filters_response = await axios(home_page_filters);
      // console.log(api_URL);

      if (home_page_filters_response.status === 200) {
        var data = home_page_filters_response.data;
        if (data.length !== 0) {

          // console.log(data);
          setPopularTag(data.filters);

        }
      }

    } catch (error) {
      console.log(true);
    }

  };
  useEffect(() => {
    fetchTag();

  }, []);





  /***
   * Add to Wishlist
   * Phase 2
   */

  const AddtoWishlist = (productid, pdates) => {
    let wishlistItem = (localStorage.getItem('wishlist_item') !== undefined && localStorage.getItem('wishlist_item') !== 'undefined' && localStorage.getItem('wishlist_item') !== null) ? JSON.parse(localStorage.getItem('wishlist_item')) : [];
    let wishlistItemArray = localStorage.getItem('wishlist_item_array') !== null ? JSON.parse(localStorage.getItem('wishlist_item_array')) : [];

    let NewwishlistItem = [];
    let NewwishlistItemArray = [];
    if (!wishlistItem.includes(productid)) {
      NewwishlistItem.push(productid);
      if (pdates !== null) {
        var dt = new Date(pdates);

        var year = dt.getFullYear();
        var month = (dt.getMonth() + 1).toString().padStart(2, "0");
        var day = dt.getDate().toString().padStart(2, "0");
      }
      NewwishlistItemArray.push({ handle: productid, date: (pdates !== null) ? year + '-' + month + '-' + day : null });

      //Save to database throug API
      if (useLoggedIn) {


        let userDetail = JSON.parse(localStorage.getItem("userDetail"));

        let pslug = [];
        let pdate = [];
        NewwishlistItemArray.map((prdct) => {
          pslug.push(prdct.handle);
          pdate.push(prdct.date);
        })

        const Wishlistdata = {
          email: userDetail.email,
          p_id: pslug.join(","),
          date: pdate.join(",")
        };
        axios.post(EngineURL + 'wishlist/wishlist_add.php', Wishlistdata)
          .then(function (response) {
            //console.log(response);
          })
          .catch(function (error) {
            // console.log(error);
          });
      }

    }
    else {
      //Remove item from wishlist
      RemoveItemWhishlst(productid);
    }

    wishlistItem.map(data => {
      if (data !== productid) {
        NewwishlistItem.push(data);
      }
    })

    setgetAllWishlist(NewwishlistItem);
    localStorage.setItem('wishlist_item', JSON.stringify(NewwishlistItem));
    localStorage.setItem('wishlist_item_array', JSON.stringify(NewwishlistItemArray));


  }


  /****
   * Remove item from wishlist
   * Phase 2
   */
  const RemoveItemWhishlst = (productid) => {
    if (useLoggedIn) {

      let userDetail = JSON.parse(localStorage.getItem("userDetail"));
      let Wishlistdata = {
        email: userDetail.email,
        p_id: productid

      };

      axios.post(EngineURL + 'wishlist/wishlist_delete.php', Wishlistdata)
        .then(function (response) {
          //console.log(response);
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  }


  /***
   * Save offline data to databse
   * Phase 2
   */
  useEffect(() => {
    let wishlistItem = (localStorage.getItem('wishlist_item') !== undefined && localStorage.getItem('wishlist_item') !== 'undefined' && localStorage.getItem('wishlist_item') !== null) ? JSON.parse(localStorage.getItem('wishlist_item')) : [];
    //Save to database throug API
    //After login
    if (useLoggedIn && wishlistItem.length !== 0) {


      let userDetail = JSON.parse(localStorage.getItem("userDetail"));

      const Wishlistdata = {
        email: userDetail.email,
        p_id: wishlistItem.join(",")
      };
      axios.post(EngineURL + 'wishlist/wishlist_add.php', Wishlistdata)
        .then(function (response) {
          // console.log(response);
          FetchWishlistData();
        })
        .catch(function (error) {
          // console.log(error);
        });
    }

  }, []);

  /***
   * Get user Wishlist & Save without login wishlist to Database
   * Phase 2
   */
  const [getAllWishlist, setgetAllWishlist] = useState([]);
  const FetchWishlistData = async () => {
    let userDetail = JSON.parse(localStorage.getItem("userDetail"));

    axios.get(EngineURL + 'wishlist/wishlist_get.php?email=' + userDetail.email)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.length !== 0) {
            setgetAllWishlist(response.data.result);
            localStorage.setItem('wishlist_item', JSON.stringify(response.data.result));

          }
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  useEffect(() => {
    if (useLoggedIn) {
      FetchWishlistData();
    } else {
      //load wishlist from localstorage
      let wishlistItem = (localStorage.getItem('wishlist_item') !== undefined && localStorage.getItem('wishlist_item') !== 'undefined' && localStorage.getItem('wishlist_item') !== null) ? JSON.parse(localStorage.getItem('wishlist_item')) : [];
      if (wishlistItem.length !== 0) {
        setgetAllWishlist(wishlistItem);
      }
    }
  }, []);



  const isDayIS = () => {
    var today = new Date()
    var curHr = today.getHours()

    if (curHr < 12) {
      return 'Good morning';
    } else if (curHr < 18) {
      return 'Good afternoon';
    } else {
      return 'Good evening';
    }
  }
  const Good = isDayIS();


  return (

    <>
      <Router>

        <Header
          userState={userState}
          setStateforUserDropdown={setStateforUserDropdown}
          setHeaderSearchText={setHeaderSearchText}
          offerBar={offerBar}
          setofferBar={setofferBar}
          ProductPageJson={pageJson}
          redirectTocart={redirectTocart}
          ShowAboutPagePopup={ShowAboutPagePopup}
          ShowLoginPopup={ShowLoginPopup}
          useLoggedIn={useLoggedIn}
          ShowLoginPopup_={ShowLoginPopup_}
          CartAmount={CartAmount}
          ShowCreateAccountPopup={ShowCreateAccountPopup}
          AdminPanelURL={AdminPanelURL}
          ShowSignUpPopup={ShowSignUpPopup}
          Cartqty={Cartqty} />
        <ScrollToTop />
        <StatePopup showStatePopup={showStatePopup} hidedStatePopup={hidedStatePopup} TempState={TempState} setTempState={setTempState} setStateforUser={setStateforUser} userStateCodeIp={userStateCodeIp} userStateIp={userStateIp} />
        <div className="main position-relative">
          <Switch>
            <Route exact path="/">
              <HomePage
                userState={userState}
                offerBarTwo={offerBarTwo}
                setofferBarTwo={setofferBarTwo}
                lastURL={lastURL}
                setlastURL={setlastURL}
                ShowLoginPopup={ShowLoginPopup}
                useLoggedIn={useLoggedIn}
                Program_structure_images={Program_structure_images}
                EngineURL={EngineURL}
                prodcut_list_order={prodcut_list_order}
                set_prodcut_list_order={set_prodcut_list_order}
                ProductPageJson={ProductPageJson}
                AddtoCart={AddtoCart}
                UpdateCartQty={UpdateCartQty}
                ShowSignUpPopup={ShowSignUpPopup}
                UserData={UserData}
                AdminPanelURL={AdminPanelURL}
                PopularTag={PopularTag}
                UpdateCartAmount={UpdateCartAmount} />
            </Route>
            <Route path="/product/:slug">
              <SIngleProduct
                AddtoWishlist={AddtoWishlist}
                getAllWishlist={getAllWishlist}
                EngineURL={EngineURL}
                Program_structure_images={Program_structure_images}
                Program_structure_color={Program_structure_color}
                AddtoCart={AddtoCart}
                UpdateCartQty={UpdateCartQty}
                UpdateCartAmount={UpdateCartAmount} />
            </Route>

            {ProductPageJson.map((menu, index) => {
              const menuname = menu.Name;
              if (menuname === null || menuname === undefined || menu.Status !== "Active") return;
              const slug = menuname.split(' ').join('-').toLowerCase();
              return <Route path={'/' + slug} key={menuname}>
                <ProductPage
                key={menuname+"111"}
                  AddtoWishlist={AddtoWishlist}
                  getAllWishlist={getAllWishlist}
                  PopularTag={PopularTag}
                  userState={userState}
                  ShowSignUpPopup={ShowSignUpPopup}
                  offerBarTwo={offerBarTwo}
                  setofferBarTwo={setofferBarTwo}
                  lastURL={lastURL}
                  setlastURL={setlastURL}
                  EngineURL={EngineURL}
                  Program_structure_images={Program_structure_images}
                  prodcut_list_order={prodcut_list_order}
                  set_prodcut_list_order={set_prodcut_list_order}
                  ProductPageJson={ProductPageJson}
                  AddtoCart={AddtoCart}
                  UpdateCartQty={UpdateCartQty}
                  ShowLoginPopup={ShowLoginPopup}
                  useLoggedIn={useLoggedIn}
                  UserData={UserData}
                  AdminPanelURL={AdminPanelURL}
                  Program_structure_color={Program_structure_color}
                  storeURL={storeURL}
                  UpdateCartAmount={UpdateCartAmount} />
              </Route>
            })}

            <Router path="/search">
              <Search
                AddtoWishlist={AddtoWishlist}
                getAllWishlist={getAllWishlist}
                Program_structure_color={Program_structure_color}
                PopularTag={PopularTag}
                userState={userState}
                ShowSignUpPopup={ShowSignUpPopup}
                offerBarTwo={offerBarTwo}
                setofferBarTwo={setofferBarTwo}
                lastURL={lastURL}
                setlastURL={setlastURL}
                EngineURL={EngineURL}
                Program_structure_images={Program_structure_images}
                prodcut_list_order={prodcut_list_order}
                set_prodcut_list_order={set_prodcut_list_order}
                ProductPageJson={ProductPageJson}
                AddtoCart={AddtoCart}
                UpdateCartQty={UpdateCartQty}
                ShowLoginPopup={ShowLoginPopup}
                useLoggedIn={useLoggedIn}
                UserData={UserData}
                AdminPanelURL={AdminPanelURL}
                storeURL={storeURL}
                UpdateCartAmount={UpdateCartAmount} />
            </Router>

            <Router path="/all-courses">
              <Search
                AddtoWishlist={AddtoWishlist}
                getAllWishlist={getAllWishlist}
                Program_structure_color={Program_structure_color}
                PopularTag={PopularTag}
                userState={userState}
                ShowSignUpPopup={ShowSignUpPopup}
                offerBarTwo={offerBarTwo}
                setofferBarTwo={setofferBarTwo}
                lastURL={lastURL}
                setlastURL={setlastURL}
                EngineURL={EngineURL}
                Program_structure_images={Program_structure_images}
                prodcut_list_order={prodcut_list_order}
                set_prodcut_list_order={set_prodcut_list_order}
                ProductPageJson={ProductPageJson}
                AddtoCart={AddtoCart}
                UpdateCartQty={UpdateCartQty}
                ShowLoginPopup={ShowLoginPopup}
                useLoggedIn={useLoggedIn}
                UserData={UserData}
                AdminPanelURL={AdminPanelURL}
                storeURL={storeURL}
                UpdateCartAmount={UpdateCartAmount} />
            </Router>

            <Route path="/my-wishlist">
              <AdminWishlist
                Good={Good}
                AddtoWishlist={AddtoWishlist}
                getAllWishlist={getAllWishlist}
                EngineURL={EngineURL}
                useLoggedIn={useLoggedIn}
                Program_structure_images={Program_structure_images}
                Program_structure_color={Program_structure_color}
                UpdateloginUserDetail={UpdateloginUserDetail}
                UpdateCartQty={UpdateCartQty}
                AddtoCart={AddtoCart}
                UpdateCartAmount={UpdateCartAmount}
                ShowLoginPopup={ShowLoginPopup}
                ShowLoginPopup_={ShowLoginPopup_}
                UserData={UserData}
              />
            </Route>
            <Route path="/admin-my-events">
              <AdminMyEvents
                Good={Good}
                EngineURL={EngineURL}
                useLoggedIn={useLoggedIn}
                UpdateloginUserDetail={UpdateloginUserDetail}
                UpdateCartQty={UpdateCartQty}
                UpdateCartAmount={UpdateCartAmount}
                ShowLoginPopup={ShowLoginPopup}
                ShowLoginPopup_={ShowLoginPopup_}
                UserData={UserData}

              />
            </Route>
            <Route path="/admin-orders">
              <AdminOrders
                Good={Good}
                EngineURL={EngineURL}
                useLoggedIn={useLoggedIn}
                UpdateloginUserDetail={UpdateloginUserDetail}
                UpdateCartQty={UpdateCartQty}
                UpdateCartAmount={UpdateCartAmount}
                ShowLoginPopup={ShowLoginPopup}
                ShowLoginPopup_={ShowLoginPopup_}
                UserData={UserData}
              />
            </Route>

            <Route path="/admin-cpd-points">
              <AdminCPDPoints
                Good={Good}
                EngineURL={EngineURL}
                useLoggedIn={useLoggedIn}
                UpdateloginUserDetail={UpdateloginUserDetail}
                UpdateCartQty={UpdateCartQty}
                UpdateCartAmount={UpdateCartAmount}
                ShowLoginPopup={ShowLoginPopup}
                ShowLoginPopup_={ShowLoginPopup_}
                UserData={UserData}
                AdminPanelURL={AdminPanelURL}
              />
            </Route>
            <Route path="/admin-my-library">
              <AdminMyLibrary
                Good={Good}
                EngineURL={EngineURL}
                Program_structure_images={Program_structure_images}
                useLoggedIn={useLoggedIn}
                UpdateloginUserDetail={UpdateloginUserDetail}
                UpdateCartQty={UpdateCartQty}
                UpdateCartAmount={UpdateCartAmount}
                ShowLoginPopup={ShowLoginPopup}
                ShowLoginPopup_={ShowLoginPopup_}
                UserData={UserData}
              />
            </Route>
            <Route path="/refer-earn">
              <AdminRefer
                Good={Good}
                EngineURL={EngineURL}
                useLoggedIn={useLoggedIn}
                UserData={UserData}
                UpdateloginUserDetail={UpdateloginUserDetail}
              />
            </Route>
            <Route path="/reset">
              <ResetPassword
                ShowLoginPopup={ShowLoginPopup}
              />
            </Route>
            <Route path="/activate">
              <ActivateAccount
                EngineURL={EngineURL}
                ShowLoginPopup={ShowLoginPopup}
              />
            </Route>
            <Route path="/activate-guest">
              <ActivateAccountGuest
                EngineURL={EngineURL}
                ShowLoginPopup={ShowLoginPopup}
              />
            </Route>
            <Route path="/admin-profile">
              <AdminProfile
                Good={Good}
                userState={userState}
                setStateforUserDropdown={setStateforUserDropdown}
                UpdateloginUserDetail={UpdateloginUserDetail}
                useLoggedIn={useLoggedIn}
                ShowLoginPopup_={ShowLoginPopup}
                UpdateCartQty={UpdateCartQty}
                EngineURL={EngineURL}
                UpdateCartAmount={UpdateCartAmount} />
            </Route>

            <Route path="/attendees">
              <Attendees
                EngineURL={EngineURL}
                Program_structure_images={Program_structure_images}
                ShowLoginPopup={ShowLoginPopup}
                CardItem_sku={CardItem_sku}
                UserData={UserData}
                useLoggedIn={useLoggedIn}
                CartData={CheckoutData}
                customerAccessToken={customerAccessToken}
                ShowCreateAccountPopup={ShowCreateAccountPopup}
                HideCreateAccountPopup={HideCreateAccountPopup}
                setAddresstoCheckout={Logged_In_user_associative_with_checkout}
                UpdateCartQty={UpdateCartQty}
                UpdateCartAmount={UpdateCartAmount}
              />
            </Route>

            <Route path="/cart" >
              <Cart
                Program_structure_images={Program_structure_images}
                UpdateCartItem={UpdateCartItem}
                removeItemfromCart={removeItemfromCart}
                UpdateCartQty={UpdateCartQty}
                UpdateCartAmount={UpdateCartAmount}
                CartData={CheckoutData}
                CardItem_sku={CardItem_sku}

              />

            </Route>
            <Route path="/thank-you">
              <Thankyou
                EngineURL={EngineURL}
                ShowCreateAccountPopup={ShowCreateAccountPopup}
                ShowLoginPopup={ShowLoginPopup}
                Program_structure_images={Program_structure_images}
                useLoggedIn={useLoggedIn}
                UpdateCartQty={UpdateCartQty}
                UpdateCartAmount={UpdateCartAmount} />

            </Route>
            <Route path="*" component={NotFound} status={404}/>
          </Switch>
        </div>
        <CookieConsent
          location="bottom"
          buttonText="Accept!"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#01293A" }}
          buttonStyle={{ background: "#FEC010", color: "#01293A", fontSize: "14px" }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>

        <Footer
          EngineURL={EngineURL}
          setCanWeHelpStatus={setCanWeHelpStatus} />


        <Login
          forgot_password_popup_show={forgot_password_popup_show}
          OpenRegisterPopupFromLogin={OpenRegisterPopupFromLogin}
          forgot_username_popup_show={forgot_username_popup_show}
          forgot_username_popup_hide={forgot_username_popup_hide}
          LoginPopup={LoginPopup}
          HideLoginPopup={HideLoginPopup}
          EngineURL={EngineURL}
          DoLoginAuth={DoLoginAuth}
          setLoginErrorMessage={setLoginErrorMessage}
          LoginErrorMsgm={LoginErrorMsgm}
          useLoggedIn={useLoggedIn} />

        <CreateAccount
          EngineURL={EngineURL}
          DoLoginAuth={DoLoginAuth}
          UpdateDtatususeLoggedIn={UpdateDtatususeLoggedIn}
          CreateAccountPopup={CreateAccountPopup}
          ShowCreateAccountPopup={ShowCreateAccountPopup}
          HideCreateAccountPopup={HideCreateAccountPopup} />

        <CartSidebar
          UpdateCartItem={UpdateCartItem}
          removeItemfromCart={removeItemfromCart}
          CartData={CheckoutData}
          CardItem_sku={CardItem_sku}
          Addtocartloading={Addtocartloading}
          AddtocartMoreloading={AddtocartMoreloading}
          RemoveItemLoading={RemoveItemLoading}
          UpdateItemLoading={UpdateItemLoading}
          CartSidebarPopup={CartSidebarPopup}
          HideCartSidebarPopup={HideCartSidebarPopup} />

        <AboutPage
          AboutPagePopup={AboutPagePopup}
          ShowAboutPagePopup={ShowAboutPagePopup}
          HideAboutPagePopup={HideAboutPagePopup} />

        <SignUp
          EngineURL={EngineURL}
          SignUpPopup={SignUpPopup}
          ShowSignUpPopup={ShowSignUpPopup}
          HideSignUpPopup={HideSignUpPopup} />

        <ForgotPassword
          LoginPopup={LoginPopup}
          ShowLoginPopup={ShowLoginPopup}
          HideLoginPopup={HideLoginPopup}
          forgot_password_popup={forgot_password_popup}
          forgot_password_popup_show={forgot_password_popup_show}
          forgot_password_popup_hide={forgot_password_popup_hide}
          forgot_username_popup={forgot_username_popup}
          forgot_username_popup_show={forgot_username_popup_show}
          forgot_username_popup_hide={forgot_username_popup_hide}
        />

        <ForgotUsername
          LoginPopup={LoginPopup}
          ShowLoginPopup={ShowLoginPopup}
          HideLoginPopup={HideLoginPopup}
          EngineURL={EngineURL}
          forgot_username_popup={forgot_username_popup}
          forgot_username_popup_show={forgot_username_popup_show}
          forgot_username_popup_hide={forgot_username_popup_hide}
        />

        <CanWeHelp
          EngineURL={EngineURL}
          CanWeHelpStatus={CanWeHelpStatus}
          setCanWeHelpStatus={setCanWeHelpStatus} />
        <CookiePref
          CookiePrefPopup={CookiePrefPopup}
          HideCookiePrefPopup={HideCookiePrefPopup}
          ShowCookiePrefPopup={ShowCookiePrefPopup} />

      </Router>

    </>
  );
}



export default App;

import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
    MyLibraryIcon,
    MyEventsIcon,
    CPDPointsIcon,
    OrdersIcon,
    ProfileIcon,
    SearchIcon,
    MoreOptionsIcon,
    CaretUpIcon,
    CaretDownIcon,
    InformationIcon,
    LoaderIcon,
    LogOutIcon
} from "../../icons";
import {
    Container,
    Row,
    Col,
    ListGroup,
    Form,
    FormControl,
    Button,
    Accordion,
    Popover,
    OverlayTrigger,
    AccordionButton,
    Modal,
    Table,
    Alert,
    Pagination
} from 'react-bootstrap';
import axios from 'axios';
import { Helmet } from "react-helmet";
import { AdminSidebar } from './Sidebar/AdminSidebar';
import BlueBg from "../../images/my-profile-banner.png";


export const AdminOrders = (props) => {
    let history = useHistory();
    const My_Account_button_click = () => {
        if (props.useLoggedIn)
            history.push('/admin-my-library');
        else
            props.ShowLoginPopup_();
    }

    const [show, setShow] = useState(false);
    const [update, setUpdate] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setHelpText('');
        setHelpCheckbox(false);
        set_form_submitted(false);
    }
    const setUpdatehandleClose = () => setUpdate(false);
    const setUpdatehandleShow = () => setUpdate(true);


    // Orders states
    const [orderDetails, setOrderDetails] = useState([]);
    const [orderLines, setOrderLines] = useState([]);
    const [programRegistrations, setProgramRegistrations] = useState([]);
    const [ProgramFees, setProgramFees] = useState([]);
    const [program_record_types, set_program_record_types] = useState([]);
    const [program_details, set_program_details] = useState([]);
    const [contact_details, set_contact_details] = useState([]);
    const [loading, setLoading] = useState(true);
    const search_reference = useRef('');
    const [helpText, setHelpText] = useState('');
    const [helpCheckbox, setHelpCheckbox] = useState(false);
    const [help_text_error, set_help_text_error] = useState('');
    const [help_checkbox_error, set_help_checkbox_error] = useState('');
    const [form_submitted, set_form_submitted] = useState(false);

    const needHelpSubmit = () => {

        if (helpText == '') {
            set_help_text_error('This field is required.');
            set_form_submitted(false);
        }
        else {
            set_help_text_error('');

        }
        if (helpCheckbox === false) {
            set_help_checkbox_error('This field is required.');
            set_form_submitted(false);
        }
        else {
            set_help_checkbox_error('');
        }
        if (helpText !== '' && helpCheckbox === true) {
            send_mail(helpText, user_email, user_lastname);
        }
    }

    async function send_mail(helpText, user_email) {
        const response = await axios.get(`${props.EngineURL}canwehelp/need-help-email.php?sms=${helpText}&email=${user_email}&lanme=${user_lastname}`);
        if (response.data.msg == 'Success') {
            set_help_text_error('');
            set_help_checkbox_error('');
            setHelpText('');
            setHelpCheckbox(false);
            set_form_submitted(true);
        }
    }

    const [user_email, set_user_email] = useState('');
    const [user_token, set_user_token] = useState('');
    const [user_lastname, set_user_lastname] = useState('');
    const [error, set_error] = useState('');
    const [my_orders, set_my_orders] = useState([]);
    const per_page = 10;
    const [current_page, set_current_page] = useState(1);
    const [total_orders, set_total_orders] = useState(0);
    const [total_pages, set_total_pages] = useState(0);
    const [pagination_customize, set_pagination_customize] = useState(0);
    const [paginated_orders, set_paginated_orders] = useState([]);

    let order_item_index = 0;
    let isMounted;

    const leo_order_search = () => {
        set_current_page(1);
        let searh_query = search_reference.current.value;
        // console.log('user_lastname', user_lastname);
        if (user_email != '' && user_email !== undefined && user_lastname !== undefined) {
            setLoading(true);
            getData(user_email, searh_query, user_lastname);
        }
    }

    async function getData(email = '', searh_query = '', lastname) {
        if (email == '') {
            email = user_email;
        }
        if (lastname == '') {
            lastname = user_lastname;
        }
        // console.log('user_lastname', lastname);
        if (email != '' && email !== undefined) {
            await axios.get(`${props.EngineURL}salesforce-query/checkout/leo_orders_debug.php?contact_id=${email}&searh_query=${searh_query}&lastname=${lastname}`, { crossdomain: true })
                .then(function (response) {
                    // handle success
                    if (response.data.error !== undefined && response.data.error !== '') {
                        set_error(response.data.error);
                    }
                    else {
                        set_error('');
                        setOrderDetails(response.data.orders);
                        setOrderLines(response.data.order_lines);
                        setProgramRegistrations(response.data.program_registrations);
                        setProgramFees(response.data.program_registration_fees);
                        set_program_record_types(response.data.program_record_types);
                        set_program_details(response.data.program_details);
                        set_contact_details(response.data.contact_details);
                        set_my_orders(response.data.my_orders);
                        set_total_orders(response.data.total_my_orders);
                        set_total_pages(Math.ceil(response.data.total_my_orders / per_page));
                    }
                    setLoading(false);
                    return response;

                })
                .catch(function (error) {
                    // handle error
                })
                .then(function () {
                    // always executed
                });
        }

    }

    useEffect(() => {
        if (my_orders !== undefined) {
            // console.log('total_pages', total_pages);
            // console.log('total_orders', total_orders);
            let total_pages_array = [];
            if (total_pages > 0) {
                for (let order_page = 1; order_page <= total_pages; order_page++) {
                    total_pages_array.push(order_page);
                    set_pagination_customize(total_pages_array);

                }

            }
            // console.log('total_pages_array', total_pages_array);
        }
    }, [total_pages]);

    useEffect(() => {
        //console.log('pagination_customize', pagination_customize);
    }, [pagination_customize])

    useEffect(() => {

        // console.log('current page changed my_orders', my_orders);
        // console.log('current page changed my_orders', Object.keys(my_orders).length);
        let new_my_orders;
        Object.keys(my_orders).map((my_order_key) => {
            // console.log('my_order_key', my_order_key);
            new_my_orders = my_orders[my_order_key].show = false;
        });
        // console.log('new_my_orders', new_my_orders);
        set_paginated_orders(my_orders);
    }, [current_page]);

    useEffect(() => {

        isMounted = true;
        let orders_data = getData();

        if (isMounted) {
            setOrderDetails(orders_data.data !== undefined && orders_data.data.orders !== undefined ? orders_data.data.orders : []);
        }

        return () => {
            isMounted = false;
        };


    }, []);

    useEffect(() => {
    }, [orderDetails, orderLines, programRegistrations]);

    const sortOrder = (by, type) => {

        var sortedOrders = Object.keys(my_orders).sort(function (keyA, keyB) {
            if (by == "Name") {
                if (by == 'Name' && type == 'desc') {
                    return my_orders[keyB][by].localeCompare(my_orders[keyA][by]);
                }
                else {
                    return my_orders[keyA][by].localeCompare(my_orders[keyB][by]);
                }
            }
            else {
                if (type == 'desc') {
                    return (my_orders[keyB][by]) - (my_orders[keyA][by]);
                }
                else {
                    return (my_orders[keyA][by]) - (my_orders[keyB][by]);
                }
            }
        }); //
        let updated_order = {};
        if (sortedOrders.length > 0) {
            sortedOrders.forEach((order_val, order_key) => {
                updated_order[order_val] = my_orders[order_val];
            });
        }
        set_my_orders(updated_order);
    }

    const set_program_html = (data) => {

    }

    useEffect(() => {

    }, []);

    useEffect(() => {
        var userDetail = (localStorage.getItem("userDetail"));
        var userLoginData = JSON.parse(userDetail);
        let user_lname = '';
        let accessToken = '';
        if (props.UserData != undefined && props.UserData != null && props.UserData.customer !== null) {
            //  console.log('setting');
            set_user_lastname(props.UserData.customer.lastName);
            user_lname = props.UserData.customer.lastName;
            //  console.log('get_user_lname', user_lname);
            // console.log('user_lastname', user_lastname);
            set_user_lastname(user_lname);
        }
        if (userLoginData !== null && userLoginData !== undefined) {
            var login_user_email = userLoginData.email;
            accessToken = userLoginData.accessToken;
            if (login_user_email !== undefined && login_user_email !== '') {
                set_user_email(userLoginData.email);
            }
            if (accessToken !== undefined && accessToken !== '' && user_lname != '') {

                set_user_token(userDetail.accessToken);
            }
        }
        if (accessToken !== undefined && accessToken !== '' && user_lname != '') {
            // console.log('user_lnamedddd', user_lname);
            let orders_data = getData(login_user_email, '', user_lname);
        }
        // console.log('my props', props.UserData);

    }, [props.UserData])

    const doLogout = () => {
        localStorage.removeItem("userDetail");
        localStorage.setItem('isLoggedin', false);
        localStorage.removeItem("checkout_token");
        localStorage.removeItem("thank_you_image");
        localStorage.removeItem("checkout_id");
        localStorage.removeItem("cart_session");
        localStorage.removeItem("create_account_detail");
        localStorage.removeItem("cart_session_sku");
        localStorage.removeItem("isLoggedin");
        localStorage.removeItem("was_book");
        localStorage.removeItem("wishlist_item");
        window.location.href = "/";
    }

    const renderPage = () => {
        search_reference.current.value = '';
        setLoading(true);
        // console.log('renderPage');
        getData(user_email, '', user_lastname);
    }

    return (
        <div className="admin_page admin_orders" style={{ backgroundImage: "url(" + BlueBg + ")" }}>
            <Helmet>
                <title>My Orders | Leo Cussen Centre for Law</title>
            </Helmet>
            <Container>
                {
                    props.useLoggedIn !== undefined && props.useLoggedIn == true && error == ''
                        ?

                        <div className="admin_wrapper position-relative">
                            {props.UserData !== null && props.UserData !== undefined ?
                                <div className='admin_details_block mb-5'>
                                    <h1 className="m-0">{props.Good},  {props.UserData.customer.firstName}</h1>
                                </div>
                                : null}
                            <Row>
                                <Col xl={3} lg={4} xs={12} className="d-none d-lg-block">
                                    <AdminSidebar doLogout={doLogout} />
                                </Col>
                                <Col xl={9} lg={8} xs={12}>
                                    <div className='admin_sidebar_mobile d-lg-none'>
                                        <AdminSidebar doLogout={doLogout} />
                                    </div>
                                    <div className="admin_right_content_block">
                                        <div className="information_block card border-exlight">
                                            <div className="information_content">
                                                <InformationIcon />
                                                <p className="small mb-0">
                                                    <strong>Disclaimer</strong>
                                                </p>
                                                <p className="mt-2 small small-dec mb-0">CPD customers please note that order history is retained for the current and previous CPD years (1 April – 31 March). For enquiries contact <a href="mailto:registration@leocussen.edu.au">registration@leocussen.edu.au</a>.</p>

                                            </div>
                                        </div>
                                        <div className="admin_head d-flex flex-wrap align-items-center justify-content-between">
                                            <h4 className="m-0 text-dark">My Orders</h4>
                                            <Form className="d-flex search_block" onSubmit={(e) => {
                                                e.preventDefault();
                                                leo_order_search();
                                            }}>
                                                <div className="search_group position-relative">
                                                    <FormControl
                                                        type="search"
                                                        placeholder="Type and enter to search"
                                                        className="search_filed"
                                                        aria-label="Search"
                                                        ref={search_reference}
                                                        onChange={(e) => {
                                                            let search_value = e.target.value;
                                                            if (search_value == '') {
                                                                leo_order_search();
                                                            }
                                                        }}
                                                    />
                                                    <Button variant="search"><SearchIcon /></Button>
                                                </div>
                                            </Form>
                                        </div>
                                        <div className="order_data_wrapper">
                                            <div className="order_data_head shadow bg-white rounded-2">
                                                <ListGroup horizontal as="ul" className="align-items-center justify-content-between">
                                                    <ListGroup.Item as="li">Order
                                                        <div className="shorting_changer">
                                                            <span className="up_no" onClick={() => sortOrder('Name', 'asc')}><CaretUpIcon /></span>
                                                            <span className="down_no" onClick={() => sortOrder('Name', 'desc')}><CaretDownIcon /></span>
                                                        </div>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li">Date
                                                        <div className="shorting_changer">
                                                            <span className="up_no" onClick={() => sortOrder('Purshase_Date__seconds', 'asc')} ><CaretUpIcon /></span>
                                                            <span className="down_no" onClick={() => sortOrder('Purshase_Date__seconds', 'desc')} ><CaretDownIcon /></span>
                                                        </div>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li">Subtotal
                                                        <div className="shorting_changer">
                                                            <span className="up_no" onClick={() => sortOrder('Subtotal__c', 'asc')}><CaretUpIcon /></span>
                                                            <span className="down_no" onClick={() => sortOrder('Subtotal__c', 'desc')}><CaretDownIcon /></span>
                                                        </div>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li">GST
                                                        <div className="shorting_changer">
                                                            <span className="up_no" onClick={() => sortOrder('Total_GST__c', 'asc')} ><CaretUpIcon /></span>
                                                            <span className="down_no" onClick={() => sortOrder('Total_GST__c', 'desc')} ><CaretDownIcon /></span>
                                                        </div>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li">Total
                                                        <div className="shorting_changer">
                                                            <span className="up_no" onClick={() => sortOrder('Total_Price__c', 'asc')} ><CaretUpIcon /></span>
                                                            <span className="down_no" onClick={() => sortOrder('Total_Price__c', 'desc')} ><CaretDownIcon /></span>
                                                        </div>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li">
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </div>
                                            <div className="order_data_listing_block">
                                                <Accordion defaultActiveKey="0">
                                                    {
                                                        my_orders !== undefined && loading === false && Object.keys(my_orders).length > 0 ?

                                                            Object.keys(my_orders).map((order_item, order_values) => {
                                                                // console.log('order_item',order_item);
                                                                let order = my_orders[order_item];
                                                                // console.log('order',order);
                                                                let total_gst = order.Total_GST__c;
                                                                let purchase_date = order.Purshase_Date__c2;
                                                                let subtotal = order.Subtotal__c;
                                                                let total_price = order.Total_Price__c;
                                                                let order_lines = order.order_lines;
                                                                let users_order_count = order.users_order_count;
                                                                // console.log('users_order_count',users_order_count);
                                                                let order_lines_length = Object.keys(order_lines).length;
                                                                order_item_index = order_item_index + 1;
                                                                let high_order_number = current_page * per_page;
                                                                let low_order_number = high_order_number - (per_page);

                                                                if ((order_item_index > low_order_number && order_item_index <= high_order_number)) {
                                                                    return <Accordion.Item eventKey={order_item_index} className="shadow bg-white rounded-2" key={order_item_index}>
                                                                        <div className="accordion_header">
                                                                            <div className="order_head_record_block">
                                                                                <ListGroup horizontal as="ul" className="align-items-center justify-content-between">
                                                                                    <ListGroup.Item as="li" className="d-flex align-items-center"><AccordionButton className="" ></AccordionButton> {order.Shopify_Order_Number__c}</ListGroup.Item>
                                                                                    <ListGroup.Item as="li">{purchase_date}</ListGroup.Item>
                                                                                    <ListGroup.Item as="li">${parseFloat(subtotal).toFixed(2)}</ListGroup.Item>
                                                                                    <ListGroup.Item as="li">${total_gst}</ListGroup.Item>
                                                                                    <ListGroup.Item as="li">${parseFloat(total_price).toFixed(2)}</ListGroup.Item>
                                                                                    <ListGroup.Item as="li">
                                                                                        {/* <p className="text-center mb-0"><Link to="#" className="link-item" onClick={setUpdatehandleShow}>Download Invoice</Link></p> */}
                                                                                        <p className="text-center mb-0"><Link to="#" className="link-item small" onClick={handleShow}>Need Help?</Link></p>
                                                                                        {/* <>
                                                                                    {['bottom'].map((placement) => (
                                                                                        <OverlayTrigger
                                                                                            trigger="click"
                                                                                            key={placement}
                                                                                            placement={placement}
                                                                                            overlay={
                                                                                                <Popover id={`popover-positioned-${placement}`}>
                                                                                                    <Popover.Body>
                                                                                                        <Link to="#" className="link-item" onClick={handleShow}>Need Help?</Link>
                                                                                                        <Link to="#" className="link-item" onClick={setUpdatehandleShow}>Download Invoice</Link>
                                                                                                    </Popover.Body>
                                                                                                </Popover>
                                                                                            }
                                                                                        >
                                                                                            <Button variant="link"><MoreOptionsIcon /></Button>
                                                                                        </OverlayTrigger>
                                                                                    ))}
                                                                                </> */}
                                                                                    </ListGroup.Item>
                                                                                </ListGroup>
                                                                            </div>
                                                                        </div>
                                                                        <Accordion.Body>
                                                                            {
                                                                                order_lines_length > 0 ?
                                                                                    Object.keys(order_lines).map((order_line_key) => {
                                                                                        let program_registrations = order_lines[order_line_key]['program_registrations'];
                                                                                        let program_registrations_length = Object.keys(program_registrations).length;

                                                                                        return (
                                                                                            <Fragment key={Math.random()}>

                                                                                                {
                                                                                                    program_registrations_length > 0
                                                                                                        ?
                                                                                                        Object.keys(program_registrations).map((program_id) => {
                                                                                                            let program_details = program_registrations[program_id];
                                                                                                            let program_name = program_details.program_name;
                                                                                                            let program_slug = program_details.program_slug !== null ? program_details.program_slug : '';
                                                                                                            let attendy_fname = program_details.Attendee_First_Name__c;
                                                                                                            let attendy_lname = program_details.Attendee_Last_Name__c;
                                                                                                            let attendy_email = program_details.Attendee_Email__c;
                                                                                                            let Dietary_Requirement = program_details.Dietary_Requirement__c;
                                                                                                            let Registration_Date = program_details.Registration_Date__c !== null ? program_details.Registration_Date__c.split('-').reverse().join('/') : null;
                                                                                                            let recordTypeName = program_details.recordTypeName;
                                                                                                            let program_fees_details = program_details['program_fees'];
                                                                                                            let program_fee_amount = program_fees_details[0] !== undefined && program_fees_details[0]['Amount__c'] !== undefined ? program_fees_details[0]['Amount__c'] : '';
                                                                                                            let bill_to = program_details['Bill_to__c'];
                                                                                                            let contact_to = program_details['contact__c'];
                                                                                                            let attendy_name = contact_details[contact_to] !== undefined && contact_details[contact_to]['totalSize'] > 0 ? contact_details[contact_to]['records'][0]['Name'] : null;
                                                                                                            attendy_email = contact_details[contact_to] !== undefined && contact_details[contact_to]['totalSize'] > 0 ? contact_details[contact_to]['records'][0]['Email'] : null;
                                                                                                            // if( contact_to == userid )
                                                                                                            // {
                                                                                                            return <div className="order_details_block" key={program_name}>
                                                                                                                <ListGroup horizontal as="ul" className="align-items-center justify-content-between">
                                                                                                                    <ListGroup.Item as="li">
                                                                                                                        <div className="order_user_dietary_block">
                                                                                                                            <p className="order_user_info"><Link to={'/product/' + program_slug}>{program_name}</Link> <br /> {attendy_name} {attendy_email} </p>
                                                                                                                            <p className="dietary_requirement">Dietary requirement: <span className="text-uppercase">{Dietary_Requirement !== null ? Dietary_Requirement : 'no'}</span></p>
                                                                                                                        </div>
                                                                                                                    </ListGroup.Item>
                                                                                                                    <ListGroup.Item as="li">{Registration_Date}</ListGroup.Item>
                                                                                                                    <ListGroup.Item as="li">{recordTypeName}</ListGroup.Item>
                                                                                                                    <ListGroup.Item as="li">${parseFloat(program_fee_amount).toFixed(2)}</ListGroup.Item>
                                                                                                                </ListGroup>
                                                                                                            </div>
                                                                                                            // }
                                                                                                        })
                                                                                                        : null
                                                                                                }

                                                                                            </Fragment>
                                                                                        )
                                                                                    })

                                                                                    : null
                                                                            }
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                }
                                                            })
                                                            : loading ? <div className="pre_loader text-center"><LoaderIcon /></div> : <div className='not_found bg-white text-primary rounded text-center p-3 border'><h6 className='h3 mb-0'>No record found</h6></div>
                                                    }
                                                </Accordion>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            {
                                pagination_customize !== undefined && pagination_customize.length > 0 && loading === false && total_pages > 1
                                    ?
                                    <Pagination className="justify-content-center flex-wrap">
                                        {current_page > 1 ? <Pagination.Prev onClick={() => set_current_page(parseFloat(current_page) - parseFloat(1))} /> : null}
                                        {pagination_customize.map((page_number) => {
                                            return <Pagination.Item className={page_number == current_page ? 'active' : null} onClick={() => set_current_page(page_number)}>{page_number}</Pagination.Item>
                                        })}
                                        {current_page < total_pages ? <Pagination.Next onClick={() => set_current_page(parseFloat(current_page) + parseFloat(1))} /> : null}
                                    </Pagination>
                                    : null
                            }

                        </div >
                        : <div className="text-center">
                            {
                                error != ''
                                    ?
                                    <h3 className="text-center">
                                        {
                                            error == 'user not found.' ?
                                                <p>There are no data found with your account. <a href='mailto:registration@leocussen.edu.au' className="btn-link">Get in touch</a></p>
                                                : 'Please login to access My profile section.'
                                        }
                                    </h3>
                                    : null
                            }
                            {
                                error == ''
                                    ?
                                    <>
                                        <h3>Please Login</h3>
                                        <a onClick={() => My_Account_button_click()} className="btn btn-primary rounded-pill m-0 stest"> Login</a>
                                    </>
                                    : null
                            }
                        </div>
                }

            </Container >
            <>
                <Modal show={show} size="lg" onHide={handleClose} className="need_help_modal">
                    <Modal.Header closeButton className="border-0">
                    </Modal.Header>
                    <Modal.Body>
                        <Modal.Title as="h2" className="h1 text-center">How We Can Help ?</Modal.Title>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" rows={3} value={helpText} onChange={(e) => setHelpText(e.target.value)} />
                            {help_text_error != '' ? <p className="text-danger">{help_text_error}</p> : ''}
                        </Form.Group>
                        <Form.Group controlId="Checkbox21">
                            <Form.Check className="d-flex align-items-center" type="checkbox" onChange={(e) => setHelpCheckbox(e.target.checked)} label="I have read all terms & conditions" checked={helpCheckbox} htmlFor="Checkbox21" required />
                            {help_checkbox_error != '' ? <p className="text-danger">{help_checkbox_error}</p> : ''}
                        </Form.Group>
                        <Button type="button" className="btn-lg border-2 rounded-2" onClick={() => needHelpSubmit()}>Submit</Button>
                        <br />{form_submitted === true ? <Alert className="mt-3" variant="success">Thank you for your message.  The Registrations Team will attend to your query as soon as possible.</Alert> : null}
                    </Modal.Body>
                </Modal>
            </>
            <>
                <Modal show={update} size="lg" onHide={setUpdatehandleClose} className="update_order_modal">
                    <Modal.Header closeButton className="border-0">
                    </Modal.Header>
                    <Modal.Body>
                        <Modal.Title as="h2" className="h1 text-center">Update Your Order</Modal.Title>
                        <div className="update_order_data">
                            <Table>
                                <tbody>
                                    <tr>
                                        <th>Event</th>
                                        <td className="text-secondary">Alert! Owners Corporation Act Update 2021 – Legislative reforms and Case Update!</td>
                                    </tr>
                                    <tr>
                                        <th>Atendee</th>
                                        <td>John Smith</td>
                                    </tr>
                                    <tr>
                                        <th>Date</th>
                                        <td>23/05/2021</td>
                                    </tr>
                                    <tr>
                                        <th>Event Type</th>
                                        <td>Seminar</td>
                                    </tr>
                                    <tr>
                                        <th>Price (Inc) GST</th>
                                        <td>$300</td>
                                    </tr>
                                    <tr>
                                        <th>Dietary requirement </th>
                                        <td>None</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <Form>
                            <Form.Group className="form-group">
                                <Form.Label>How we can help?</Form.Label>
                                <Form.Select className="form-control">
                                    <option value="Refund / Reassign">Refund / Reassign</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </Form.Select>
                            </Form.Group>
                            <div className="information_block card border-exlight">
                                <div className="information_content">
                                    <InformationIcon />
                                    <p className="small mb-0">*all cancellation or program transfer requests are subject to approval</p>
                                </div>
                            </div>
                            <Form.Group className="mb-3" controlId="updateAccountMessage">
                                <Form.Label>Message</Form.Label>
                                <Form.Control as="textarea" rows={3} />
                            </Form.Group>
                            <Form.Group controlId="updateAccountcheckbox">
                                <Form.Check type="checkbox" label="I have read all terms & conditions" htmlFor="updateAccountcheckbox" required />
                            </Form.Group>
                            <Button type="submit" className="btn-lg border-2 rounded-2">Submit</Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </>
        </div >
    )
}

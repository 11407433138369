import React from "react";

const SVG = ({
  style = {},
  fill = "none",
  stroke="#004e6d",
  width = "20.941",
  height = "17.411",
  className = "",
  viewBox = "0 0 10.941 15.411"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
    fill={fill} stroke={stroke}
    d="M12.371,3A4.867,4.867,0,0,0,7.5,7.871c0,3.653,4.871,9.046,4.871,9.046s4.871-5.393,4.871-9.046A4.867,4.867,0,0,0,12.371,3Zm0,6.61a1.74,1.74,0,1,1,1.74-1.74A1.74,1.74,0,0,1,12.371,9.61Z" transform="translate(-6.9 -2.4)" strokeWidth="1.2"/>
    </svg>
);

export default SVG;

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import pm from '../../../images/tag-pm.png';
import ps from '../../../images/tag-ps.png';
import sl from '../../../images/tag-sl.png';
import ep from '../../../images/tag-ep.png';
import user from '../../../images/user-ic.png';
import laptop from '../../../images/laptop-ic.png';
import calendar from '../../../images/calendar-ic.png';
import watch from '../../../images/watch-ic.png';
export const ProductGridNew = ({ product, AddtoCart, Program_structure_images, is_ten_minite_less, program_date_is_gone_or_not,  GAurl }) => {
    const [metaData, set_metaData] = useState({});

    useEffect(() => {
        product.metafields.forEach((meta_val, meta_key) => {
            if (meta_val.key == 'all_data') {
                set_metaData(JSON.parse(meta_val.value));
            }
        })
    }, []);






    const [substantive_law, setSubstantive_law] = useState(metaData.Substantive_Law__c != undefined ? metaData.Substantive_Law__c : 0);
    const [professional_skills, setProfessional_skills] = useState(metaData.Professional_Skills__c != undefined ? metaData.Professional_Skills__c : 0);
    const [practise_managment_skill, setPractise_managment_skill] = useState(metaData.Practice_Management_Business_Skills__c != undefined ? metaData.Practice_Management_Business_Skills__c : 0);
    const [ethics_professional_responsibi, setEthics_professional_responsibi] = useState(metaData.Ethics_Professional_Responsibility__c != undefined ? metaData.Ethics_Professional_Responsibility__c : 0);
    const [totalCpdPoints, setTotalCpdPoints] = useState(substantive_law + professional_skills + practise_managment_skill + ethics_professional_responsibi);
    const [venue, setVenue] = useState('');
    const [DeliveryMode, setDeliveryMode] = useState([]);
    const [longPromotionMessage, setLongPromotionMessage] = useState('');
    const [shortPromotionMessage, setShortPromotionMessage] = useState('');
    const [program_date_time_line_2, setProgram_date_time_line_2] = useState('');
    const [program_date_time_line_1, setProgram_date_time_line_1] = useState('');
    const [reached_capacity, set_reached_capacity] = useState(false);
    const [Program_Structure__c, setProgram_Structure__c] = useState(undefined);
    const [Early_Bird_Price__c, setEarly_Bird_Price__c] = useState(undefined);

    const [DefaultRadioValue, setDefaultRadioValue] = useState("");

    const [DefaultRadio, setDefaultRadio] = useState(0);


    const [sDate, setsDate] = useState(null);

    const [presentor, setpresentor] = useState([])

    const [RecordType, setRecordType] = useState("")

    const UpdateRadio = (value, index) => {

        setDefaultRadioValue(value);
        setDefaultRadio(index);
    }


    const [TEN_MIntLeft, setTEN_MIntLeft] = useState(false);
    const [isProgramOver, setisProgramOver] = useState(false);


    const [Program_structure_image, setProgram_structure_image] = useState(['https://engine.leocussen.edu.au/sync/img/logo.png']);


    const isNotEmpty = (value) => {
        return value !== null && value !== undefined;
    }

    useEffect(() => {
        setSubstantive_law(metaData.Substantive_Law__c !== undefined ? metaData.Substantive_Law__c : 0);
        setProfessional_skills(metaData.Professional_Skills__c !== undefined ? metaData.Professional_Skills__c : 0);
        setPractise_managment_skill(metaData.Practice_Management_Business_Skills__c !== undefined ? metaData.Practice_Management_Business_Skills__c : 0);
        setEthics_professional_responsibi(metaData.Ethics_Professional_Responsibility__c !== undefined ? metaData.Ethics_Professional_Responsibility__c : 0);
        setTotalCpdPoints(substantive_law + professional_skills + practise_managment_skill + ethics_professional_responsibi);
        setProgram_date_time_line_2(metaData.Program_Date_Time_Line_2__c !== undefined && metaData.Program_Date_Time_Line_2__c !== null ? metaData.Program_Date_Time_Line_2__c : "");
        setProgram_date_time_line_1(metaData.Program_Date_Time_Line_1__c !== undefined && metaData.Program_Date_Time_Line_1__c !== null ? metaData.Program_Date_Time_Line_1__c : "");


        setVenue(metaData.Venue__c !== undefined ? metaData.Venue__c : "");
        setDeliveryMode(metaData.delivery_mode !== undefined ? metaData.delivery_mode : []);

        setLongPromotionMessage(metaData.Long_Promotion_Message__c !== undefined ? metaData.Long_Promotion_Message__c : '');
        setShortPromotionMessage(metaData.Short_Promotion_Message__c !== undefined ? metaData.Short_Promotion_Message__c : '');
        setProgram_Structure__c(isNotEmpty(metaData.Program_Structure__c) ? metaData.Program_Structure__c : undefined);


        set_reached_capacity(metaData.Reached_Capacity__c !== undefined ? metaData.Reached_Capacity__c : false);
        setEarly_Bird_Price__c(isNotEmpty(metaData.Compare_Price) ? metaData.Compare_Price : undefined);


        setsDate(metaData.start_date_time !== undefined && metaData.start_date_time !== null ? metaData.start_date_time : null);
        setRecordType(metaData.RecordType !== undefined ? metaData.RecordType : "");
        setpresentor(metaData.presentor !== undefined ? metaData.presentor : []);


        Program_structure_images.filter(function (item, value) {
            var keyname = Object.keys(item);
            var tag_array = product.tags;
            if (tag_array.includes(keyname.toString())) {
                setProgram_structure_image(Object.values(item));
            }
        })

    }, [metaData])



    let author_index = 0;
    let top_author_index = 0;
    const randm = () => {
        return (Math.random() + 1).toString(36).substring(7);
    }



    useEffect(() => {
        if (sDate !== undefined && sDate !== null && sDate !== "") {
            setTEN_MIntLeft(is_ten_minite_less(sDate));
            setisProgramOver(program_date_is_gone_or_not(sDate));
            // console.log('program_date_is_gone_or_not -> '+sDate+' -> '+program_date_is_gone_or_not(sDate));
        }
    }, [sDate])

    useEffect(() => {
        setTotalCpdPoints(parseFloat(substantive_law) + parseFloat(professional_skills) + parseFloat(practise_managment_skill) + parseFloat(ethics_professional_responsibi));
    }, [substantive_law, professional_skills, practise_managment_skill, ethics_professional_responsibi])



    useEffect(() => {
        if (DeliveryMode.length) {
            const dfvalue = DeliveryMode[0];
            UpdateRadio(dfvalue == "" ? '' : dfvalue, 0);
          //  console.log(DeliveryMode);
        }

    }, [DeliveryMode])


    const price = product.priceRange.minVariantPrice.amount;
    const price_float = parseFloat(price);
    const numberWithCommas = (x) => {
        return  parseFloat(x).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    }
    const dateFormat_sDate = new Date(sDate);
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    return (
        <div className='product_grid_block a'>
            <div className='product_grid_block_top'>
                <div className='product_grid_block_title'>
                <Link to={`/product/${product.handle+GAurl}`}> <h2 className='h3'>{product.title}</h2></Link>
                    {sDate !== null && sDate !== undefined ? <p>({months[dateFormat_sDate.getMonth()]} {dateFormat_sDate.getFullYear()})</p> : null}
                </div>
                <div className='product_grid_block_icons' key="lodidi">
                    {DeliveryMode.length ?
                        <>
                            {DeliveryMode.map((value, index) => {
                                return <> {value === "Live Stream"
                                    ? <img key={'check'+index} src={laptop} alt={value} className={index == DefaultRadio ? 'selected' : ''} onClick={() => UpdateRadio(value, index)} />
                                    : <img  key={'check'+index} src={user} alt={value} className={index == DefaultRadio ? 'selected' : ''} onClick={() => UpdateRadio(value, index)} />}</>
                            })}

                        </> : null}
                </div>
            </div>{/*product_grid_block_top */}
            <div className='product_grid_block_bottom'>
                <div className='product_grid_block_tags'>
                    {practise_managment_skill > 0 ? <img src={pm} alt="Practice Management & Business Skills" title="Practice Management & Business Skills" /> : null}
                    {professional_skills > 0 ? <img src={ps} alt="Professional Skills" title="Professional Skills" /> : null}
                    {substantive_law > 0 ? <img src={sl} alt="Substantive Law" title="Substantive Law" /> : null}
                    {ethics_professional_responsibi > 0 ? <img src={ep} alt="Ethics & Professional Responsibility" title="Ethics & Professional Responsibility" /> : null}
                </div>
                <div className='product_grid_block_titmings'>
                    {
                        program_date_time_line_1 !== '' && program_date_time_line_1 !== undefined ?
                            (
                                <div className='inside_block' key={program_date_time_line_1}>
                                    <div className='icon'><img src={calendar} alt='calendar' /></div>
                                    <div className='content'>{program_date_time_line_1}</div>
                                </div>
                            )
                            : ''
                    }
                    {
                        program_date_time_line_2 !== '' && program_date_time_line_2 !== undefined ?
                            (
                                <div className='inside_block' key={program_date_time_line_2}>
                                    <div className='icon'><img src={watch} alt='watch' /></div>
                                    <div className='content'>{program_date_time_line_2}</div>
                                </div>
                            )
                            : ''
                    }

                </div>
                <div className='product_grid_block_price'>
                    <h3><span className="discount_price lund">{Early_Bird_Price__c !== undefined ? '$' + numberWithCommas(Early_Bird_Price__c) : null}</span>${numberWithCommas(price_float)}</h3>
                    {/*<h4 className='text-decoration-line-through'>$299.00</h4>*/}
                </div>
                <div className='product_grid_block_btns'>
                    {!TEN_MIntLeft ?
                        isProgramOver ? <div className="label_tag">Bookings Closed</div> :
                            reached_capacity
                                ? <div className="label_tag">Fully Booked</div>
                                : <>

                                    <a href='#' className='btn btn-secondary' onClick={(e) => AddtoCart(product.variants.edges[0].node.id, 1, product.variants.edges[0].node.sku, DefaultRadioValue, RecordType, product.tags, Program_structure_image, product.handle, sDate,Program_Structure__c)}>Add to cart</a>
                                </>
                        : <div className="label_tag">Bookings Closed</div>
                    }
                    <Link className="btn-link" to={`/product/${product.handle+GAurl}`}>Learn more</Link>
                </div>
            </div>
        </div>
    )
}

export default ProductGridNew;

import React from 'react';
import { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Form,
    Button
} from 'react-bootstrap';
import ProductGridNew2 from '../ProductListing/ProductGrid/ProductGridNew2';

import { LoaderIcon } from "../../icons";






export const AreasSection = (props) => {
    //console.log(props.selectedProgram);

    const [practice_areas_result, set_practice_areas_result] = useState([]);
    const practice_areas = [
        "Advocacy",
        "Building & Construction",
        "Business & Contracts",
        "Consumer",
        "Corporate & In-House Counsel",
        "Costs",
        "Criminal",
        "Employment & Workplace Relations",
        "Ethics",
        "Family",
        "Finance, Tax, Superannuation",
        "Government, Privacy & FOI",
        "Health & Aged Care",
        "Insolvency & Restructuring",
        "Intellectual Property",
        "Litigation & Dispute Resolution",
        "Migration",
        "Personal Injury & Medico-Legal",
        "Planning & Environment",
        "Professional Skills & Practice Management",
        "Property & Leases",
        "Sports Law",
        "Trusts & Succession Planning",
        "Wills & Estates"];
    const load_per_page = 12;
    const [total_products, set_total_products] = useState(0);
    const [products, set_products] = useState([]);
    const [page, set_page] = useState(1);
    const [practice_courses, set_practice_courses] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [selectedPractise, setPractise] = useState([]);


    const getAllWishlist = (localStorage.getItem('wishlist_item') !== undefined && localStorage.getItem('wishlist_item') !== 'undefined' && localStorage.getItem('wishlist_item') !== null) ? JSON.parse(localStorage.getItem('wishlist_item')) : []
    /***
     * Remove special charachter
     */
    const RemoveSpecialChar = (StringText) => {
        if (StringText !== null && StringText !== undefined) {
            return StringText.toLowerCase().split("&").join("and").split(",").join(" ").split(" ").join("-");
        }
    }
    let temp = [];
    props.selectedProgram.map((val) => {
        if (val !== "") {
            temp.push(RemoveSpecialChar(val));
        }
    })
    const [selected_practice_areas, set_selected_practice_areas] = useState(temp);
    /***
 * Is filter Tag checked
 */

    const isTagCHecked = (TagValue) => {
        if (Does_Tag_isPracticeArea(TagValue).length > 0 && selected_practice_areas !== undefined) {
            return selected_practice_areas.includes(RemoveSpecialChar(TagValue));
        }

    }

    const Does_Tag_isPracticeArea = (PracticeValue) => {
        return practice_areas.filter((val) => {
            return val === PracticeValue;
        });
    }

    const flatten = (arr) => {
        return arr.reduce(function (flat, toFlatten) {
            return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
        }, []);
    }

    /****
     * All filter list
     */
    const AllFilterList = (RequestTag) => {
        var ArraysTemp = [
            [practice_areas]
        ];
        return flatten(ArraysTemp).includes(RequestTag) && isTagCHecked(RequestTag);

    }
    /***
       * Compare array value
       */
    function intersect_arrays(a, b) {
        var sorted_a = a.concat().sort();
        var sorted_b = b.concat().sort();
        var common = [];
        var a_i = 0;
        var b_i = 0;

        while (a_i < a.length
            && b_i < b.length) {
            if (sorted_a[a_i] === sorted_b[b_i]) {
                common.push(sorted_a[a_i]);
                a_i++;
                b_i++;
            }
            else if (sorted_a[a_i] < sorted_b[b_i]) {
                a_i++;
            }
            else {
                b_i++;
            }
        }
        return common;
    }
    /****
     * Fillter 3 dot toogle
     */
    const [dotToogle, setdotToogle] = useState(false);

    const handleClick = () => {
        setLoading(true);
        setTimeout(function () {
            set_page(page + 1);
            if (selected_practice_areas !== undefined && selected_practice_areas.length > 0) {
                set_practice_courses(practice_areas_result.slice(0, (load_per_page * (page + 1))));
            }
            else {
                set_practice_courses(props.products.slice(0, (load_per_page * (page + 1))));
            }
            setLoading(false);
        }, 1000);
    }

    useEffect(() => {
        if (props.products !== undefined && props.products !== undefined) {
            set_products(props.products);
            // set_total_products(props.products.length);
            // set_practice_courses(props.products.slice(0, 4));
        }
        // console.log('props',props);
    }, [props]);

    function LoadingButton() {


        return (
            <Button
                variant="primary"
                disabled={isLoading}
                onClick={!isLoading ? handleClick : null}
                key="lol"
            >
                {isLoading ? 'Loading…' : 'Show more'}
            </Button>
        );
    }

   

    function practice_areas_updated(val) {
        // console.log('updated',val);
        if (!selected_practice_areas.includes(val)) {
            set_selected_practice_areas([...selected_practice_areas, val]);

        }
        else {
            let arr = selected_practice_areas.filter(function (item) {
                // console.log('item',item);
                return item !== val;
            });

            set_selected_practice_areas(arr);

        }
    }
    function practice_areas_search() {
        let new_selected_practice_areas = [];
       // console.log('selected_practice_areas lenth -> ' + selected_practice_areas.length);
       // console.log(selected_practice_areas);

        if (selected_practice_areas.length > 0) {
            props.products.forEach(function (val, key) {
                let product = val[1];
                product.metafields.forEach((meta_val, meta_key) => {
                    if (meta_val.key === 'all_data') {
                        let product_data = JSON.parse(meta_val.value);
                        let practice_areas = product_data.Practising_Areas__c;
                       // console.log("practice_areas ", practice_areas);
                        let practice_areas_array = [];
                        if (practice_areas !== undefined && practice_areas !== null && practice_areas != '') {
                            practice_areas_array = practice_areas;
                            //console.log(selected_practice_areas.join(';').replace('/&/g', 'and').replace(',', '+').split(';'));
                            //  console.log(practice_areas_array);
                            //  console.log("----------------------------------------------------");
                            var lower_program = practice_areas_array.split(";").map(v => RemoveSpecialChar(v));
                            var selectedPractise_user = selected_practice_areas[0].split("|").map(v => RemoveSpecialChar(v));
                            if (intersect_arrays(lower_program, selectedPractise_user).length !== 0) {


                                // console.log('found',meta_val);
                                new_selected_practice_areas = [...new_selected_practice_areas, val];
                            }

                        }
                    }
                })
            });
            // console.log('new_selected_practice_areas', new_selected_practice_areas);
            set_practice_areas_result(new_selected_practice_areas);
            set_practice_courses(new_selected_practice_areas.slice(0, 4));
            set_total_products(new_selected_practice_areas.length);
            set_page(1);
        }
        else {
            set_products(props.products);
            set_total_products(props.products.length);
            set_practice_courses(props.products.slice(0, 4));
        }

    }
    useEffect(() => {
        // console.log('selected_practice_areas',selected_practice_areas);
        if (props.products !== undefined) {

            practice_areas_search();
        }
    }, [selected_practice_areas]);

    useEffect(() => {
        // console.log('selected_practice_areas',selected_practice_areas);
        if (props.products !== undefined) {
            //  console.log("practice_areas_search");
            practice_areas_search();
        }
    }, []);

    //  console.log("total_products->" + total_products);

    return (
        <div className='areas_wrapper'>
            <Container>
                {
                    practice_areas !== undefined && practice_areas.length > 0 ?
                        <>
                            <div className='title_wrap text-center' key="500">
                                <h2 className='text-primary'>Top Practice Areas</h2>
                            </div>
                            <div className='fillter_wrapper'>
                                <div className='filter_list_wrapper' style={dotToogle ? { "height": 'auto' } : {}}>
                                    {/* <label className='filter_title'>Filter by:</label> */}
                                    {practice_areas.map((TagName, key) => {
                                        return <div className='filter_list' key={key + 'ss'}>
                                            <div className='form-check'>
                                                <div className={AllFilterList(TagName) ? 'form-check-label active' : 'form-check-label'}
                                                    id={RemoveSpecialChar(TagName)}
                                                    onClick={() => practice_areas_updated(RemoveSpecialChar(TagName))} >
                                                    {TagName}
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                    {!dotToogle ? <div className='filter_list_more' onClick={() => setdotToogle(true)}>...</div> : null}
                                </div>
                            </div>
                        </> : null
                }
                <Row>
                    {
                        practice_courses === undefined || practice_courses.length === 0 ?

                            <div className="pre_loader l2 mt-5"><LoaderIcon /></div> :


                            (
                                practice_courses.map(function (single_product, index) {
                                    // console.log('single_product',single_product);
                                    let product_data = Object.values(single_product)[1];
                                    let product_title = product_data.title;
                                    let product_slug = product_data.handle;
                                    let priceRange = product_data.priceRange;
                                    let product_final_data = undefined;
                                    product_data.metafields.forEach((meta_val, meta_key) => {
                                        if (meta_val.key == 'all_data') {
                                            product_final_data = JSON.parse(meta_val.value)
                                        }
                                    })
                                    return <Col lg={3} md={6} xs={12} key={index}>
                                        <ProductGridNew2 key={index + 'poi'} program_date_is_gone_or_not={props.program_date_is_gone_or_not} is_ten_minite_less={props.is_ten_minite_less} Program_structure_images={props.Program_structure_images} getAllWishlist={getAllWishlist} product={single_product[1]} AddtoCart={props.AddtoCart} priceRange={priceRange} product_slug={product_slug} product_title={product_title} product_data={product_final_data} GAurl={props.GAurl} />
                                    </Col>
                                })
                            )
                    }

                </Row>
                {
                    (products == undefined || total_products == 0) || (practice_courses.length == total_products) ?
                        ''
                        :
                        (<div className='btn_wrap text-center'>
                            <div className='text'>Showing {practice_courses.length} of {total_products} Courses</div>
                            <LoadingButton />
                        </div>)
                }
            </Container>
        </div>
    )
}

export default AreasSection;

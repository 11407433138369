import React, { useState, useEffect } from "react";
import Select from "react-select";

import { ProductListing } from "./ProductListing";
import {
  ArrowUpIcon,
  ArrowDownIcon,
  LoaderIcon,
  SearchIconNew,
} from "../../icons";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  FormControl,
  Button,
  Pagination,
  InputGroup,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import queryString, { parse } from "query-string";
import { useHistory } from "react-router-dom";
import axios from "axios";
import CloseIcon from "../../images/close-ic.png";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { Hint } from "react-autocomplete-hint";
import BlueBg from "../../images/blue-banner-img.png";
import { Paginator } from "../Sections/Paginator";

export const ProductPage = (props) => {
  const location = useLocation();
  let history = useHistory();
  const queryParam = queryString.parse(location.search);
  const PathName = location.pathname;
  const productHandle =
    PathName.substring(PathName.lastIndexOf("/") + 1) !== ""
      ? PathName.substring(PathName.lastIndexOf("/") + 1)
      : "cpd-programs";
  let tagfilter = "";
  let lastSlug = undefined;

  const [prodcutSlug, setprodcutSlug] = useState(productHandle);
  const [seo_title, set_seo_title] = useState("");
  const [seo_description, set_seo_description] = useState("");
  const [CurrentMenuTitle, setCurrentMenuTitle] = useState("");
  const [isDateFilterNeeded, setisDateFilterNeeded] = useState("");
  const [Date_Filters_On__c, setDate_Filters_On__c] = useState("");
  const [LengthFilters, setLengthFilters] = useState("");
  const [ProgramType, setProgramType] = useState([]);
  const [PracticeArea, setPracticeArea] = useState([]);
  const [CPDCategories, setCPDCategories] = useState([]);
  const [DeliverMode, setDeliveryMode] = useState([]);
  const [formatType, setformatType] = useState([]);
  const [ProgramStructure, setProgramStructure] = useState([]);
  const [Topics, setTopics] = useState([]);
  const [isPromotion_show, setPromotion_show] = useState(false);
  const localizer = momentLocalizer(moment);
  const [LayoutView, setLayoutView] = useState("List view");
  const [VisibleFilterLayout, setVisibleFilterLayout] = useState(true);
  const [expandAll, setExpandAll] = useState(true);

  /***
   * Remove special charachter
   */
  const RemoveSpecialChar = (StringText) => {
    if (StringText !== null && StringText !== undefined) {
      return StringText.toLowerCase()
        .split("&")
        .join("and")
        .split(",")
        .join(" ")
        .split(" ")
        .join("-");
    }
  };

  let events = [];
  let FilterProductsID = [];
  tagfilter = "tag:" + productHandle + "-menu";

  useEffect(() => {
    if (props.ProductPageJson.length > 0) {
      setCurrentMenuTitle(() => {
        let mydata = "";
        props.ProductPageJson.map((menu) => {
          if (menu.Name.split(" ").join("-").toLowerCase() == productHandle) {
            mydata = menu.Name;
          }
        });
        return mydata;
      });

      set_seo_title(() => {
        let mydata = "";
        props.ProductPageJson.map((menu) => {
          if (menu.Name.split(" ").join("-").toLowerCase() === productHandle) {
            mydata = menu.Name;
          }
        });
        return mydata;
      });

      set_seo_description(() => {
        let mydata = "";
        props.ProductPageJson.map((menu) => {
          if (
            menu.Name.split(" ").join("-").toLowerCase() === productHandle &&
            menu.SEO_Content !== null
          ) {
            mydata = menu.SEO_Content.replace(/<[^>]*>?/gm, "");
          }
        });
        return mydata;
      });

      setisDateFilterNeeded(() => {
        let mydata = "";
        props.ProductPageJson.map((menu) => {
          if (menu.Name.split(" ").join("-").toLowerCase() === productHandle) {
            mydata = menu.Order_By;
          }
        });
        return mydata;
      });

      setDate_Filters_On__c(() => {
        let mydata = "";
        props.ProductPageJson.map((menu) => {
          if (menu.Name.split(" ").join("-").toLowerCase() === productHandle) {
            mydata = menu.Date_Filters_On__c;
          }
        });
        return mydata;
      });

      /***
       * Check filter length
       */

      setLengthFilters(() => {
        let mydata = "";
        props.ProductPageJson.map((menu) => {
          if (menu.Name.split(" ").join("-").toLowerCase() === productHandle) {
            mydata = menu.Filters.length;
          }
        });
        return mydata;
      });

      /***
       * Set promotation
       */
      setPromotion_show(() => {
        let mydata = false;
        props.ProductPageJson.map((menu) => {
          if (
            menu.Name.split(" ").join("-").toLowerCase() === productHandle &&
            menu.Filters.includes("Promotions")
          ) {
            mydata = true;
          }
        });
        return mydata;
      });

      /***
       * Topic list
       */
      setTopics(() => {
        return [
          "Administrative Law",
          "Civil Litigation",
          "Commercial and Corporate Practice",
          "Employment and Industrial Relations",
          //  "Ethics and Professional Responsibility",
          "Lawyer’s Skills including Advocacy",
          "Problem Solving",
          "Property Law",
          "Risk Management",
          "Trust and Office Accounting",
          "Work Management Business Skills (including Risk Management)",
        ];
      });

      /****
       * Program structure
       */

      setProgramStructure(() => {
        let mydata = [];
        props.ProductPageJson.map((menu) => {
          if (menu.Name.split(" ").join("-").toLowerCase() === productHandle) {
            menu.Page_Item__c.map((page_item) => {
              mydata.push(
                page_item.Program_Structure.split(" ").join("-").toLowerCase()
              );
            });
          }
        });
        return mydata;
      });

      /***
       * Types of format
       */
      setformatType(() => {
        return ["Pre-Recorded Webcasts", "10 CPD Point Packages", "Books"];
      });

      /***
       * Delivery Mode
       */
      setDeliveryMode(() => {
        return ["Face to Face", "Live Stream"];
      });

      /**
       * CPD Categories
       */
      setCPDCategories(() => {
        return [
          "Ethics & Professional Responsibility",
          "Professional Skills",
          "Practice Management & Business Skills",
          "Substantive Law",
        ];
      });

      /***
       * Practice Area
       */
      fetchDataPracticeArea();

      /***
       * Program Type
       */
      setProgramType(() => {
        return [
          "Live Streamed One Hour Seminars",
          "Live Streamed Conferences and Intensives",
          "Live Streamed Practical Workshops",
          "Onsite Programs",
          "Pre-Recorded Webcasts",
        ];
      });
    }
  }, [props.ProductPageJson]);

  const eventStyleGetter = (event, start, end, isSelected) => {
    const customClass = event.customClass;
    return {
      className: customClass,
    };
  };

  /****
   * Update calendar event list
   * Phase 2
   */
  const CustomToolbar = ({ label, onNavigate, view, onView }) => {
    const navigate = (action) => {
      onNavigate(action);
    };

    const goToBack = () => {
      // Previous month, week, day, or agenda range
      onNavigate("PREV");
    };

    const goToNext = () => {
      // Next month, week, day, or agenda range
      onNavigate("NEXT");
    };

    const goToCurrent = () => {
      // Current date
      onNavigate("TODAY");
    };

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      let month = "" + (date.getMonth() + 1), // getMonth() returns month from 0-11
        day = "" + date.getDate(),
        year = date.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [day, month, year].join("/");
    };

    const reformatDateRange = (dateRange) => {
      const dates = dateRange.split(" – ");
      if (dates.length === 2) {
        const startFormatted = formatDate(dates[0]);
        const endFormatted = formatDate(dates[1]);
        return startFormatted + " – " + endFormatted;
      }
      return "";
    };

    const formatLabel = () => {
      // Custom label formatting can go here
      if (view === "agenda") {
        // Customize the agenda view label format
        return reformatDateRange(label);
      }
      // Default label for other views
      return label;
    };

    return (
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          <button onClick={goToCurrent} type="button">
            Today
          </button>
          <button onClick={goToBack} type="button">
            Back
          </button>
          <button onClick={goToNext} type="button">
            Next
          </button>
        </span>
        <span className="rbc-toolbar-label">{formatLabel()}</span>
        <span className="rbc-btn-group">
          <button
            onClick={() => onView("month")}
            type="button"
            className={view === "month" ? "rbc-active" : ""}
          >
            Month
          </button>
          <button
            onClick={() => onView("week")}
            type="button"
            className={view === "week" ? "rbc-active" : ""}
          >
            Week
          </button>
          <button
            onClick={() => onView("day")}
            type="button"
            className={view === "day" ? "rbc-active" : ""}
          >
            Day
          </button>
          <button
            onClick={() => onView("agenda")}
            type="button"
            className={view === "agenda" ? "rbc-active" : ""}
          >
            Agenda
          </button>
        </span>
      </div>
    );
  };

  const UpdateCalendarEvent = (myEvents) => {
    var event_list = [];
    var products_id = [];
    for (var s = 0; s < myEvents.length; s++) {
      let ppp = Object.values(myEvents[s]);
      for (var edge = 0; edge < ppp[1].metafields.length; edge++) {
        var metadata = ppp[1].metafields[edge];
        if (metadata.key == "all_data") {
          var prodcutJson = JSON.parse(metadata.value);
          if (
            prodcutJson.start_date !== undefined &&
            prodcutJson.end_date !== undefined &&
            prodcutJson.start_date !== null &&
            prodcutJson.end_date !== null
          ) {
            event_list.push({
              start: new Date(prodcutJson.start_date_time),
              end: new Date(prodcutJson.end_date_time),
              url: props.storeURL + "product/" + ppp[1].handle,
              title: ppp[1].title,
              customClass: program_date_is_gone_or_not(prodcutJson.start_date)
                ? "pgone"
                : "",
            });
          }
        }
      }
      products_id.push(ppp[1].handle);
    }
    events = event_list;
    FilterProductsID = products_id;
  };

  /***
   * Event click
   * Phase 2
   */
  const EventClick = (event) => {
    window.location.href = event.url;
  };

  /**
   * Fetch product data
   */
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [data, setData] = useState({});

  const fetchData = async (tags) => {
    setError(false);
    setLoading(true);
    try {
      const tag_url = tags !== "" ? "?tag=" + tags : "";
      const api_URL = props.EngineURL + "shopify-pings/getdata.php" + tag_url;
      const response = await axios(api_URL);

      /***
       * Assign data to State
       */
      if (response.status === 200) {
        var data = response.data;
        if (data.length !== 0) {
          var result = Object.keys(data).map((key) => [Number(key), data[key]]);

          setUserData_({ products: result });
          settotalProduct(result);
        } else {
          setUserData_({ products: [] });
          settotalProduct(0);
        }
        setLoading(false);
      }
    } catch (error) {
      setError(true);
    }
    setLoading(false);
  };

  const fetchDataPracticeArea = async () => {
    setPracticeArea([
      "Building & Construction",
      "Business & Contracts",
      "Consumer",
      "Corporate & In-House Counsel",
      "Costs",
      "Criminal",
      "Employment & Workplace Relations",
      "Ethics",
      "Family",
      "Finance, Tax, Superannuation",
      "Government, Privacy & FOI",
      "Insolvency & Restructuring",
      "Intellectual Property",
      "Legal Practice Fundamentals",
      "Litigation & Advocacy",
      "Migration",
      "Personal Injury & Medico-Legal",
      "Professional Skills & Practice Management",
      "Property, Leases & Planning",
      "Sports Law",
      "Wills, Trusts & Succession Planning",
    ]);
  };

  /***
   * WHen user click on Menu
   */
  useEffect(() => {
    tagfilter = productHandle + "-menu";
    if (prodcutSlug != undefined) {
      fetchData(tagfilter);
    }
  }, [prodcutSlug]);

  useEffect(() => {
    if (
      props.lastURL !== undefined &&
      props.lastURL !== location.search &&
      location.search === ""
    ) {
      ResetFilter_Data();
    }
    props.setlastURL(location.search);
  }, [location.search]);

  /***
   * Get value from query URL
   */
  const GAurl =
    queryParam["gclid"] !== undefined ? "?gclid=" + queryParam["gclid"] : "";

  const [selectedProgram, setProgram] = useState(() => {
    return queryParam["program-type"] !== undefined
      ? queryParam["program-type"].split(",")
      : [];
  });
  const [SortBy, setSortBy] = useState(() => {
    return queryParam["sort-by"] !== undefined
      ? queryParam["sort-by"]
      : "date-az";
  });
  const [selectedPractise, setPractise] = useState(() => {
    return queryParam["practice-area"] !== undefined
      ? queryParam["practice-area"].split(",")
      : [];
  });
  const [selectedCPD, setCPD] = useState(() => {
    return queryParam["cpd-categories"] !== undefined
      ? queryParam["cpd-categories"].split(",")
      : [];
  });

  const [selectedTopic, setTopic] = useState(() => {
    return queryParam["topic"] !== undefined
      ? queryParam["topic"].split(",")
      : [];
  });

  const [SearchText, setSearchText] = useState(() => {
    return queryParam["keyword"] !== undefined ? queryParam["keyword"] : "";
  });
  const [SearchTextTextField, setSearchTextTextField] = useState(() => {
    return queryParam["keyword"] !== undefined ? queryParam["keyword"] : "";
  });

  const [SearchText_, setSearchText_] = useState(() => {
    return queryParam["keyword"] !== undefined ? queryParam["keyword"] : "";
  });

  const [startDateFocus, setStartDateFocus] = useState(false);
  const [endDateFocus, setEndDateFocus] = useState(false);
  const [startDate, setStartDate] = useState(() => {
    return queryParam["start-date"] !== undefined
      ? queryParam["start-date"]
      : undefined;
  });
  const [endDate, setEndDate] = useState(() => {
    return queryParam["end-date"] !== undefined
      ? queryParam["end-date"]
      : undefined;
  });
  const [selectedFormat, setselectedFormat] = useState(() => {
    return queryParam["format"] !== undefined
      ? queryParam["format"].split(",")
      : [];
  });
  const [isPromotion, setPromotion] = useState(() => {
    return queryParam["ispromotion"] !== undefined
      ? queryParam["ispromotion"]
      : false;
  });
  const [selectedDelivery, setDelivery] = useState(() => {
    return queryParam["stream-mode"] !== undefined
      ? queryParam["stream-mode"].split(",")
      : [];
  });
  const [userData_, setUserData_] = useState(undefined);

  const ResetFilter_Data = () => {
    setProgram([]);
    setPractise([]);
    setTopic([]);
    setCPD([]);
    setSearchText_("");
    setSearchText("");
    setSearchTextTextField("");
    setStartDate(null);
    setEndDate(null);
    setselectedFormat([]);
    setPromotion(undefined);
    setPromotion(false);
    setDelivery([]);
    history.push(PathName);
  };

  const ResetFilter = (e) => {
    e.preventDefault();
    ResetFilter_Data();
  };

  /***
   * Pagignation Object
   */
  const [pageCount, setpageCount] = useState(0);
  const [totalProduct, settotalProduct] = useState(0);
  const [pageSize, setpageSize] = useState(15);
  const [currentPage, setcurrentPage] = useState(() => {
    return queryParam["page"] != undefined ? queryParam["page"] : 1;
  });
  const [PaginationRange, setPaginationRange] = useState(0);

  /***
   * Generate URL from user selection
   */
  function GenerateDomURLforFilter() {
    if (
      currentPage !== 1 ||
      selectedProgram.length ||
      selectedPractise !== undefined ||
      selectedCPD !== undefined ||
      isPromotion !== false ||
      SearchText_ !== "" ||
      selectedDelivery.length !== 0 ||
      (startDate !== null &&
        endDate !== null &&
        startDate !== undefined &&
        endDate !== undefined) ||
      selectedTopic.length ||
      selectedFormat.length ||
      SortBy !== "date-az"
    ) {
      var MakeURL = "?";
      if (
        selectedProgram !== undefined &&
        selectedProgram !== null &&
        selectedProgram.length
      ) {
        MakeURL +=
          "&program-type=" +
          selectedProgram.map((v) => RemoveSpecialChar(v)).join(",");
      }
      if (selectedPractise !== undefined && selectedPractise.length) {
        MakeURL +=
          "&practice-area=" +
          selectedPractise.map((v) => RemoveSpecialChar(v)).join(",");
      }
      if (selectedCPD !== undefined && selectedCPD.length) {
        MakeURL +=
          "&cpd-categories=" +
          selectedCPD.map((v) => RemoveSpecialChar(v)).join(",");
      }
      if (selectedDelivery !== undefined && selectedDelivery.length) {
        MakeURL +=
          "&stream-mode=" +
          selectedDelivery.map((v) => RemoveSpecialChar(v)).join(",");
      }
      if (selectedTopic !== undefined && selectedTopic.length) {
        MakeURL +=
          "&topic=" + selectedTopic.map((v) => RemoveSpecialChar(v)).join(",");
      }
      if (selectedFormat !== undefined && selectedFormat.length) {
        MakeURL +=
          "&format=" +
          selectedFormat.map((v) => RemoveSpecialChar(v)).join(",");
      }
      if (isPromotion !== false) {
        MakeURL += "&ispromotion=true";
      }

      if (SearchText_ !== "") {
        MakeURL += "&keyword=" + SearchText_;
      }

      if (
        startDate !== null &&
        endDate !== null &&
        startDate !== undefined &&
        endDate !== undefined
      ) {
        MakeURL += "&start-date=" + startDate + "&end-date=" + endDate;
      }

      if (currentPage !== 1) {
        MakeURL += "&page=" + currentPage;
      }

      if (SortBy !== "date-az") {
        MakeURL += "&sort-by=" + SortBy;
      }
      if (queryParam["gclid"] !== undefined) {
        MakeURL += "&gclid=" + queryParam["gclid"];
      }
      if (queryParam["utm_medium"] !== undefined) {
        MakeURL += "&utm_medium=" + queryParam["utm_medium"];
      }
      if (queryParam["utm_campaign"] !== undefined) {
        MakeURL += "&utm_campaign=" + queryParam["utm_campaign"];
      }
      if (queryParam["utm_content"] !== undefined) {
        MakeURL += "&utm_content=" + queryParam["utm_content"];
      }
      if (queryParam["utm_source"] !== undefined) {
        MakeURL += "&utm_source=" + queryParam["utm_source"];
      }
      if (queryParam["utm_term"] !== undefined) {
        MakeURL += "&utm_term=" + queryParam["utm_term"];
      }
      if (window.location.hash) {
        MakeURL += "" + window.location.hash;
      }

      return MakeURL;
    }
    return undefined;
  }

  // Initializing didMount as false
  const [didMount, setDidMount] = useState(false);

  // Setting didMount to true upon mounting
  useEffect(() => {
    setDidMount(true);
  }, []);

  /**
   * change current page when filter updated
   */
  useEffect(() => {
    if (didMount) {
      setcurrentPage(1);

      const newurl = GenerateDomURLforFilter();
      if (newurl !== undefined) {
        history.push(PathName + "" + newurl);
      }
    }
  }, [
    selectedProgram,
    selectedPractise,
    selectedCPD,
    isPromotion,
    selectedDelivery,
    startDate,
    endDate,
    selectedTopic,
    selectedFormat,
    SearchText_,
    SortBy,
  ]);

  useEffect(() => {
    const newurl = GenerateDomURLforFilter();
    if (newurl !== undefined) {
      history.push(PathName + "" + newurl);
    }
  }, [currentPage]);

  function getPrevPage() {
    return currentPage === 1 ? 1 : currentPage - 1;
  }

  function getNextPage() {
    return currentPage < PaginationRange ? currentPage + 1 : PaginationRange;
  }

  /**
   * WHnen prodcut has been filter recalculate total product
   */
  function handler(val) {
    settotalProduct(val);
    setPaginationRange(Math.ceil(val / pageSize));
  }

  /***
   * Add to cart & Checkout constant
   */
  const [InitialLoad, setInitialLoad] = useState(true);

  /***
   * Compare array value
   */
  function intersect_arrays(a, b) {
    var sorted_a = a.concat().sort();
    var sorted_b = b.concat().sort();
    var common = [];
    var a_i = 0;
    var b_i = 0;

    while (a_i < a.length && b_i < b.length) {
      if (sorted_a[a_i] === sorted_b[b_i]) {
        common.push(sorted_a[a_i]);
        a_i++;
        b_i++;
      } else if (sorted_a[a_i] < sorted_b[b_i]) {
        a_i++;
      } else {
        b_i++;
      }
    }
    return common;
  }

  function dateInPast(firstDate, secondDate) {
    if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
      return true;
    }

    return false;
  }

  /***
   * Check date between
   */
  function compare_date(dateFrom, dateTo, dateCheck) {
    //   console.log(dateFrom + " - " + dateTo + " - " + dateCheck);
    var d1 = dateFrom.split("-");
    var d2 = dateTo.split("-");
    var c = dateCheck.split("-");

    var from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
    var to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
    var check = new Date(c[0], parseInt(c[1]) - 1, c[2]);

    return from <= check && check <= to;
  }

  /***
   * Detect enter key
   */
  const HitEnter = (event) => {
    event.preventDefault();
    // console.log(SearchText);

    setSearchText_(SearchText);
  };

  const program_date_is_gone_or_not = (program_start_date_time) => {
    if (program_start_date_time === null) {
      return false;
    }
    program_start_date_time = new Date(program_start_date_time);
    let program_time_modify = program_start_date_time.setHours(
      program_start_date_time.getHours()
    );
    let current_time = new Date().getTime();
    if (parseFloat(program_time_modify) - parseFloat(current_time) > 0) {
      return false;
    } else {
      return true;
    }
  };

  const is_ten_minite_less = (program_start_date_time) => {
    if (
      program_start_date_time === undefined ||
      program_start_date_time === null ||
      program_start_date_time === ""
    ) {
      return false;
    }

    program_start_date_time = new Date(program_start_date_time);
    let program_time_modify = program_start_date_time.setHours(
      program_start_date_time.getHours()
    );
    let current_time_10 = new Date(new Date().getTime() + 1000 * 600);
    let current_time = new Date().getTime();

    var from = current_time; // -1 because months are from 0 to 11
    var to = current_time_10;
    var check = program_time_modify;

    return from <= check && check <= to;
  };

  /***
   * Search by title
   */
  function getArraysIntersection(a1, a2) {
    a1 = a1.filter(function (item) {
      return item !== "and";
    });
    return a1.filter(function (n) {
      return a2.indexOf(n) !== -1;
    });
  }

  /***
   * Filter value from main array
   */
  function mysearch(data) {
    console.log("length ->" + data.length);
    const filterRespones = data.filter(function (pppp) {
      let ppp = Object.values(pppp);

      var program_type = false;
      var practise_area = false;
      var cpd_categories = false;
      var check_promotion = false;
      var deliver_mode = false;
      var start_date = false;
      var end_date = false;
      var check_topic = false;
      var search_text = false;
      var defaaultt = false;
      var ispromotion = false;
      var topic = false;
      var hidefromlist = false;
      var datapassed = true;
      var format_ = false;
      var state_allowed = true;
      // alert(selectedTopic);
      //alert(selectedTopic);
      var CurrentDate = new Date();

      if (
        selectedFormat !== "" ||
        isPromotion === true ||
        isPromotion === "true" ||
        (startDate !== null && endDate !== null) ||
        SearchText_ !== "" ||
        selectedProgram !== undefined ||
        selectedPractise !== undefined ||
        selectedCPD !== undefined ||
        selectedDelivery.length ||
        selectedTopic.length
      ) {
        ppp[1].metafields.filter(function (metadata) {
          if (metadata.key === "all_data") {
            var prodcutJson = JSON.parse(metadata.value);

            /****
             * Old date
             */

            var is_program_gone = program_date_is_gone_or_not(
              prodcutJson.start_date_time
            );
            if (prodcutJson.start_date_time !== null && is_program_gone) {
              datapassed = false;
            }

            /* var past = new Date(prodcutJson.end_date);

                          if (prodcutJson.end_date != undefined && dateInPast(past, CurrentDate)) {
                              datapassed = false;
                          }*/

            /***
             * Hide from list
             */

            if (prodcutJson.Hide_from_list !== true) {
              hidefromlist = true;
            }

            /**
             * Program type
             */

            if (selectedProgram !== undefined && selectedProgram.length) {
              if (
                prodcutJson.Program_Types__c !== null &&
                prodcutJson.Program_Types__c !== undefined
              ) {
                var lower_program = prodcutJson.Program_Types__c.map((v) =>
                  RemoveSpecialChar(v)
                );
                var selectedProgram_user = selectedProgram.map((v) =>
                  RemoveSpecialChar(v)
                );
                if (
                  intersect_arrays(lower_program, selectedProgram_user)
                    .length !== 0
                ) {
                  program_type = true;
                }
              }
            } else {
              program_type = true;
            }

            /****
             * Practice area
             */
            if (selectedPractise !== undefined && selectedPractise.length) {
              if (prodcutJson.Practising_Areas__c !== null) {
                var lower_program = prodcutJson.Practising_Areas__c.split(
                  ";"
                ).map((v) => RemoveSpecialChar(v));
                var selectedPractise_user = selectedPractise.map((v) =>
                  RemoveSpecialChar(v)
                );
                if (
                  intersect_arrays(lower_program, selectedPractise_user)
                    .length !== 0
                ) {
                  practise_area = true;
                }
              }
            } else {
              practise_area = true;
            }

            /****
             * CPD Categories
             */
            if (selectedCPD !== undefined && selectedCPD.length) {
              //console.log(selectedCPD);
              if (
                prodcutJson.Ethics_Professional_Responsibility__c !== 0 &&
                selectedCPD.includes("ethics-and-professional-responsibility")
              ) {
                cpd_categories = true;
              }
              if (
                prodcutJson.Practice_Management_Business_Skills__c !== 0 &&
                selectedCPD.includes("practice-management-and-business-skills")
              ) {
                cpd_categories = true;
              }
              if (
                prodcutJson.Substantive_Law__c !== 0 &&
                selectedCPD.includes("substantive-law")
              ) {
                cpd_categories = true;
              }
              if (
                prodcutJson.Professional_Skills__c !== 0 &&
                selectedCPD.includes("professional-skills")
              ) {
                cpd_categories = true;
              }
            } else {
              cpd_categories = true;
            }

            /**
             * Product title
             */
            if (SearchText_ !== "") {
              let myReg = new RegExp(
                SearchText_.toLowerCase()
                  .split(" ")
                  .join("-")
                  .replace(/[^a-zA-Z0-9]/g, "") + ".*"
              );

              if (
                ppp[1].handle
                  .toLowerCase()
                  .split(" ")
                  .join("-")
                  .replace(/[^a-zA-Z0-9]/g, "")
                  .match(myReg) !== null ||
                ppp[1].title
                  .toLowerCase()
                  .split(" ")
                  .join("-")
                  .replace(/[^a-zA-Z0-9]/g, "")
                  .match(myReg) ||
                ppp[1].description
                  .toLowerCase()
                  .split(" ")
                  .join("-")
                  .replace(/[^a-zA-Z0-9]/g, "")
                  .match(myReg)
              ) {
                search_text = true;
              } else {
                if (!search_text) {
                  prodcutJson.presentor.map((presentor, index) => {
                    presentor.Contact_info.map((Contact_info) => {
                      if (
                        Contact_info.Name.toLowerCase()
                          .split(" ")
                          .join("-")
                          .replace(/[^a-zA-Z0-9]/g, "")
                          .match(myReg) !== null
                      ) {
                        search_text = true;
                      }
                    });
                  });
                }
                if (!search_text) {
                  if (
                    prodcutJson.Program_Types__c !== null &&
                    prodcutJson.Program_Types__c !== undefined
                  ) {
                    var lower_program = prodcutJson.Program_Types__c.map((v) =>
                      RemoveSpecialChar(v)
                    );
                    var selectedProgram_user = RemoveSpecialChar(SearchText_);
                    if (lower_program.includes(selectedProgram_user)) {
                      search_text = true;
                    }
                  }
                }
                if (!search_text) {
                  if (prodcutJson.Practising_Areas__c !== null) {
                    var lower_program = prodcutJson.Practising_Areas__c.split(
                      ";"
                    ).map((v) => RemoveSpecialChar(v));
                    var selectedPractise_user = [
                      RemoveSpecialChar(SearchText_),
                    ];
                    if (
                      intersect_arrays(lower_program, selectedPractise_user)
                        .length !== 0
                    ) {
                      search_text = true;
                    }
                  }
                }

                if (!search_text) {
                  //console.log(selectedCPD);
                  if (
                    prodcutJson.Ethics_Professional_Responsibility__c !== 0 &&
                    RemoveSpecialChar(SearchText_) ===
                      "ethics-and-professional-responsibility"
                  ) {
                    search_text = true;
                  }
                  if (
                    prodcutJson.Practice_Management_Business_Skills__c !== 0 &&
                    RemoveSpecialChar(SearchText_) ===
                      "practice-management-and-business-skills"
                  ) {
                    search_text = true;
                  }
                  if (
                    prodcutJson.Substantive_Law__c !== 0 &&
                    RemoveSpecialChar(SearchText_) === "substantive-law"
                  ) {
                    search_text = true;
                  }
                  if (
                    prodcutJson.Professional_Skills__c !== 0 &&
                    RemoveSpecialChar(SearchText_) === "professional-skills"
                  ) {
                    search_text = true;
                  }
                }
                if (!search_text) {
                  if (prodcutJson.slt_topic !== null) {
                    var lower_program = prodcutJson.slt_topic
                      .split(";")
                      .map((v) => RemoveSpecialChar(v));
                    var selectedPractise_user = [
                      RemoveSpecialChar(SearchText_),
                    ];
                    if (
                      intersect_arrays(lower_program, selectedPractise_user)
                        .length !== 0
                    ) {
                      search_text = true;
                    }
                  }
                }

                if (!search_text) {
                  if (prodcutJson.Format__c !== null) {
                    var lower_program = prodcutJson.Format__c.split(";").map(
                      (v) => RemoveSpecialChar(v)
                    );
                    var selectedPractise_user = [
                      RemoveSpecialChar(SearchText_),
                    ];
                    if (
                      intersect_arrays(lower_program, selectedPractise_user)
                        .length !== 0
                    ) {
                      search_text = true;
                    }
                  }
                }

                if (!search_text) {
                  if (prodcutJson.delivery_mode != null) {
                    var lower_program = prodcutJson.delivery_mode.map((v) =>
                      RemoveSpecialChar(v)
                    );
                    var lower_program_user = [RemoveSpecialChar(SearchText_)];
                    if (
                      intersect_arrays(lower_program, lower_program_user)
                        .length !== 0
                    ) {
                      search_text = true;
                    }
                  }
                }
              }
            } else {
              search_text = true;
            }

            /***
             * isPromotion
             */
            if (isPromotion === true || isPromotion === "true") {
              if (prodcutJson.isPromotion == true) {
                check_promotion = true;
              }
            } else {
              check_promotion = true;
            }

            /**
             * Selected topic
             */
            if (selectedTopic !== undefined && selectedTopic.length) {
              if (prodcutJson.slt_topic !== null) {
                var lower_program = prodcutJson.slt_topic
                  .split(";")
                  .map((v) => RemoveSpecialChar(v));
                var selectedPractise_user = selectedTopic.map((v) =>
                  RemoveSpecialChar(v)
                );
                if (
                  intersect_arrays(lower_program, selectedPractise_user)
                    .length !== 0
                ) {
                  topic = true;
                }
              }
            } else {
              topic = true;
            }

            /**
             * Selected format
             */
            if (selectedFormat !== undefined && selectedFormat.length) {
              if (prodcutJson.Format__c !== null) {
                var lower_program = prodcutJson.Format__c.split(";").map((v) =>
                  RemoveSpecialChar(v)
                );
                var selectedPractise_user = selectedFormat.map((v) =>
                  RemoveSpecialChar(v)
                );
                if (
                  intersect_arrays(lower_program, selectedPractise_user)
                    .length !== 0
                ) {
                  format_ = true;
                }
              }
            } else {
              format_ = true;
            }

            /**
             * Deliver Mode
             */
            if (selectedDelivery.length !== 0) {
              if (prodcutJson.delivery_mode != null) {
                var lower_program = prodcutJson.delivery_mode.map((v) =>
                  RemoveSpecialChar(v)
                );
                var lower_program_user = selectedDelivery.map((v) =>
                  RemoveSpecialChar(v)
                );
                if (
                  intersect_arrays(lower_program, lower_program_user).length !==
                  0
                ) {
                  deliver_mode = true;
                }
              }
            } else {
              deliver_mode = true;
            }

            /***
             * Start Date
             */
            if (
              startDate !== null &&
              endDate !== null &&
              startDate !== undefined &&
              endDate !== undefined
            ) {
              var is_date_avaialble = false;

              if (
                prodcutJson.start_date === undefined ||
                prodcutJson.end_date === undefined ||
                prodcutJson.start_date === null ||
                prodcutJson.end_date === null
              ) {
                is_date_avaialble = true;
              }

              if (
                prodcutJson.start_date !== undefined &&
                prodcutJson.start_date !== null &&
                compare_date(startDate, endDate, prodcutJson.start_date)
              ) {
                start_date = true;
                end_date = true;
              }
              if (
                prodcutJson.end_date !== undefined &&
                prodcutJson.end_date !== null &&
                compare_date(startDate, endDate, prodcutJson.end_date)
              ) {
                start_date = true;
                end_date = true;
              }

              if (is_date_avaialble) {
                // start_date = true;
                // end_date = true;
              }
            } else {
              start_date = true;
              end_date = true;
            }

           
            if (
              state_allowed &&
              format_ &&
              datapassed &&
              hidefromlist &&
              check_promotion &&
              program_type &&
              practise_area &&
              cpd_categories &&
              deliver_mode &&
              search_text &&
              start_date &&
              end_date &&
              topic
            ) {
              defaaultt = true;
            }
          }
        });
      } else {
        /****
         * Hide from list
         */
        ppp[1].node.metafields.edges.filter(function (metadata) {
          if (metadata.key == "all_data") {
            var prodcutJson = JSON.parse(metadata.value);
            if (prodcutJson.Hide_from_list !== true) {
              hidefromlist = true;
            }

            /****
             * Old date
             */

            /* if (prodcutJson.end_date != undefined && prodcutJson.end_date < CurrentDate) {
                             datapassed = true
                             alert();
                         }*/
            var past = new Date(prodcutJson.end_date);

            if (
              prodcutJson.end_date !== undefined &&
              dateInPast(past, CurrentDate)
            ) {
              datapassed = true;
            }

            /*     var is_program_gone = program_date_is_gone_or_not(prodcutJson.start_date_time);
                             if(is_program_gone)
                             {
                                 datapassed = false;
                             }*/
          }
        });

        if (hidefromlist) {
          defaaultt = true;
        } else {
          defaaultt = false;
        }

        if (datapassed) {
          defaaultt = true;
        }
      }

      return defaaultt;
    });

    /***
     * Sort by onSale
     */

    if (SortBy === "onsale") {
      filterRespones.sort(function (aa, bb) {
        let a = Object.values(aa);
        let b = Object.values(bb);

        var date1 = null;
        var date2 = null;

        a[1].metafields.map(function (metadata) {
          if (metadata.key == "all_data") {
            var prodcutJson1 = JSON.parse(metadata.value);
            date1 = prodcutJson1.Compare_Price;
          }
        });

        b[1].metafields.map(function (metadata) {
          if (metadata.key == "all_data") {
            var prodcutJson2 = JSON.parse(metadata.value);
            date2 = prodcutJson2.Compare_Price;
          }
        });

        return new Date(date2) - new Date(date1);
      });
    }

    /***
     * Sort by date
     */

    if (SortBy === "date-az" || SortBy === "date-za") {
      filterRespones.sort(function (aa, bb) {
        let a = Object.values(aa);
        let b = Object.values(bb);
        var pdate1 = a[1];
        var pdate2 = b[1];

        var date1 = null;
        var date2 = null;

        a[1].metafields.map(function (metadata) {
          if (metadata.key == "all_data") {
            var prodcutJson1 = JSON.parse(metadata.value);
            date1 = prodcutJson1.start_date_time;
          }
        });

        b[1].metafields.map(function (metadata) {
          if (metadata.key == "all_data") {
            var prodcutJson2 = JSON.parse(metadata.value);
            date2 = prodcutJson2.start_date_time;
          }
        });

        if (
          date1 == null ||
          date1 == "" ||
          pdate1.tags.includes("online-intensive-(to-download)")
        ) {
          return 1;
        } else if (
          date2 == null ||
          date2 == "" ||
          pdate2.tags.includes("online-intensive-(to-download)")
        ) {
          return -1;
        } else {
          return SortBy === "date-az"
            ? new Date(date1) - new Date(date2)
            : new Date(date2) - new Date(date1);
        }
      });
    }

    /***
     * Sort by Name
     */

    if (SortBy === "title-az") {
      filterRespones.sort((a, b) => a[1].title.localeCompare(b[1].title));
    }

    if (SortBy === "title-za") {
      filterRespones.sort((a, b) => b[1].title.localeCompare(a[1].title));
    }

    /***
     * Sort by Price
     */

    if (SortBy === "price-az") {
      filterRespones.sort((a, b) => {
        return (
          a[1].priceRange.minVariantPrice.amount -
          b[1].priceRange.minVariantPrice.amount
        );
      });
    }

    if (SortBy === "price-za") {
      filterRespones.sort((a, b) => {
        return (
          b[1].priceRange.minVariantPrice.amount -
          a[1].priceRange.minVariantPrice.amount
        );
      });
    }

    /*****
     * Update calendar event
     */
    UpdateCalendarEvent(filterRespones);
    return filterRespones;
  }

  String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  };

  /****
   * Close OfferBar
   */

  const CloseOfferBAR = () => {
    props.setofferBarTwo(false);
    localStorage.setItem("offerBarTwo", true);
  };

  /***
   * Remove array value
   */
  const removeItemOnce = (arr, value) => {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    /* console.log('removeItemOnce');
         console.log(arr);
         console.log('removeItemOnce end');*/
    return arr;
  };

  /***
   * Convert array to selectio option
   */
  const getSelectOption = (optionList, DefaultLabel) => {
    let option_list = [];
    if (DefaultLabel === "program_array") {
      ProgramType.map((value) => {
        if (
          optionList.length &&
          optionList.includes(RemoveSpecialChar(value))
        ) {
          option_list.push({
            label: value,
            value: RemoveSpecialChar(value),
          });
        }
      });
    } else if (DefaultLabel === "topic_array") {
      Topics.map((value) => {
        if (
          optionList.length &&
          optionList.includes(RemoveSpecialChar(value))
        ) {
          option_list.push({
            label: value,
            value: RemoveSpecialChar(value),
          });
        }
      });
    } else if (DefaultLabel === "format_array") {
      formatType.map((value) => {
        if (
          optionList.length &&
          optionList.includes(RemoveSpecialChar(value))
        ) {
          option_list.push({
            label: value,
            value: RemoveSpecialChar(value),
          });
        }
      });
    } else if (DefaultLabel === "practice_array") {
      PracticeArea.map((value) => {
        if (
          optionList.length &&
          optionList.includes(RemoveSpecialChar(value))
        ) {
          option_list.push({
            label: value,
            value: RemoveSpecialChar(value),
          });
        }
      });
    } else if (DefaultLabel === "cpd_array") {
      CPDCategories.map((value) => {
        if (
          optionList.length &&
          optionList.includes(RemoveSpecialChar(value))
        ) {
          option_list.push({
            label: value,
            value: RemoveSpecialChar(value),
          });
        }
      });
    } else if (DefaultLabel === "remove space") {
      DeliverMode.map((value) => {
        if (optionList.includes(RemoveSpecialChar(value))) {
          option_list.push({
            label: value,
            value: RemoveSpecialChar(value),
          });
        }
      });
    } else {
      optionList.map((value) => {
        option_list.push({
          label: value,
          value: RemoveSpecialChar(value),
        });
      });
    }

    return option_list;
  };

  const getLabel = (optionList, selected) => {
    var label = "";
    optionList.map((value) => {
      if (RemoveSpecialChar(value) === RemoveSpecialChar(selected)) {
        label = value;
      }
    });
    return label;
  };

  const ManageDeliveryMode = (dmode) => {
    var temp = [];
    dmode.map((opt) => {
      temp.push(opt.value);
    });
    setDelivery(temp);
  };

  const ManagePrograme = (dmode) => {
    var temp = [];
    dmode.map((opt) => {
      temp.push(opt.value);
    });
    setProgram(temp);
  };

  const ManageFormat = (dmode) => {
    var temp = [];
    dmode.map((opt) => {
      temp.push(opt.value);
    });
    setselectedFormat(temp);
  };

  const ManagePractice = (dmode) => {
    var temp = [];
    dmode.map((opt) => {
      temp.push(opt.value);
    });
    setPractise(temp);
  };

  const ManageCPD = (dmode) => {
    var temp = [];
    dmode.map((opt) => {
      temp.push(opt.value);
    });
    setCPD(temp);
  };
  const ManageTopic = (dmode) => {
    var temp = [];
    dmode.map((opt) => {
      temp.push(opt.value);
    });
    setTopic(temp);
  };

  const ManagePromotioan = (val) => {
    setPromotion(val === null ? false : true);
  };

  /****
   * Manage Popular tag click event
   */
  const ClickPopularTag = (TagValue) => {
    console.log(TagValue);
    if (Does_Tag_isProgramType(TagValue).length > 0) {
      selectedProgram.includes(RemoveSpecialChar(TagValue))
        ? setProgram([
            ...removeItemOnce(selectedProgram, RemoveSpecialChar(TagValue)),
          ])
        : setProgram((oldArray) => [...oldArray, RemoveSpecialChar(TagValue)]);
      return true;
    } else if (Does_Tag_isFormatType(TagValue).length > 0) {
      selectedFormat.includes(RemoveSpecialChar(TagValue))
        ? setselectedFormat([
            ...removeItemOnce(selectedFormat, RemoveSpecialChar(TagValue)),
          ])
        : setselectedFormat((oldArray) => [
            ...oldArray,
            RemoveSpecialChar(TagValue),
          ]);
      return true;
    } else if (Does_Tag_isPracticeArea(TagValue).length > 0) {
      selectedPractise.includes(RemoveSpecialChar(TagValue))
        ? setPractise([
            ...removeItemOnce(selectedPractise, RemoveSpecialChar(TagValue)),
          ])
        : setPractise((oldArray) => [...oldArray, RemoveSpecialChar(TagValue)]);
      return true;
    } else if (Does_Tag_isTopics(TagValue).length > 0) {
      selectedTopic.includes(RemoveSpecialChar(TagValue))
        ? setTopic([
            ...removeItemOnce(selectedTopic, RemoveSpecialChar(TagValue)),
          ])
        : setTopic((oldArray) => [...oldArray, RemoveSpecialChar(TagValue)]);
      return true;
    } else if (Does_Tag_isCPDCategories(TagValue).length > 0) {
      console.log("cpd added");
      selectedCPD.includes(RemoveSpecialChar(TagValue))
        ? setCPD([...removeItemOnce(selectedCPD, RemoveSpecialChar(TagValue))])
        : setCPD((oldArray) => [...oldArray, RemoveSpecialChar(TagValue)]);
      return true;
    } else if (Does_Tag_isDeliverMode(TagValue).length > 0) {
      selectedDelivery.includes(RemoveSpecialChar(TagValue))
        ? setDelivery([
            ...removeItemOnce(selectedDelivery, RemoveSpecialChar(TagValue)),
          ])
        : setDelivery((oldArray) => [...oldArray, RemoveSpecialChar(TagValue)]);
      return true;
    }
  };

  /***
   * Is filter Tag checked
   */

  const isTagCHecked = (TagValue) => {
    if (
      Does_Tag_isProgramType(TagValue).length > 0 &&
      selectedProgram !== undefined
    ) {
      return selectedProgram.includes(RemoveSpecialChar(TagValue));
    } else if (Does_Tag_isFormatType(TagValue).length > 0) {
      return selectedFormat.includes(RemoveSpecialChar(TagValue));
    } else if (
      Does_Tag_isPracticeArea(TagValue).length > 0 &&
      selectedPractise !== undefined
    ) {
      return selectedPractise.includes(RemoveSpecialChar(TagValue));
    } else if (Does_Tag_isTopics(TagValue).length > 0) {
      return selectedTopic.includes(RemoveSpecialChar(TagValue));
    } else if (
      Does_Tag_isCPDCategories(TagValue).length > 0 &&
      selectedCPD !== undefined
    ) {
      return selectedCPD.includes(RemoveSpecialChar(TagValue));
    } else if (Does_Tag_isDeliverMode(TagValue).length > 0) {
      return selectedDelivery.includes(RemoveSpecialChar(TagValue));
    }
  };

  /****
   * All filter list
   */
  const AllFilterList = (RequestTag) => {
    var ArraysTemp = [
      [ProgramType],
      [formatType],
      [PracticeArea],
      [Topics],
      [CPDCategories],
      [DeliverMode],
    ];
    return flatten(ArraysTemp).includes(RequestTag) && isTagCHecked(RequestTag);
  };

  const flatten = (arr) => {
    return arr.reduce(function (flat, toFlatten) {
      return flat.concat(
        Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
      );
    }, []);
  };

  /***
   *
   */
  const Does_Tag_isProgramType = (ProgramValue) => {
    return ProgramType.filter((val) => {
      return RemoveSpecialChar(val) === RemoveSpecialChar(ProgramValue);
    });
  };

  const Does_Tag_isFormatType = (FormatValue) => {
    return formatType.filter((val) => {
      return RemoveSpecialChar(val) === RemoveSpecialChar(FormatValue);
    });
  };

  const Does_Tag_isPracticeArea = (PracticeValue) => {
    return PracticeArea.filter((val) => {
      return RemoveSpecialChar(val) === RemoveSpecialChar(PracticeValue);
    });
  };

  const Does_Tag_isTopics = (TopicValue) => {
    return Topics.filter((val) => {
      return RemoveSpecialChar(val) === RemoveSpecialChar(TopicValue);
    });
  };

  const Does_Tag_isCPDCategories = (CategoriesValue) => {
    return CPDCategories.filter((val) => {
      return RemoveSpecialChar(val) === RemoveSpecialChar(CategoriesValue);
    });
  };

  const Does_Tag_isDeliverMode = (DeliverModeValue) => {
    return DeliverMode.filter((val) => {
      return RemoveSpecialChar(val) === RemoveSpecialChar(DeliverModeValue);
    });
  };

  /***
   * Auto complete list
   */

  const AutoComplteList = () => {
    var ArraysTemp = [
      [ProgramType],
      [formatType],
      [PracticeArea],
      [Topics],
      [CPDCategories],
      [DeliverMode],
    ];
    var ReturnArray = [];
    flatten(ArraysTemp).map((val) => {
      ReturnArray.push(val);
    });
    return ReturnArray;
  };

  /****
   *
   */
  const AutoCompltehandleOnSelect = (item) => {
    let TagValue = item;
    setSearchText(TagValue);
    setSearchTextTextField(TagValue);
  };

  /****
   * Sort by option
   */
  const [SortbyOption, setSortbyOption] = useState([
    {
      label: "Price Low to High",
      value: "price-az",
    },
    {
      label: "Price High to low",
      value: "price-za",
    },
    {
      label: "On Sale",
      value: "onsale",
    },
    {
      label: "Title (A-Z)",
      value: "title-az",
    },
    {
      label: "Title (Z-A)",
      value: "title-za",
    },
    {
      label: "Date (Earliest-Latest)",
      value: "date-az",
    },
    {
      label: "Date (Latest-Earliest)",
      value: "date-za",
    },
  ]);

  const getDefaultSortby = () => {
    return SortbyOption.filter((val) => {
      return val.value === SortBy;
    });
  };

  const [DownloadButtonText, setDownloadButtonText] = useState("Download PDF");
  const [userEmail, setUserEmail] = useState("");
  const [EmailModal, setEmailModal] = useState(false);
  const [EmailModalMessage, setEmailModalMessage] = useState(undefined);
  const [userEmail_ER, set_userEmail_ER] = useState(false);
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  useEffect(() => {
    if (
      props.useLoggedIn !== undefined &&
      props.useLoggedIn === true &&
      props.UserData !== null &&
      props.UserData !== undefined
    ) {
      setUserEmail(props.UserData.customer.email);
    }
  }, [props.UserData]);

  const MakePDFready = () => {
    // console.log(FilterProductsID);
    set_userEmail_ER(false);

    if (userEmail !== "" && userEmail !== null && userEmail !== undefined) {
      setDownloadButtonText("Validating...");
      axios
        .post(props.AdminPanelURL + "api/pdf/addtosqs", {
          id: FilterProductsID,
          email: userEmail,
        })
        .then(function (response) {
          if (response.status === 200) {
            setDownloadButtonText("PDF has been sent to your email.");
            setEmailModalMessage("PDF has been sent to your email.");
            // downloadPDF(response.data.pdf, "product-list.pdf");
          }
        });
    } else {
      if (EmailModal && (userEmail === null || !validateEmail(userEmail))) {
        set_userEmail_ER(true);
      }

      setEmailModal(true);
    }
  };

  /***
   * Pagignation button
   */
  const PagignationButton = (index, page_current_render, currentPage) => {
    return (
      <Pagination.Item
        key={"pagination" + index}
        className={page_current_render == currentPage ? "active" : null}
        onClick={() => setcurrentPage(page_current_render)}
      >
        {page_current_render}
      </Pagination.Item>
    );
  };

  /****
   * Fillter 3 dot toogle
   */
  const [dotToogle, setdotToogle] = useState(false);

  if (isLoading) {
    return (
      <>
        <div className="pre_loader l1">
          <LoaderIcon />
        </div>
      </>
    );
  }

  if (userData_ != undefined && userData_ != "") {
    return (
      <>
        <Helmet>
          <title>
            {seo_title} {seo_title != "" ? "|" : ""} Leo Cussen Centre for Law
          </title>
          <link
            rel="canonical"
            href={"https://store.leocussen.edu.au/" + productHandle}
          />

          <meta name="description" content={seo_description} />
        </Helmet>

        <div className="all_program_page">
          <div className="search_wrapper">
            <Container>
              <div
                className="blue_banner_bar"
                style={{ backgroundImage: "url(" + BlueBg + ")" }}
              ></div>
              <div className="search_inner">
                <Form onSubmit={HitEnter}>
                  <div className="filter_search_block">
                    <div className="sc-eCYdqJ">
                      <div className="wrapper">
                        <div className="sc-hKMtZM jVgFww d-none d-md-block">
                          <Hint options={AutoComplteList()} allowTabFill={true}>
                            <input
                              className="search_filed"
                              placeholder="Search for a program title, program description or presenter name"
                              value={SearchTextTextField}
                              onChange={(e) =>
                                AutoCompltehandleOnSelect(e.target.value)
                              }
                            />
                          </Hint>
                        </div>
                        <div className="sc-hKMtZM jVgFww d-md-none">
                          <Hint options={AutoComplteList()} allowTabFill={true}>
                            <input
                              className="search_filed"
                              placeholder="Search..."
                              value={SearchTextTextField}
                              onChange={(e) =>
                                AutoCompltehandleOnSelect(e.target.value)
                              }
                            />
                          </Hint>
                        </div>
                      </div>
                    </div>
                    <Button type="submit" variant="search">
                      <SearchIconNew />
                    </Button>
                  </div>
                  <div
                    className={
                      VisibleFilterLayout
                        ? "fillter_wrapper"
                        : "fillter_wrapper d-none"
                    }
                  >
                    <div
                      className="filter_list_wrapper"
                      style={dotToogle ? { height: "auto" } : {}}
                    >
                      <label className="filter_title">Filter by:</label>
                      {props.PopularTag.map((TagName, key) => {
                        return (
                          <div className="filter_list" key={key + "ss"}>
                            <div className="form-check">
                              <div
                                className={
                                  AllFilterList(TagName)
                                    ? "form-check-label active"
                                    : "form-check-label"
                                }
                                id={RemoveSpecialChar(TagName)}
                                onClick={() => ClickPopularTag(TagName)}
                              >
                                {TagName}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {!dotToogle ? (
                        <div
                          className="filter_list_more"
                          onClick={() => setdotToogle(true)}
                        >
                          ...
                        </div>
                      ) : null}
                    </div>
                    <ul className="filter_dropdown_list list-unstyled">
                      {props.ProductPageJson.map((menu, index) => {
                        return menu.Name.split(" ").join("-").toLowerCase() ===
                          productHandle &&
                          menu.Filters.includes("Program Type") ? (
                          <li key={"li-"+index}>
                            <Select
                              aria-label="select"
                              key={selectedProgram.toString()}
                              options={getSelectOption(
                                ProgramType,
                                "Program Type"
                              )}
                              placeholder="Program Type"
                              isClearable={true}
                              defaultValue={getSelectOption(
                                selectedProgram,
                                "program_array"
                              )}
                              isMulti={true}
                              onChange={ManagePrograme}
                            />
                          </li>
                        ) : null;
                      })}
                      {props.ProductPageJson.map((menu, index) => {
                        return menu.Name.split(" ").join("-").toLowerCase() ===
                          productHandle && menu.Filters.includes("Format") ? (
                          <li key={"li--"+index}>
                            <Select
                              key={selectedFormat}
                              aria-label="select"
                              options={getSelectOption(
                                formatType,
                                "Format Type"
                              )}
                              defaultValue={getSelectOption(
                                selectedFormat,
                                "format_array"
                              )}
                              placeholder="Format Type"
                              isMulti={true}
                              isClearable={true}
                              onChange={ManageFormat}
                            />
                          </li>
                        ) : null;
                      })}
                      {props.ProductPageJson.map((menu, index) => {
                        return menu.Name.split(" ").join("-").toLowerCase() ===
                          productHandle &&
                          menu.Filters.includes("Practice Areas") ? (
                          <li key={"li---"+index}>
                            <Select
                              key={selectedPractise}
                              aria-label="select"
                              options={getSelectOption(
                                PracticeArea,
                                "Practice Areas"
                              )}
                              defaultValue={getSelectOption(
                                selectedPractise,
                                "practice_array"
                              )}
                              placeholder="Practice Areas"
                              isMulti={true}
                              isClearable={true}
                              onChange={ManagePractice}
                            />
                          </li>
                        ) : null;
                      })}
                      {props.ProductPageJson.map((menu, index) => {
                        return menu.Name.split(" ").join("-").toLowerCase() ===
                          productHandle && menu.Filters.includes("Topic") ? (
                          <li key={"li----"+index}>
                            <Select
                              key={selectedTopic}
                              aria-label="select"
                              options={getSelectOption(Topics, "Topic")}
                              defaultValue={getSelectOption(
                                selectedTopic,
                                "topic_array"
                              )}
                              placeholder="Topic"
                              isMulti={true}
                              isClearable={true}
                              onChange={ManageTopic}
                            />
                          </li>
                        ) : null;
                      })}
                      {props.ProductPageJson.map((menu, index) => {
                        return menu.Name.split(" ").join("-").toLowerCase() ===
                          productHandle &&
                          menu.Filters.includes("CPD Categories") ? (
                          <li key={"li-----"+index}>
                            <Select
                              id="cpd_categories_select"
                              key={selectedCPD}
                              aria-label="select"
                              options={getSelectOption(
                                CPDCategories,
                                "CPD Categories"
                              )}
                              defaultValue={getSelectOption(
                                selectedCPD,
                                "cpd_array"
                              )}
                              placeholder="CPD Categories"
                              isMulti={true}
                              isClearable={true}
                              onChange={ManageCPD}
                            />
                          </li>
                        ) : null;
                      })}
                      {isDateFilterNeeded === "Date" ? (
                        <>
                          <li>
                            <FormControl
                              type={startDateFocus ? "date" : "text"}
                              placeholder="From Date"
                              className="date_filed"
                              onFocus={() => setStartDateFocus(true)}
                              value={startDate !== null ? startDate : ""}
                              onChange={(e) => setStartDate(e.target.value)}
                              aria-label="From Date"
                            />
                          </li>
                          <li>
                            <FormControl
                              type={endDateFocus ? "date" : "text"}
                              placeholder="To Date"
                              className="date_filed"
                              onFocus={() => setEndDateFocus(true)}
                              aria-label="To Date"
                              value={endDate !== null ? endDate : ""}
                              onChange={(e) => setEndDate(e.target.value)}
                            />
                          </li>
                        </>
                      ) : (
                        ""
                      )}
                      {props.ProductPageJson.map((menu, index) => {
                        return menu.Name.split(" ").join("-").toLowerCase() ===
                          productHandle &&
                          menu.Filters.includes("Delivery Mode") ? (
                          <li  key={"lii-"+index}>
                            <Select
                              id="delivery_mode_select"
                              key={selectedDelivery.toString()}
                              aria-label="select"
                              options={getSelectOption(
                                DeliverMode,
                                "Delivery Mode"
                              )}
                              placeholder="Delivery Mode"
                              defaultValue={getSelectOption(
                                selectedDelivery,
                                "remove space"
                              )}
                              isMulti={true}
                              isClearable={true}
                              onChange={ManageDeliveryMode}
                            />
                          </li>
                        ) : null;
                      })}
                      {isPromotion_show == false ? (
                        ""
                      ) : (
                        <li>
                          <Select
                            aria-label="select"
                            options={[{ label: "Yes", value: "true" }]}
                            placeholder="Promotions"
                            isClearable={true}
                            onChange={ManagePromotioan}
                          />
                        </li>
                      )}

                      <li>
                        <div
                          className="reset_btn btn btn-outline-primary"
                          onClick={() => ResetFilter_Data()}
                        >
                          Reset
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="filter-expan-link btn-expand-link"
                    onClick={() => setVisibleFilterLayout(!VisibleFilterLayout)}
                  >
                    {VisibleFilterLayout ? "Hide Filters" : "Show Filters"}{" "}
                    {VisibleFilterLayout ? <ArrowUpIcon /> : <ArrowDownIcon />}
                  </div>
                </Form>
              </div>
            </Container>
          </div>

          <Container>
            <Row>
              <Col md={12} xs={12}>
                <div className="cpd_programs_content">
                  <div className="cpd_head d-flex flex-wrap align-items-center">
                    <h1>{CurrentMenuTitle}</h1>

                    {totalProduct > 0 ? (
                      <a className="downloadpdf" onClick={() => MakePDFready()}>
                        {DownloadButtonText}{" "}
                      </a>
                    ) : null}
                  </div>

                  <div className="programs_description">
                    <p>{seo_description}</p>
                  </div>

                  <div className="results_sort_order_block d-flex flex-column flex-md-row align-items-sm-center justify-content-between">
                    <div className="results_block d-flex align-items-center">
                      <h4>Results</h4>
                      <Form.Check
                        inline
                        label="List view"
                        id="switch_view_list"
                        name="switch_view"
                        value="List view"
                        defaultChecked={LayoutView === "List view"}
                        onChange={(e) => setLayoutView(e.target.value)}
                        type="radio"
                      />
                      <Form.Check
                        inline
                        id="switch_view_calendar"
                        label="Calendar view"
                        name="switch_view"
                        value="Calendar view"
                        defaultChecked={LayoutView === "Calendar view"}
                        onChange={(e) => setLayoutView(e.target.value)}
                        type="radio"
                      />
                    </div>
                    <div className="sort_order_block d-flex flex-column flex-sm-row align-items-sm-center">
                      {LayoutView === "List view" ? (
                        <>
                          <span>Sort by:</span>
                          <Select
                            aria-label="Sort by"
                            options={SortbyOption}
                            placeholder="Sort by"
                            defaultValue={getDefaultSortby}
                            onChange={(val) => setSortBy(val.value)}
                          />
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div
                    className={
                      LayoutView === "List view"
                        ? "all-collapse"
                        : "all-collapse  d-none"
                    }
                    onClick={() => {
                      setExpandAll(!expandAll);
                    }}
                  >
                    {expandAll ? "Hide All Details" : "Expand All Details"}
                  </div>
                  <div className={LayoutView !== "List view" ? "d-none" : ""}>
                    <div className="cpd_programs">
                      <Row xs={1} className="g-4">
                        <ProductListing
                          key="0"
                          pageSize={pageSize}
                          totalProduct={totalProduct}
                          currentPage={currentPage}
                          statehandler={handler}
                          AddtoCart={props.AddtoCart}
                          data={mysearch(userData_.products)}
                          Program_structure_images={
                            props.Program_structure_images
                          }
                          is_ten_minite_less={is_ten_minite_less}
                          program_date_is_gone_or_not={
                            program_date_is_gone_or_not
                          }
                          Program_structure_color={
                            props.Program_structure_color
                          }
                          getAllWishlist={props.getAllWishlist}
                          GAurl={GAurl}
                          AddtoWishlist={props.AddtoWishlist}
                          expandAll={expandAll}
                        />
                      </Row>
                    </div>
                    {PaginationRange > 1 ? (
                      <Pagination className="justify-content-center flex-wrap">
                        {currentPage != getPrevPage() ? (
                          <Pagination.Prev
                            onClick={() => setcurrentPage(getPrevPage())}
                            className="page_link_prev"
                          />
                        ) : (
                          ""
                        )}
                        {PaginationRange <= 6 ? (
                          [...Array(PaginationRange)].map((_, index) => {
                            let page_current_render = index + 1;
                            return (
                              <Pagination.Item
                                key={"page" + index}
                                className={
                                  page_current_render == currentPage
                                    ? "active"
                                    : null
                                }
                                onClick={() =>
                                  setcurrentPage(page_current_render)
                                }
                              >
                                {page_current_render}
                              </Pagination.Item>
                            );
                          })
                        ) : (
                          <Paginator
                            key="sdf"
                            totalPages={PaginationRange}
                            currentPage={currentPage}
                            setcurrentPage={setcurrentPage}
                          />
                        )}

                        {currentPage !== getNextPage() ? (
                          <Pagination.Next
                            data-next={getNextPage()}
                            onClick={() => setcurrentPage(getNextPage())}
                            className="page_link_next"
                          />
                        ) : (
                          ""
                        )}
                      </Pagination>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <Calendar
                  localizer={localizer}
                  onSelectEvent={(event) => EventClick(event)}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: 800 }}
                  events={events}
                  className={LayoutView === "List view" ? "d-none" : ""}
                  popup
                  eventPropGetter={eventStyleGetter}
                  components={{
                    toolbar: CustomToolbar,
                  }}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <>
          <Modal
            className="login_modal"
            show={EmailModal}
            onHide={() => setEmailModal(false)}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton className="border-0"></Modal.Header>
            <Modal.Body>
              {EmailModalMessage === undefined ? (
                <>
                  <p className="text-center">
                    We will send you an email once PDF is ready
                  </p>
                  <Form.Group as={Col} md="12" xs="12" className="form-group">
                    <Form.Control
                      type="email"
                      className={userEmail_ER ? "form-error" : ""}
                      id="pdfemail"
                      placeholder="Your email address*"
                      value={userEmail}
                      onChange={(e) => setUserEmail(e.target.value)}
                    />
                  </Form.Group>
                  <div className="text-center d-flex justify-content-between">
                    <Button
                      variant="primary"
                      onClick={() => MakePDFready()}
                      type="button"
                      className="rounded-2 btn-lg border-2 ms-0 m-2 w-50"
                      disabled={DownloadButtonText !== "Download PDF"}
                    >
                      {DownloadButtonText === "Download PDF"
                        ? "Submit"
                        : "Submiting..."}
                    </Button>
                  </div>
                </>
              ) : (
                <p className="text-center">{EmailModalMessage}</p>
              )}
            </Modal.Body>
          </Modal>
        </>
      </>
    );
  }
  return (
    <>
      <div className="pre_loader l2">
        <LoaderIcon />
      </div>
    </>
  );
};

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
    Container,
    Row,
    Col
} from 'react-bootstrap';

import Bg from "../../images/tile-new.png"
import ProductGridNew from '../ProductListing/ProductGrid/ProductGridNew'

// import Swiper core and required modules
import SwiperCore, {
    Navigation,
    Pagination
} from 'swiper';
// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

export const FeaturedProduct = (props) => {
    const randm = () => {
        return (Math.random() + 1).toString(36).substring(7);
    }
    return (
        <div className='featured_wrapper' style={{ backgroundImage: "url(" + Bg + ")" }}>
            <Container>
                <div className='title_wrap text-center'>
                    <h2 className='text-primary'>Featured Programs</h2>
                </div>
              

                <Swiper
                    cssMode={true}
                    spaceBetween={24}
                    slidesPerView={4}
                    navigation={false}
                    pagination={{
                        clickable: true,
                    }}
                    breakpoints={{
                        "0": {
                            "slidesPerView": 1,
                        },
                        "768": {
                            "slidesPerView": 2,
                        },
                        "1200": {
                            "slidesPerView": 4,

                        }
                    }}
                    modules={[Pagination]}
                >
                    {props.productData.map((single_product, index) => {
                        let product_data = Object.values(single_product)[1];
                        let product_title = product_data.title;
                        let product_slug = product_data.handle;
                        let priceRange = product_data.priceRange;
                        let product_final_data = undefined;
                        product_data.metafields.forEach((meta_val, meta_key) => {
                            if (meta_val.key == 'all_data') {
                                product_final_data = JSON.parse(meta_val.value)
                            }
                        })
                        return <SwiperSlide key={Math.random()}>
                            <ProductGridNew  GAurl={props.GAurl}  AddtoCart={props.AddtoCart}  Program_structure_images={props.Program_structure_images} program_date_is_gone_or_not={props.program_date_is_gone_or_not} is_ten_minite_less={props.is_ten_minite_less}  product={single_product[1]} priceRange={priceRange} product_slug={product_slug} product_title={product_title} product_data={product_final_data} />
                        </SwiperSlide>
                    })}

                </Swiper>






























            </Container>
        </div>
    )
}

export default FeaturedProduct;

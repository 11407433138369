import React from 'react';
import ImageNotFound from '../../images/404.png';
import { Helmet } from 'react-helmet';

import {
    Container
} from 'react-bootstrap';
export const NotFound = () => {
    return (
        <>
         <Helmet>
                    <title>Not Found</title>
                    <meta name="prerender-status-code" content="400" />

                </Helmet>
            <Container>
                <div className='text-center'>
                    <img src={ImageNotFound} alt="not found" className='d-block mx-auto' />
                </div>
            </Container>
        </>
    );
}
import {
    ListGroup
} from 'react-bootstrap';
import {
    MyLibraryIcon,
    MyEventsIcon,
    CPDPointsIcon,
    OrdersIcon,
    ProfileIcon,
    LikeIcon,
    LogOutIcon,
    PaymentIcon,
    ShareIcon
} from "../../../icons";
import { Link, useLocation } from 'react-router-dom';


export const AdminSidebar = (props) => {
    const location = useLocation();
    return (
        <ListGroup as="ul" className="admin_listing_block list-group position-sticky top-0">
            <ListGroup.Item as="li" className={"admin-profile" === location.pathname.replace('/', '') ? 'active' : null}><Link to="/admin-profile" className="link-item"><ProfileIcon />My Profile</Link></ListGroup.Item>
            <ListGroup.Item as="li" className={"admin-my-events" === location.pathname.replace('/', '') ? 'active' : null}><Link to="/admin-my-events" className="link-item"><MyEventsIcon /> Upcoming Events</Link></ListGroup.Item>
            <ListGroup.Item as="li" className={"admin-my-library" === location.pathname.replace('/', '') ? 'active' : null}><Link to="/admin-my-library" className="link-item"><MyLibraryIcon /> My Digital Library</Link></ListGroup.Item>
            <ListGroup.Item as="li" className={"admin-cpd-points" === location.pathname.replace('/', '') ? 'active' : null}><Link to="/admin-cpd-points" className="link-item"><CPDPointsIcon /> CPD Points</Link></ListGroup.Item>
            <ListGroup.Item as="li" className={"my-wishlist" === location.pathname.replace('/', '') ? 'active' : null}><Link to="/my-wishlist" className="link-item"><LikeIcon /> Wishlist</Link></ListGroup.Item>
            <ListGroup.Item as="li" className={"admin-orders" === location.pathname.replace('/', '') ? 'active' : null}><Link to="/admin-orders" className="link-item"><OrdersIcon /> Billing History</Link></ListGroup.Item>
            <ListGroup.Item as="li" className={"refer-earn" === location.pathname.replace('/', '') ? 'd-none active' : 'd-none'}><Link to="/refer-earn" className="link-item"><ShareIcon /> Invite & Earn</Link></ListGroup.Item>
            <ListGroup.Item as="li"><a onClick={() => props.doLogout()} className="link-item"><LogOutIcon /> Logout</a></ListGroup.Item>
        </ListGroup>
    )
}